export const UNIT_DETAILS_OPTION = {
  STATUS: "Status",
  HISTORY: "History",
  OPERATION: "Operation",
  LOGS: "Logs",
  DEBUG: "Debug (Full)",
  DEBUGBASIC: "Debug",
};

export const UNIT_DETAILS_OPTIONS_TABS = [
  {
    value: UNIT_DETAILS_OPTION.STATUS,
    label: UNIT_DETAILS_OPTION.STATUS,
    accessKey: "status",
  },
  {
    value: UNIT_DETAILS_OPTION.HISTORY,
    label: UNIT_DETAILS_OPTION.HISTORY,
    accessKey: "history",
  },
  {
    value: UNIT_DETAILS_OPTION.OPERATION,
    label: UNIT_DETAILS_OPTION.OPERATION,
    accessKey: "operation",
  },
  {
    value: UNIT_DETAILS_OPTION.LOGS,
    label: UNIT_DETAILS_OPTION.LOGS,
    accessKey: "logs",
  },
  {
    value: UNIT_DETAILS_OPTION.DEBUG,
    label: UNIT_DETAILS_OPTION.DEBUG,
    accessKey: "debug",
  },
  {
    value: UNIT_DETAILS_OPTION.DEBUGBASIC,
    label: "Debug",
    accessKey: "debug_basic",
  },
];

export const LIFECYCLE_OPTIONS = [
  { value: "new", label: "New" },
  { value: "manufactured", label: "Manufactured" },
  { value: "development", label: "Development" },
  { value: "initialised", label: "Initialised" },
  { value: "installing", label: "Installing" },
  { value: "installed", label: "Installed" },
  { value: "operational", label: "Operational" },
  { value: "deceased", label: "Deceased" },
];

export const CONTROL_MODE_STATUS_OPTIONS = [
  { value: "optimisation_setup", label: "Optimisation Setup Required" },
  { value: "optimisation_learning", label: "Optimisation Learning" },
  { value: "optimisation_active", label: "Optimisation Active" },
  { value: "vpp", label: "External Control" },
  { value: "default", label: "Self Powered" },
];

export const DAYS_RANGE_FILTERS = [
  { value: "1", label: "1 Day" },
  { value: "3", label: "Last 3 Days" },
  { value: "7", label: "Last 7 Days" },
  { value: "30", label: "Last 30 Days" },
];

export const FROM_TO_DAYS_RANGE_FILTERS = [
  { value: "6h", label: "Last 6 hrs" },
  { value: "12h", label: "Last 12 hrs" },
  { value: "today", label: "Today" },
  { value: "2d", label: "Last 2 days" },
  { value: "7d", label: "Last 7 days" },
  { value: "30d", label: "Last 30 days" },
];

export const SITE_VIEW = {
  LIST: "List View",
  MAP: "Map View",
};

export const SITE_VIEW_OPTIONS = [
  { label: SITE_VIEW.LIST, value: SITE_VIEW.LIST },
  { label: SITE_VIEW.MAP, value: SITE_VIEW.MAP },
];

export const OPERATION_OPTIONS = {
  MODE: [
    { value: "auto", label: "Automatic" },
    { value: "manual", label: "Manual" },
  ],
  SYSTEM: [
    { value: "interactive", label: "Grid Interactive" },
    { value: "debug", label: "Debug" },
    { value: "off", label: "Off" },
  ],
  BATTERY: [
    { value: "off", label: "Off" },
    { value: "charge", label: "Charge" },
    { value: "discharge", label: "Discharge" },
    { value: "excess_only", label: "Minimise Export" },
    { value: "offset_import", label: "Minimise Import" },
    { value: "self_usage", label: "Minimise Usage" },
  ],
  LIMIT: [
    { value: 1000, label: "1kW" },
    { value: 2000, label: "2kW" },
    { value: 3000, label: "3kW" },
    { value: 4000, label: "4kW" },
    { value: 5000, label: "5kW" },
  ],
  RESERVE: [
    { value: "0", label: "Disabled" },
    { value: "5", label: "5%" },
    { value: "25", label: "25%" },
    { value: "50", label: "50%" },
    { value: "100", label: "100%" },
  ],
};

export const UNIT_LIST_FITLERS = {
  organisation: "",
  group: "",
  installer: "",
  lifecycle: "",
  control_mode_status: "",
  search: "",
  online: true,
  paginationModel: {
    pageSize: 10,
    page: 0,
  },
};

export const SEASON_START_LIST = [
  { label: "1st January", value: 1 },
  { label: "1st February", value: 2 },
  { label: "1st March", value: 3 },
  { label: "1st April", value: 4 },
  { label: "1st May", value: 5 },
  { label: "1st June", value: 6 },
  { label: "1st July", value: 7 },
  { label: "1st August", value: 8 },
  { label: "1st September", value: 9 },
  { label: "1st October", value: 10 },
  { label: "1st November", value: 11 },
  { label: "1st December", value: 12 },
];

export const CONFIGURE_SEASON_BY_RATES = [
  { label: "Same rates every day", value: true },
  { label: "Different rates for weekdays vs weekends", value: false },
];

export const CONFIGURE_SEASON_HOLIDAYS = [
  { label: "Treat as weekends", value: true },
  { label: "As they fail", value: false },
];

export const getTouStartTime = () => {
  const list = [];
  for (let d = 0; d <= 23; d++) {
    list.push({
      value: d,
      label: `${d}:00`,
    });
    list.push({
      value: d + 0.5,
      label: `${d}:30`,
    });
  }

  return list;
};

export const getBatteryLabel = (value) => {
  const item = OPERATION_OPTIONS.BATTERY.find((it) => it.value === value);
  return item?.label || value;
};

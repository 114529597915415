import {
  GET_GROUPS_LIST_REQUEST,
  GET_GROUPS_LIST_SUCCESS,
  GET_GROUPS_LIST_FAILURE,
  PUT_GROUP_REQUEST,
  POST_GROUP_REQUEST,
  PUT_GROUP_FAILURE,
  POST_GROUP_FAILURE,
  POST_GROUP_SUCCESS,
  PUT_GROUP_SUCCESS,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILURE,
  GET_GROUP_SUCCESS,
  GET_GROUP_REQUEST,
  GET_GROUP_FAILURE,
  ASSIGN_SITE_GROUP_REQUEST,
  ASSIGN_SITE_GROUP_FAILURE,
  ASSIGN_SITE_GROUP_SUCCESS,
  GET_FILTERED_GROUPS_LIST_REQUEST,
  GET_FILTERED_GROUPS_LIST_FAILURE,
  GET_FILTERED_GROUPS_LIST_SUCCESS,
} from "actions/groups";
import { orderBy } from "lodash";

const initialState = {
  loading: false,
  error: {},
  list: [],
  details: {},
  filteredGroups: [],
};

export default function groups(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_GROUPS_LIST_REQUEST:
    case POST_GROUP_REQUEST:
    case PUT_GROUP_REQUEST:
    case DELETE_GROUP_REQUEST:
    case ASSIGN_SITE_GROUP_REQUEST:
    case GET_FILTERED_GROUPS_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_GROUPS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: payload.map((it) => {
          return {
            ...it,
            value: it.id,
          };
        }),
      };
    }

    case GET_GROUP_REQUEST: {
      return {
        ...state,
        loading: true,
        details: {
          sites: [],
        },
      };
    }

    case GET_GROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        details: {
          ...payload,
          sites: orderBy(payload.sites, ["nmi"]).map((it) => {
            return {
              ...it,
              value: it.id,
              label: it.nmi,
            };
          }),
        },
      };
    }

    case GET_FILTERED_GROUPS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        filteredGroups: payload.map((it) => {
          return {
            ...it,
            value: it.id,
          };
        }),
      };
    }

    case POST_GROUP_SUCCESS:
    case PUT_GROUP_SUCCESS:
    case DELETE_GROUP_SUCCESS:
    case ASSIGN_SITE_GROUP_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_GROUPS_LIST_FAILURE:
    case POST_GROUP_FAILURE:
    case PUT_GROUP_FAILURE:
    case DELETE_GROUP_FAILURE:
    case GET_GROUP_FAILURE:
    case ASSIGN_SITE_GROUP_FAILURE:
    case GET_FILTERED_GROUPS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}

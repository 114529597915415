import { Box, Typography } from "@mui/material";

export const severities = [
  "Emergency",
  "Alert",
  "Critical",
  "Error",
  "Warning",
  "Notice",
  "Informational",
  "Debug",
];

const Severity = ({ severity }) => {
  return (
    <Box>
      <Typography variant="body1" fontSize={"13px"}>
        {severities[severity]}
      </Typography>
    </Box>
  );
};

export default Severity;

import {
  GET_STATUS_LIST_REQUEST,
  GET_STATUS_LIST_SUCCESS,
  GET_STATUS_LIST_FAILURE,
} from "actions/status";
import { orderBy } from "lodash";

const initialState = {
  loading: false,
  error: {},
  list: [],
  totalCount: 0,
  details: {},
};

export default function status(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_STATUS_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_STATUS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: orderBy(payload.items, ["serial"]),
        totalCount: payload?.total_count,
      };
    }

    case GET_STATUS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}

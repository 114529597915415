import {
  POST_NAME_REQUEST,
  POST_NAME_SUCCESS,
  POST_NAME_FAILURE,
} from "actions/name";

const initialState = {
  loading: false,
  error: {},
};

export default function name(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case POST_NAME_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_NAME_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case POST_NAME_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}

import { API } from "aws-amplify";
import { constructStatusQueryString } from "utils/actions";

export const GET_DASHBOARD_METRICS_REQUEST =
  "@@dashboard/GET_DASHBOARD_METRICS_REQUEST";
export const GET_DASHBOARD_METRICS_SUCCESS =
  "@@dashboard/GET_DASHBOARD_METRICS_SUCCESS";
export const GET_DASHBOARD_METRICS_FAILURE =
  "@@dashboard/GET_DASHBOARD_METRICS_FAILURE";

export function getDashboardMetrics(formData) {
  return async (dispatch) => {
    dispatch({
      type: GET_DASHBOARD_METRICS_REQUEST,
    });
    try {
      let url = "/dashboard";
      const response = await API.get("cmsAPI", url);

      const metricGroups = response.dashboard.metric_groups;

      dispatch({
        type: GET_DASHBOARD_METRICS_SUCCESS,
        payload: metricGroups,
      });

      // console.log("formData", formData);

      dispatch(metricDetailList(metricGroups, formData));
    } catch (error) {
      dispatch({
        type: GET_DASHBOARD_METRICS_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_METRIC_DETAIL_LIST_REQUEST =
  "@@dashboard/GET_METRIC_DETAIL_LIST_REQUEST";
export const GET_METRIC_DETAIL_LIST_SUCCESS =
  "@@dashboard/GET_METRIC_DETAIL_LIST_SUCCESS";
export const GET_METRIC_DETAIL_LIST_FAILURE =
  "@@dashboard/GET_METRIC_DETAIL_LIST_FAILURE";

export function metricDetailList(metricGroups, formData) {
  return async (dispatch) => {
    metricGroups.forEach((metricGroup, index) => {
      metricGroup.metrics.forEach(async (metric) => {
        dispatch({
          type: GET_METRIC_DETAIL_LIST_REQUEST,
          payload: {
            index,
            id: metric.metric,
          },
        });
        try {
          let params = `chart=${metric.chart}&list=${metric.list}&limit=${
            metric.limit ?? 100
          }`;
          let url = `/metric/${metric.metric}?${params}`;

          if (formData) {
            url = constructStatusQueryString(url, formData);
          }
          const response = await API.get("cmsAPI", url);

          dispatch({
            type: GET_METRIC_DETAIL_LIST_SUCCESS,
            payload: {
              index,
              id: metric.metric,
              details: response,
            },
          });
        } catch (error) {
          dispatch({
            type: GET_METRIC_DETAIL_LIST_FAILURE,
            payload: {
              index,
              id: metric.metric,
            },
          });
        }
      });
    });
  };
}

export const GET_METRIC_DETAILS_REQUEST =
  "@@dashboard/GET_METRIC_DETAILS_REQUEST";
export const GET_METRIC_DETAILS_SUCCESS =
  "@@dashboard/GET_METRIC_DETAILS_SUCCESS";
export const GET_METRIC_DETAILS_FAILURE =
  "@@dashboard/GET_METRIC_DETAILS_FAILURE";

export function getMetricDetails(id, formData) {
  return async (dispatch) => {
    dispatch({
      type: GET_METRIC_DETAILS_REQUEST,
    });
    try {
      let params = `limit=100`;
      let url = `/metric/${id}?${params}`;

      if (formData) {
        url = constructStatusQueryString(url, formData);
      }
      const response = await API.get("cmsAPI", url);

      dispatch({
        type: GET_METRIC_DETAILS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_METRIC_DETAILS_FAILURE,
        payload: error,
      });
    }
  };
}

export const CLEAR_METRIC_DETAILS = "@@dashboard/CLEAR_METRIC_DETAILS";

export function clearMetricDetails() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_METRIC_DETAILS,
    });
  };
}

export const GET_UNIT_METRIC_DETAILS_REQUEST =
  "@@dashboard/GET_UNIT_METRIC_DETAILS_REQUEST";
export const GET_UNIT_METRIC_DETAILS_SUCCESS =
  "@@dashboard/GET_UNIT_METRIC_DETAILS_SUCCESS";
export const GET_UNIT_METRIC_DETAILS_FAILURE =
  "@@dashboard/GET_UNIT_METRIC_DETAILS_FAILURE";

export function getUnitMetricDetails(unitId, id, formData) {
  return async (dispatch) => {
    dispatch({
      type: GET_UNIT_METRIC_DETAILS_REQUEST,
    });
    try {
      let params = `limit=100`;
      let url = `/unit/${unitId}/metric/${id}?${params}`;

      if (formData) {
        url = constructStatusQueryString(url, formData);
      }
      const response = await API.get("cmsAPI", url);

      dispatch({
        type: GET_UNIT_METRIC_DETAILS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_UNIT_METRIC_DETAILS_FAILURE,
        payload: error,
      });
    }
  };
}

export const CLEAR_UNIT_METRIC_DETAILS =
  "@@dashboard/CLEAR_UNIT_METRIC_DETAILS";

export function clearUnitMetricDetails() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_UNIT_METRIC_DETAILS,
    });
  };
}

export const CONFIGURATION_OPTIONS = {
  PATCHES: "Patches",
};

export const CONFIGURATION_OPTIONS_TABS = [
  {
    label: CONFIGURATION_OPTIONS.PATCHES,
    value: CONFIGURATION_OPTIONS.PATCHES,
  },
];

export const MODEL_OPTIONS = {
  EB01: "eb01",
  EB02: "eb02",
};

export const MODEL_OPTIONS_TABS = [
  { value: MODEL_OPTIONS.EB01, label: "EB01" },
  { value: MODEL_OPTIONS.EB02, label: "EB02" },
];

import {
  GET_SLOT_LIST_REQUEST,
  GET_SLOT_LIST_SUCCESS,
  GET_SLOT_LIST_FAILURE,
  POST_ASSIGN_SLOT_REQUEST,
  POST_ASSIGN_SLOT_FAILURE,
  POST_ASSIGN_SLOT_SUCCESS,
  DELETE_ASSIGN_SLOT_REQUEST,
  DELETE_ASSIGN_SLOT_FAILURE,
  DELETE_ASSIGN_SLOT_SUCCESS,
} from "actions/slot";

const initialState = {
  loading: false,
  error: {},
  list: [],
};

export default function slot(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SLOT_LIST_REQUEST:
    case POST_ASSIGN_SLOT_REQUEST:
    case DELETE_ASSIGN_SLOT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_SLOT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: payload.map((it, i) => {
          return {
            ...it,
            id: i,
            serial: it.assigned,
          };
        }),
      };
    }

    case POST_ASSIGN_SLOT_SUCCESS:
    case DELETE_ASSIGN_SLOT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_SLOT_LIST_FAILURE:
    case POST_ASSIGN_SLOT_FAILURE:
    case DELETE_ASSIGN_SLOT_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}

export const NOTIFICATIONS_STATUS = {
  ALL: "All",
  ACTIVE: "Active",
  OPEN: "Open",
  CLOSED: "Closed",
};

export const NOTIFICATIONS_STATUS_OPTIONS = [
  { label: NOTIFICATIONS_STATUS.ALL, value: NOTIFICATIONS_STATUS.ALL },
  { label: NOTIFICATIONS_STATUS.ACTIVE, value: NOTIFICATIONS_STATUS.ACTIVE },
  { label: NOTIFICATIONS_STATUS.OPEN, value: NOTIFICATIONS_STATUS.OPEN },
  { label: NOTIFICATIONS_STATUS.CLOSED, value: NOTIFICATIONS_STATUS.CLOSED },
];

export const NOTIFICATIONS_ACKNOWLEDGE = {
  ALL: "All",
  UNACKNOWLEDGED: "Unacknowledged",
  MYNOTIFICATIONS: "My Notifications",
};

export const NOTIFICATIONS_ACKNOWLEDGE_OPTIONS = [
  {
    label: NOTIFICATIONS_ACKNOWLEDGE.ALL,
    value: NOTIFICATIONS_ACKNOWLEDGE.ALL,
  },
  {
    label: NOTIFICATIONS_ACKNOWLEDGE.UNACKNOWLEDGED,
    value: NOTIFICATIONS_ACKNOWLEDGE.UNACKNOWLEDGED,
  },
  {
    label: NOTIFICATIONS_ACKNOWLEDGE.MYNOTIFICATIONS,
    value: NOTIFICATIONS_ACKNOWLEDGE.MYNOTIFICATIONS,
  },
];

export const NOTIFICATIONS_LIST_FITLERS = {
  search: "",
  organisationId: "",
  group: "",
  lifecycle: "",
  eventType: "",
  severity: "",
  timestampGt: "",
  resolved: NOTIFICATIONS_STATUS.ALL,
  acknowledged: NOTIFICATIONS_ACKNOWLEDGE.ALL,
  paginationModel: {
    pageSize: 10,
    page: 0,
  },
  sortModel: [
    {
      field: "timestamp",
      sort: "desc",
    },
  ],
};

export const UNIT_NOTIFICATIONS_LIST_FITLERS = {
  unit: "",
  resolved: NOTIFICATIONS_STATUS.ACTIVE,
  paginationModel: {
    pageSize: 10,
    page: 0,
  },
  sortModel: [
    {
      field: "timestamp",
      sort: "desc",
    },
  ],
};

export const SET_STATUS_DETAILS_CACHED = "@@cached/SET_STATUS_DETAILS_CACHED";

export function setSiteDetailsCached(details) {
  return async (dispatch) => {
    dispatch({
      type: SET_STATUS_DETAILS_CACHED,
      payload: details,
    });
  };
}

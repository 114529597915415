import {
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAILURE,
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE,
  POST_USER_CREATE_REQUEST,
  POST_USER_CREATE_SUCCESS,
  POST_USER_CREATE_FAILURE,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILURE,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  GET_USER_SITE_OPTION_REQUEST,
  GET_USER_SITE_OPTION_SUCCESS,
  GET_USER_SITE_OPTION_FAILURE,
  POST_ASSIGN_SITE_USER_REQUEST,
  POST_ASSIGN_SITE_USER_SUCCESS,
  POST_ASSIGN_SITE_USER_FAILURE,
  USER_ACTION_REQUEST,
  USER_ACTION_SUCCESS,
  USER_ACTION_FAILURE,
  SET_USER_PREFERRED_MFA_REQUEST,
  SET_USER_PREFERRED_MFA_SUCCESS,
  SET_USER_PREFERRED_MFA_FAILURE,
} from "actions/user";
import { getUserRole } from "constants/select";
import { orderBy } from "lodash";
import { toast } from "react-toastify";

const initialState = {
  loading: false,
  list: [],
  userDetails: {},
  assignedSites: [],
};

export default function user(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_USER_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_USER_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: payload.map((it) => {
          return {
            ...it,
            access_type_label: getUserRole(it.access_type),
            value: it.id,
            label: `${it.first_name} ${it.last_name}`,
          };
        }),
      };
    }

    case GET_USER_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        list: [],
      };
    }

    case GET_USER_DETAILS_REQUEST:
    case POST_USER_CREATE_REQUEST:
    case USER_DELETE_REQUEST:
    case USER_UPDATE_REQUEST:
    case POST_ASSIGN_SITE_USER_REQUEST:
    case USER_ACTION_REQUEST:
    case SET_USER_PREFERRED_MFA_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_USER_CREATE_SUCCESS:
    case USER_DELETE_SUCCESS:
    case USER_UPDATE_SUCCESS:
    case POST_ASSIGN_SITE_USER_SUCCESS:
    case USER_ACTION_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        userDetails: payload,
      };
    }

    case GET_USER_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        userDetails: {},
      };
    }

    case POST_USER_CREATE_FAILURE:
    case USER_DELETE_FAILURE:
    case USER_UPDATE_FAILURE:
    case POST_ASSIGN_SITE_USER_FAILURE:
    case USER_ACTION_FAILURE:
    case SET_USER_PREFERRED_MFA_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_USER_SITE_OPTION_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_USER_SITE_OPTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        assignedSites: orderBy(payload, ["name"]).map((it) => {
          return {
            ...it,
            value: it.id,
            label: it.nmi,
          };
        }),
      };
    }

    case GET_USER_SITE_OPTION_FAILURE: {
      return {
        ...state,
        loading: false,
        assignedSites: [],
      };
    }

    case SET_USER_PREFERRED_MFA_SUCCESS: {
      toast.success("Preferred MFA setup done.");
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}

import { API } from "aws-amplify";
import { constructOrganisationUrl } from "utils/actions";

export const GET_SCHEDULES_LIST_REQUEST =
  "@@schedules/GET_SCHEDULES_LIST_REQUEST";
export const GET_SCHEDULES_LIST_SUCCESS =
  "@@schedules/GET_SCHEDULES_LIST_SUCCESS";
export const GET_SCHEDULES_LIST_FAILURE =
  "@@schedules/GET_SCHEDULES_LIST_FAILURE";

export function getSchedulesList() {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_SCHEDULES_LIST_REQUEST,
    });
    try {
      const { profile } = getState();
      const { organisation } = profile;
      let url = "/scheduling/schedule";
      const response = await API.get(
        "cmsAPI",
        constructOrganisationUrl(url, organisation)
      );
      dispatch({
        type: GET_SCHEDULES_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_SCHEDULES_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_SCHEDULE_DETAILS_REQUEST =
  "@@schedules/GET_SCHEDULE_DETAILS_REQUEST";
export const GET_SCHEDULE_DETAILS_SUCCESS =
  "@@schedules/GET_SCHEDULE_DETAILS_SUCCESS";
export const GET_SCHEDULE_DETAILS_FAILURE =
  "@@schedules/GET_SCHEDULE_DETAILS_FAILURE";

export function getScheduleDetails(id) {
  return async (dispatch) => {
    dispatch({
      type: GET_SCHEDULE_DETAILS_REQUEST,
    });
    try {
      let url = `/scheduling/schedule/${id}`;
      const response = await API.get("cmsAPI", url);
      dispatch({
        type: GET_SCHEDULE_DETAILS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_SCHEDULE_DETAILS_FAILURE,
        payload: error,
      });
    }
  };
}

export const CREATE_SCHEDULE_REQUEST = "@@schedules/CREATE_SCHEDULE_REQUEST";
export const CREATE_SCHEDULE_SUCCESS = "@@schedules/CREATE_SCHEDULE_SUCCESS";
export const CREATE_SCHEDULE_FAILURE = "@@schedules/CREATE_SCHEDULE_FAILURE";

export function createSchedule(data, callback) {
  return async (dispatch) => {
    dispatch({
      type: CREATE_SCHEDULE_REQUEST,
    });
    try {
      const init = {
        body: data,
      };
      let url = `/scheduling/schedule`;
      const response = await API.post("cmsAPI", url, init);
      dispatch({
        type: CREATE_SCHEDULE_SUCCESS,
        payload: response,
      });

      if (callback) {
        callback(response.id);
      }
    } catch (error) {
      dispatch({
        type: CREATE_SCHEDULE_FAILURE,
        payload: error,
      });
    }
  };
}

export const UPDATE_SCHEDULE_DETAILS_REQUEST =
  "@@schedules/UPDATE_SCHEDULE_DETAILS_REQUEST";
export const UPDATE_SCHEDULE_DETAILS_SUCCESS =
  "@@schedules/UPDATE_SCHEDULE_DETAILS_SUCCESS";
export const UPDATE_SCHEDULE_DETAILS_FAILURE =
  "@@schedules/UPDATE_SCHEDULE_DETAILS_FAILURE";

export function updateScheduleDetails(id, value, callback) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_SCHEDULE_DETAILS_REQUEST,
    });
    try {
      const init = {
        body: value,
      };
      let url = `/scheduling/schedule/${id}`;
      const response = await API.put("cmsAPI", url, init);
      dispatch({
        type: UPDATE_SCHEDULE_DETAILS_SUCCESS,
        payload: response,
      });

      if (callback) {
        callback(response.id);
      }
    } catch (error) {
      dispatch({
        type: UPDATE_SCHEDULE_DETAILS_FAILURE,
        payload: error,
      });
    }
  };
}

export const UPDATE_SCHEDULE_REQUEST = "@@schedules/UPDATE_SCHEDULE_REQUEST";
export const UPDATE_SCHEDULE_SUCCESS = "@@schedules/UPDATE_SCHEDULE_SUCCESS";
export const UPDATE_SCHEDULE_FAILURE = "@@schedules/UPDATE_SCHEDULE_FAILURE";

export function updateSchedule(id, data) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_SCHEDULE_REQUEST,
    });
    try {
      const init = {
        body: data,
      };
      let url = `/scheduling/schedule/${id}`;
      const response = await API.patch("cmsAPI", url, init);
      dispatch({
        type: UPDATE_SCHEDULE_SUCCESS,
        payload: response,
      });

      dispatch(getSchedulesList());
    } catch (error) {
      dispatch({
        type: UPDATE_SCHEDULE_FAILURE,
        payload: error,
      });
    }
  };
}

export const DELETE_SCHEDULE_REQUEST = "@@schedules/DELETE_SCHEDULE_REQUEST";
export const DELETE_SCHEDULE_SUCCESS = "@@schedules/DELETE_SCHEDULE_SUCCESS";
export const DELETE_SCHEDULE_FAILURE = "@@schedules/DELETE_SCHEDULE_FAILURE";

export function deleteSchedule(id) {
  return async (dispatch) => {
    dispatch({
      type: DELETE_SCHEDULE_REQUEST,
    });
    try {
      let url = `/scheduling/schedule/${id}`;
      const response = await API.del("cmsAPI", url);
      dispatch({
        type: DELETE_SCHEDULE_SUCCESS,
        payload: response,
      });

      dispatch(getSchedulesList());
    } catch (error) {
      dispatch({
        type: DELETE_SCHEDULE_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_ASSIGNED_LIST_REQUEST =
  "@@schedules/GET_ASSIGNED_LIST_REQUEST";
export const GET_ASSIGNED_LIST_SUCCESS =
  "@@schedules/GET_ASSIGNED_LIST_SUCCESS";
export const GET_ASSIGNED_LIST_FAILURE =
  "@@schedules/GET_ASSIGNED_LIST_FAILURE";

export function getAssignedList() {
  return async (dispatch) => {
    dispatch({
      type: GET_ASSIGNED_LIST_REQUEST,
    });
    try {
      let url = "/scheduling/assign";
      const response = await API.get("cmsAPI", url);
      dispatch({
        type: GET_ASSIGNED_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_ASSIGNED_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const ASSIGN_SCHEDULE_REQUEST = "@@schedules/ASSIGN_SCHEDULE_REQUEST";
export const ASSIGN_SCHEDULE_SUCCESS = "@@schedules/ASSIGN_SCHEDULE_SUCCESS";
export const ASSIGN_SCHEDULE_FAILURE = "@@schedules/ASSIGN_SCHEDULE_FAILURE";

export function assignSchedule(data) {
  return async (dispatch) => {
    dispatch({
      type: ASSIGN_SCHEDULE_REQUEST,
    });
    try {
      const init = {
        body: data,
      };
      let url = "/scheduling/assign";
      const response = await API.post("cmsAPI", url, init);
      dispatch({
        type: ASSIGN_SCHEDULE_SUCCESS,
        payload: response,
      });

      dispatch(getAssignedList());
    } catch (error) {
      dispatch({
        type: ASSIGN_SCHEDULE_FAILURE,
        payload: error,
      });
    }
  };
}

export const DELETE_ASSIGN_SCHEDULE_REQUEST =
  "@@schedules/DELETE_ASSIGN_SCHEDULE_REQUEST";
export const DELETE_ASSIGN_SCHEDULE_SUCCESS =
  "@@schedules/DELETE_ASSIGN_SCHEDULE_SUCCESS";
export const DELETE_ASSIGN_SCHEDULE_FAILURE =
  "@@schedules/DELETE_ASSIGN_SCHEDULE_FAILURE";

export function deleteAssignSchedule(id) {
  return async (dispatch) => {
    dispatch({
      type: DELETE_ASSIGN_SCHEDULE_REQUEST,
    });
    try {
      let url = `/scheduling/assign/${id}`;
      const response = await API.del("cmsAPI", url);
      dispatch({
        type: DELETE_ASSIGN_SCHEDULE_SUCCESS,
        payload: response,
      });

      dispatch(getAssignedList());
    } catch (error) {
      dispatch({
        type: DELETE_ASSIGN_SCHEDULE_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_SCHEDULE_OPTIONS_LIST_REQUEST =
  "@@schedules/GET_SCHEDULE_OPTIONS_LIST_REQUEST";
export const GET_SCHEDULE_OPTIONS_LIST_SUCCESS =
  "@@schedules/GET_SCHEDULE_OPTIONS_LIST_SUCCESS";
export const GET_SCHEDULE_OPTIONS_LIST_FAILURE =
  "@@schedules/GET_SCHEDULE_OPTIONS_LIST_FAILURE";

export function getScheduleOptionList() {
  return async (dispatch) => {
    dispatch({
      type: GET_SCHEDULE_OPTIONS_LIST_REQUEST,
    });
    try {
      let url = "/scheduling/option?type=schedule";
      const response = await API.get("cmsAPI", url);
      dispatch({
        type: GET_SCHEDULE_OPTIONS_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_SCHEDULE_OPTIONS_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const CLEAR_SCHEDULE_DETAILS = "@@schedules/CLEAR_SCHEDULE_DETAILS";

export function clearScheduleDetails() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_SCHEDULE_DETAILS,
    });
  };
}

import { useState } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button,
  Paper,
} from "@mui/material";

const styles = {
  fontSize: "16px",
  marginBottom: "8px",
  "& .MuiButton-outlinedSizeSmall": {
    fontSize: "16px !important",
    marginBottom: "4px",
  },
  ".listContainer": {
    width: "40%",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  ".list": {
    minHeight: "400px",
    maxHeight: "calc(100vh - 250px)",
    overflowX: "auto",
    "& .MuiTypography-root": {
      fontSize: "16px",
    },
  },
  ".buttons": {
    display: "flex",
    alignItems: "center",
    mr: 2,
  },
};

function not(a, b, refList) {
  const unchecked = a.filter(
    (id) => b.map((it) => it.value).indexOf(id) === -1
  );
  const list = [];

  unchecked.forEach((value) => {
    const item = refList.find((it) => it.value === value);
    if (item) {
      list.push(item);
    }
  });
  return list;
}

function intersection(a, b) {
  const checked = a.filter((id) => b.map((it) => it.value).indexOf(id) !== -1);
  const list = [];

  checked.forEach((value) => {
    const item = b.find((it) => it.value === value);
    if (item) {
      list.push(item);
    }
  });
  return list;
}

const TransferList = ({ left = [], setLeft, right = [], setRight }) => {
  const [checked, setChecked] = useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (id) => () => {
    const currentIndex = checked.indexOf(id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(
      not(
        left.map((it) => it.value),
        leftChecked,
        left
      )
    );
    setChecked(not(checked, leftChecked, left));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(
      not(
        right.map((it) => it.value),
        rightChecked,
        right
      )
    );
    setChecked(not(checked, rightChecked, right));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (list) => (
    <List dense component="div" role="list">
      {list.map((item) => {
        const labelId = `transfer-list-item-${item.label}-label`;

        return (
          <ListItem
            key={item.value}
            role="listitem"
            button
            onClick={handleToggle(item.value)}
            style={{
              wordBreak: "break-all",
            }}
          >
            <ListItemIcon>
              <Checkbox
                checked={checked.indexOf(item.value) !== -1}
                tabIndex={-1}
                disableRipple
                color="primary"
                inputProps={{
                  "aria-labelledby": labelId,
                }}
              />
            </ListItemIcon>
            <ListItemText id={labelId} primary={`${item.label}`} />
          </ListItem>
        );
      })}
      <ListItem />
    </List>
  );

  return (
    <Grid sx={styles} container spacing={2} justifyContent="center">
      <Paper className="listContainer">
        <Grid item className="list">
          {customList(left)}
        </Grid>
      </Paper>
      <Grid item className="buttons">
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Paper className="listContainer">
        <Grid item className="list">
          {customList(right)}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default TransferList;

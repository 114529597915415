const GridArrow = ({ positive }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        color: positive ? "#FF4842" : "#28B178",
        transform: positive ? "unset" : "rotate(180deg)",
      }}
    >
      <path
        d="M8.00008 5.33329V2.66663L13.3334 7.99996L8.00008 13.3333V10.6666H2.66675V5.33329H8.00008Z"
        fill="currentcolor"
      />
    </svg>
  );
};

export default GridArrow;

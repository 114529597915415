import {
  AdminPanelSettings,
  Badge,
  Business,
  Chalet,
  Dashboard,
  DisplaySettings,
  Diversity3,
  Event,
  ManageHistory,
  NotificationsActive,
  People,
  Usb,
} from "@mui/icons-material";

export const MENU_OPTIONS = [
  {
    label: "Dashboard",
    to: "/dashboard",
    icon: <Dashboard />,
    access: "dashboard",
  },
  {
    label: "Notifications",
    to: "/notifications",
    icon: <NotificationsActive />,
    access: "notifications",
  },
  { label: "Events", to: "/events", icon: <Event />, access: "events" },
  { label: "Units", to: "/units", icon: <Chalet />, access: "units" },
  { label: "Sites", to: "/sites", icon: <Business />, access: "sites" },
  { label: "Groups", to: "/groups", icon: <Diversity3 />, access: "groups" },
  {
    label: "Schedules",
    to: "/scheduling",
    icon: <ManageHistory />,
    access: "scheduling",
  },
  { label: "Slots", to: "/slots", icon: <Usb />, access: "slots" },
  {
    label: "Configuration",
    to: "/configuration",
    icon: <DisplaySettings />,
    access: "configuration",
  },
  {
    label: "Fleet Users",
    to: "/users/fleet",
    icon: <People />,
    access: "users_fleet",
  },
  {
    label: "Installers",
    to: "/users/installer",
    icon: <Badge />,
    access: "users_installer",
  },
  {
    label: "Owners",
    to: "/users/owner",
    icon: <AdminPanelSettings />,
    access: "users_owner",
  },
];

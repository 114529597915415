import { API } from "aws-amplify";
import {
  constructOrganisationUrl,
  constructStatusQueryString,
} from "utils/actions";

export const GET_GROUP_REQUEST = "@@groups/GET_GROUP_REQUEST";
export const GET_GROUP_SUCCESS = "@@groups/GET_GROUP_SUCCESS";
export const GET_GROUP_FAILURE = "@@groups/GET_GROUP_FAILURE";

export function getGroupDetails(id) {
  return async (dispatch) => {
    dispatch({
      type: GET_GROUP_REQUEST,
    });
    try {
      let url = `/group/${id}`;
      const response = await API.get("cmsAPI", url);
      dispatch({
        type: GET_GROUP_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_GROUP_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_GROUPS_LIST_REQUEST = "@@groups/GET_GROUPS_LIST_REQUEST";
export const GET_GROUPS_LIST_SUCCESS = "@@groups/GET_GROUPS_LIST_SUCCESS";
export const GET_GROUPS_LIST_FAILURE = "@@groups/GET_GROUPS_LIST_FAILURE";

export function getGroupsList() {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_GROUPS_LIST_REQUEST,
    });
    try {
      const { profile } = getState();
      const { organisation } = profile;
      let url = "/group";
      const response = await API.get(
        "cmsAPI",
        constructOrganisationUrl(url, organisation)
      );
      dispatch({
        type: GET_GROUPS_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_GROUPS_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const POST_GROUP_REQUEST = "@@groups/POST_GROUP_REQUEST";
export const POST_GROUP_SUCCESS = "@@groups/POST_GROUP_SUCCESS";
export const POST_GROUP_FAILURE = "@@groups/POST_GROUP_FAILURE";

export function createGroup(value, callback) {
  return async (dispatch, getState) => {
    dispatch({
      type: POST_GROUP_REQUEST,
    });
    try {
      const { profile } = getState();
      const { organisation } = profile;

      const init = {
        body: {
          ...value
        },
      };

      let url = `/group?organisation=${organisation}`;
      const response = await API.post("cmsAPI", url, init);
      dispatch({
        type: POST_GROUP_SUCCESS,
        payload: response,
      });

      dispatch(getGroupsList());

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({
        type: POST_GROUP_FAILURE,
        payload: error,
      });
    }
  };
}

export const PUT_GROUP_REQUEST = "@@groups/PUT_GROUP_REQUEST";
export const PUT_GROUP_SUCCESS = "@@groups/PUT_GROUP_SUCCESS";
export const PUT_GROUP_FAILURE = "@@groups/PUT_GROUP_FAILURE";

export function updateGroup(value, callback) {
  return async (dispatch, getState) => {
    dispatch({
      type: PUT_GROUP_REQUEST,
    });
    try {
      const { profile } = getState();
      const { organisation } = profile;

      const init = {
        body: {
          ...value,
          organisation: organisation,
        },
      };

      let url = `/group/${value.id}`;
      const response = await API.put("cmsAPI", url, init);
      dispatch({
        type: PUT_GROUP_SUCCESS,
        payload: response,
      });

      dispatch(getGroupsList());

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({
        type: PUT_GROUP_FAILURE,
        payload: error,
      });
    }
  };
}

export const DELETE_GROUP_REQUEST = "@@groups/DELETE_GROUP_REQUEST";
export const DELETE_GROUP_SUCCESS = "@@groups/DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_FAILURE = "@@groups/DELETE_GROUP_FAILURE";

export function deleteGroup(id) {
  return async (dispatch) => {
    dispatch({
      type: DELETE_GROUP_REQUEST,
    });
    try {
      let url = `/group/${id}`;
      const response = await API.del("cmsAPI", url);
      dispatch({
        type: DELETE_GROUP_SUCCESS,
        payload: response,
      });

      dispatch(getGroupsList());
    } catch (error) {
      dispatch({
        type: DELETE_GROUP_FAILURE,
        payload: error,
      });
    }
  };
}

export const ASSIGN_SITE_GROUP_REQUEST = "@@groups/ASSIGN_SITE_GROUP_REQUEST";
export const ASSIGN_SITE_GROUP_SUCCESS = "@@groups/ASSIGN_SITE_GROUP_SUCCESS";
export const ASSIGN_SITE_GROUP_FAILURE = "@@groups/ASSIGN_SITE_GROUP_FAILURE";

export function assignSitetoGroup(id, value, callback) {
  return async (dispatch) => {
    dispatch({
      type: ASSIGN_SITE_GROUP_REQUEST,
    });
    try {
      const init = {
        body: value,
      };

      let url = `/group/${id}/site`;
      const response = await API.put("cmsAPI", url, init);
      dispatch({
        type: ASSIGN_SITE_GROUP_SUCCESS,
        payload: response,
      });

      dispatch(getGroupsList());

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({
        type: ASSIGN_SITE_GROUP_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_FILTERED_GROUPS_LIST_REQUEST =
  "@@groups/GET_FILTERED_GROUPS_LIST_REQUEST";
export const GET_FILTERED_GROUPS_LIST_SUCCESS =
  "@@groups/GET_FILTERED_GROUPS_LIST_SUCCESS";
export const GET_FILTERED_GROUPS_LIST_FAILURE =
  "@@groups/GET_FILTERED_GROUPS_LIST_FAILURE";

export function getFilteredGroupsList(formData = {}) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_FILTERED_GROUPS_LIST_REQUEST,
    });
    try {
      const { profile } = getState();
      const { organisation } = profile;
      let url = "/group";

      if (formData) {
        url = constructStatusQueryString(url, formData);
      } else {
        constructOrganisationUrl(url, organisation);
      }

      const response = await API.get("cmsAPI", url);
      dispatch({
        type: GET_FILTERED_GROUPS_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_FILTERED_GROUPS_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

import {
  GET_ORGANISATIONS_LIST_REQUEST,
  GET_ORGANISATIONS_LIST_SUCCESS,
  GET_ORGANISATIONS_LIST_FAILURE,
  PUT_ORGANISATION_REQUEST,
  POST_ORGANISATION_REQUEST,
  PUT_ORGANISATION_FAILURE,
  POST_ORGANISATION_FAILURE,
  POST_ORGANISATION_SUCCESS,
  PUT_ORGANISATION_SUCCESS,
  DELETE_ORGANISATION_REQUEST,
  DELETE_ORGANISATION_SUCCESS,
  DELETE_ORGANISATION_FAILURE,
  GET_ORGANISATION_SUCCESS,
  GET_ORGANISATION_REQUEST,
  GET_ORGANISATION_FAILURE,
  GET_SITE_ORGANISATIONS_LIST_REQUEST,
  GET_SITE_ORGANISATIONS_LIST_FAILURE,
  GET_SITE_ORGANISATIONS_LIST_SUCCESS,
} from "actions/organisation";
import { USER_TYPE } from "components/UserPage/utils";

const initialState = {
  loading: false,
  error: {},
  list: [],
  details: {},
  installerList: [],
  siteOrganisations: [],
  installerOrganisations: [],
};

export default function organisation(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ORGANISATIONS_LIST_REQUEST:
    case POST_ORGANISATION_REQUEST:
    case PUT_ORGANISATION_REQUEST:
    case DELETE_ORGANISATION_REQUEST:
    case GET_ORGANISATION_REQUEST:
    case GET_SITE_ORGANISATIONS_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_ORGANISATIONS_LIST_SUCCESS: {
      const isInstaller = action.userType === USER_TYPE.INSTALLER;
      const list = payload.map((it) => {
        return {
          ...it,
          value: it.id,
          label: it.name,
        };
      });

      return {
        ...state,
        loading: false,
        list: isInstaller ? state.list : list,
        installerList: isInstaller ? list : state.installerList,
      };
    }

    case GET_SITE_ORGANISATIONS_LIST_SUCCESS: {
      const isInstaller = action.userType === USER_TYPE.INSTALLER;
      const list = payload.map((it) => {
        return {
          ...it,
          value: it.id,
          label: it.name,
        };
      });

      return {
        ...state,
        loading: false,
        siteOrganisations: isInstaller ? state.siteOrganisations : list,
        installerOrganisations: isInstaller
          ? list
          : state.installerOrganisations,
      };
    }

    case GET_ORGANISATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        details: payload,
      };
    }

    case POST_ORGANISATION_SUCCESS:
    case PUT_ORGANISATION_SUCCESS:
    case DELETE_ORGANISATION_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_ORGANISATIONS_LIST_FAILURE:
    case POST_ORGANISATION_FAILURE:
    case PUT_ORGANISATION_FAILURE:
    case DELETE_ORGANISATION_FAILURE:
    case GET_ORGANISATION_FAILURE:
    case GET_SITE_ORGANISATIONS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}

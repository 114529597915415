import { Box } from "@mui/material";
import {
  renderDate,
  renderSource,
} from "components/DataTable/renderComponents";
import { DataTable } from "empower-energy-owner-components";

const NotesList = ({
  loading,
  list,
  totalCount,
  paginationModel,
  setPaginationModel,
  sortModel,
  setSortModel,
}) => {
  const columns = [
    {
      field: "message",
      headerName: "Message",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "source",
      headerName: "Source",
      width: 200,
      renderCell: (params) => renderSource(params.row),
    },
    {
      field: "created_at",
      headerName: "Created",
      width: 200,
      renderCell: (params) => renderDate(params.value),
    },
    {
      field: "created_by",
      headerName: "Created By",
      width: 300,
    },
  ];

  return (
    <Box>
      <DataTable
        rows={list}
        columns={columns}
        loading={loading}
        paginationMode="server"
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        totalCount={totalCount}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        sortingMode="server"
        sortModel={sortModel}
        setSortModel={setSortModel}
      />
    </Box>
  );
};

export default NotesList;

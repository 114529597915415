import { format } from "date-fns";

export const RELEASE_LABEL = {
  DEPRECATED: "Deprecated",
  FULL: "Full",
  BETA: "Beta",
  ALPHA: "Alpha",
};

export const RELEASE_STAGE = {
  DEPRECATED: "deprecated",
  FULL: "full",
  BETA: "beta",
  ALPHA: "alpha",
};

export const getRelease = (item) => {
  if (item?.deprecated) {
    return RELEASE_LABEL.DEPRECATED;
  } else if (item?.released) {
    return RELEASE_LABEL.FULL;
  } else if (item?.beta) {
    return RELEASE_LABEL.BETA;
  } else if (item?.alpha) {
    return RELEASE_LABEL.ALPHA;
  }
  return "";
};

export const getReleaseDate = (item) => {
  if (item?.deprecated) {
    return convertDate(item?.deprecated);
  } else if (item?.released) {
    return convertDate(item?.released);
  } else if (item?.beta) {
    return convertDate(item?.beta);
  } else if (item?.alpha) {
    return convertDate(item?.alpha);
  }
  return "";
};

export const convertDate = (date) => {
  if (!date) return "";
  return `${format(new Date(date * 1000), "dd/MM/yyyy, HH:mm:ss")} [Local]`;
};

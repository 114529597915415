export const DASHBOARD = "dashboard";
export const READONLY = "readonly";
export const READWRITE = "readwrite";
export const ADMIN = "admin";

export const USER_ROLES = [
  { value: READONLY, label: "Read Only" },
  { value: READWRITE, label: "Read Write" },
  { value: ADMIN, label: "Admin" },
];

export const getUserRole = (type) => {
  const item = USER_ROLES.find((it) => it.value === type);
  if (item) {
    return item.label;
  }

  return "";
};

export const YES_NO_OPTIONS = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

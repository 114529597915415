import {
  GET_EVENTS_LIST_REQUEST,
  GET_EVENTS_LIST_SUCCESS,
  GET_EVENTS_LIST_FAILURE,
} from "actions/events";
import { orderBy } from "lodash";

const initialState = {
  loading: false,
  error: {},
  list: [],
  details: {},
};

export default function events(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_EVENTS_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_EVENTS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: orderBy(payload, ["timestamp"], "desc").map((it, i) => {
          return {
            ...it,
            id: i,
          };
        }),
      };
    }

    case GET_EVENTS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { CloudUpload, Delete } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { isNil } from "lodash";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const FileUpload = ({ onChange, file }) => {
  const handleChange = (e) => {
    onChange(e.target.files[0]);
  };

  return (
    <Box>
      {isNil(file) && (
        <Button
          component="label"
          variant="outlined"
          startIcon={<CloudUpload />}
        >
          Select file
          <VisuallyHiddenInput type="file" onChange={handleChange} />
        </Button>
      )}

      {!isNil(file) && (
        <Box className="items-center">
          <Box>{file.name}</Box>
          <IconButton color="primary" onClick={() => onChange(null)}>
            <Delete fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default FileUpload;

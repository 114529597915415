import { Search } from "@mui/icons-material";
import { Box, InputAdornment, TextField } from "@mui/material";
import { getGroupsList } from "actions/groups";
import { getOrganisationsList } from "actions/organisation";
import { USER_TYPE } from "components/UserPage/utils";
import { CONTROL_MODE_STATUS_OPTIONS, LIFECYCLE_OPTIONS, UNIT_LIST_FITLERS } from "constants/unit";
import { FormField } from "empower-energy-owner-components";
import useDebounce from "hooks/useDebounce";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const styles = () => {
  return {
    "& .mui-field": {
      width: "180px",
      mr: 2,
      "& .MuiInputBase-root": {
        borderWidth: "1px",
      },
    },
  };
};

const UnitFilter = ({ onChangeFilters, filters }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(UNIT_LIST_FITLERS);

  const [initialLoad, setInitialLoad] = useState(false);

  const profile = useSelector((state) => state.profile);
  const organisationList = useSelector((state) => state.organisation?.list);
  const groupList = useSelector((state) => state.groups?.list);
  const installerList = useSelector(
    (state) => state.organisation?.installerList
  );

  const debouncedSearch = useDebounce(formData.search, 500);

  useEffect(() => {
    dispatch(getGroupsList());
  }, [dispatch]);

  useEffect(() => {
    if (profile?.is_staff) {
      dispatch(getOrganisationsList());
      dispatch(getOrganisationsList(USER_TYPE.INSTALLER));
    }
  }, [profile]);

  useEffect(() => {
    setFormData(filters);
  }, [filters]);

  useEffect(() => {
    if (initialLoad) {
      onChangeFilters(formData);
    }
  }, [debouncedSearch]);

  const onChangeSearch = (e) => {
    setInitialLoad(true);
    const data = {
      ...UNIT_LIST_FITLERS,
      search: e.target.value,
    };
    setFormData(data);
  };

  const onChange = (e) => {
    const data = { ...formData, [e.target.name]: e.target.value };
    setFormData(data);
    onChangeFilters(data);
  };

  const constructOptions = (list) => {
    const options = list.filter((it) => it.value !== "");
    options.unshift({
      value: "",
      label: "Select All",
    });
    return options;
  };

  return (
    <Box my={2} className="items-center row-wrap" sx={styles}>
      <Box className="items-center flex-grow">
        {profile?.is_staff && (
          <FormField
            label="Select Organisation"
            name="organisation"
            type="select"
            variant="outlined"
            value={formData.organisation}
            onChange={onChange}
            options={constructOptions(organisationList)}
          />
        )}

        <FormField
          label="Select Group"
          name="group"
          type="select"
          variant="outlined"
          value={formData.group}
          onChange={onChange}
          options={constructOptions(groupList)}
        />

        {profile?.is_staff && (
          <FormField
            label="Select Installer"
            name="installer"
            type="select"
            variant="outlined"
            value={formData.installer}
            onChange={onChange}
            options={constructOptions(installerList)}
          />
        )}

        <FormField
          label="Select Lifecycle"
          name="lifecycle"
          type="select"
          variant="outlined"
          value={formData.lifecycle}
          onChange={onChange}
          options={constructOptions(LIFECYCLE_OPTIONS)}
        />

        <FormField
          label="Select Control Mode"
          name="control_mode_status"
          type="select"
          variant="outlined"
          value={formData.control_mode_status}
          onChange={onChange}
          options={constructOptions(CONTROL_MODE_STATUS_OPTIONS)}
        />

        {/* <FormField
          label="Online"
          name="online"
          value={formData.online}
          onChange={onChange}
          type="checkbox"
        /> */}
      </Box>
      <Box className="items-center">
        <TextField
          size="small"
          label="Search"
          name="search"
          variant="outlined"
          value={formData.search}
          onChange={onChangeSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default UnitFilter;

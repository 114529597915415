import { SITE_VIEW } from "./unit";

export const SITE_DETAILS_OPTION = {
  STATUS: "Status",
  HISTORY: "History",
};

export const SITE_DETAILS_OPTIONS_TABS = [
  {
    value: SITE_DETAILS_OPTION.STATUS,
    label: SITE_DETAILS_OPTION.STATUS,
    accessKey: "status",
  },
  {
    value: SITE_DETAILS_OPTION.HISTORY,
    label: SITE_DETAILS_OPTION.HISTORY,
    accessKey: "history",
  },
];

export const SITE_LIST_FITLERS = {
  organisation: "",
  group: "",
  state: "",
  dnsp: "",
  search: "",
  view: SITE_VIEW.LIST,
  paginationModel: {
    pageSize: 10,
    page: 0,
  },
  sortModel: [
    {
      field: "nmi",
      sort: "asc",
    },
  ],
};

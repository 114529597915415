import {
  DEPRECATE_PATCH_FAILURE,
  DEPRECATE_PATCH_REQUEST,
  DEPRECATE_PATCH_SUCCESS,
  GET_PATCHES_LIST_FAILURE,
  GET_PATCHES_LIST_REQUEST,
  GET_PATCHES_LIST_SUCCESS,
  GET_PATCH_DETAILS_FAILURE,
  GET_PATCH_DETAILS_REQUEST,
  GET_PATCH_DETAILS_SUCCESS,
  POST_PATCH_FAILURE,
  POST_PATCH_REQUEST,
  POST_PATCH_SUCCESS,
  PUT_PATCH_FAILURE,
  PUT_PATCH_REQUEST,
  PUT_PATCH_SUCCESS,
  RELEASE_PATCH_FAILURE,
  RELEASE_PATCH_REQUEST,
  RELEASE_PATCH_SUCCESS,
} from "actions/upgrade";
import { orderBy } from "lodash";
import { getRelease, getReleaseDate } from "utils/upgrade";

const initialState = {
  loading: false,
  error: {},
  list: [],
  details: {},
};

export default function upgrade(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PATCH_DETAILS_REQUEST:
    case GET_PATCHES_LIST_REQUEST:
    case POST_PATCH_REQUEST:
    case PUT_PATCH_REQUEST:
    case DEPRECATE_PATCH_REQUEST:
    case RELEASE_PATCH_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_PATCHES_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: orderBy(payload, ["patch"], ["desc"]).map((it, i) => {
          return {
            ...it,
            id: i,
            releaseLabel: getRelease(it),
            releaseDate: getReleaseDate(it),
          };
        }),
      };
    }

    case GET_PATCHES_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_PATCH_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        details: {
          ...payload,
          releaseLabel: getRelease(payload),
          releaseDate: getReleaseDate(payload),
        },
      };
    }

    case GET_PATCH_DETAILS_FAILURE:
    case POST_PATCH_SUCCESS:
    case POST_PATCH_FAILURE:
    case PUT_PATCH_SUCCESS:
    case PUT_PATCH_FAILURE:
    case DEPRECATE_PATCH_SUCCESS:
    case DEPRECATE_PATCH_FAILURE:
    case RELEASE_PATCH_SUCCESS:
    case RELEASE_PATCH_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Hub } from "aws-amplify";
import {
  clearAllSessionData,
  compareAndUpdateSession,
  sessionRefresh,
} from "actions/session";
import { getProfile } from "actions/profile";
import { isNil } from "lodash";

const REMAINING = 60;

const SessionCheck = () => {
  const dispatch = useDispatch();

  const [remaining, setRemaining] = useState(undefined);

  const session = useSelector((state) => state.session.session);
  const loading = useSelector((state) => state.session.loading);

  useEffect(() => {
    Hub.listen("auth", authListener);
    return () => Hub.remove("auth", authListener);
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(remainingTokenTime());
    }, 1000);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (!isNil(remaining) && remaining <= REMAINING && !loading) {
      extendSession();
    } else if (!isNil(remaining) && remaining <= 0) {
      extendSession();
    } else {
      if (remaining >= 0 && remaining < REMAINING && remaining % 5 === 0) {
        dispatch(compareAndUpdateSession(session));
      }
    }
  }, [remaining]);

  const remainingTokenTime = () => {
    if (session) {
      const currentUnixTime = Math.round(new Date().getTime() / 1000);
      return session.idToken.payload.exp - currentUnixTime;
    }
    return undefined;
  };

  const authListener = (data) => {
    switch (data.payload.event) {
      case "signIn":
        console.log("Auth: user signed in");
        dispatch(getProfile());
        break;
      case "signUp":
        console.log("Auth: user signed up");
        break;
      case "signOut":
        console.log("Auth: user signed out");
        dispatch(clearAllSessionData());
        break;
      case "sessionExpired":
        console.log("Auth: session expired");
        dispatch(clearAllSessionData());
        break;
      case "signIn_failure":
        console.log("Auth: user sign in failed");
        break;
      case "configured":
        console.log("Auth: the module is configured");
        break;
      default:
        console.log("Auth:", data.payload.event);
    }
  };

  const extendSession = () => {
    console.log("Extend Session");
    dispatch(sessionRefresh());
  };

  return <div></div>;
};

export default SessionCheck;

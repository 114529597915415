import {
  GET_OPTIONS_REQUEST,
  GET_OPTIONS_SUCCESS,
  GET_OPTIONS_FAILURE,
} from "actions/option";

const initialState = {
  loading: false,
  dnsp: [],
  region: [],
  location: [],
  meter_model: [],
  error: {},
};

export default function option(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_OPTIONS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_OPTIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        ...payload,
        location: payload.location.map((it) => {
          return {
            ...it,
            value: it.location,
            label: it.state_name,
          };
        }),
        meter_model: payload.meter_model.map((it) => {
          return {
            ...it,
            value: it.id,
            label: `${it.manufacturer} ${it.model}`,
          };
        }),
        event_parameter: payload.event_parameter.map((it) => {
          return {
            ...it,
            value: it.parameter,
            label: it.name,
          };
        }),
      };
    }

    case GET_OPTIONS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}

import { ChevronLeft } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";

const BackLink = ({ to = "list", onClick }) => {
  return (
    <Button onClick={onClick} sx={{ mb: 1.5 }} startIcon={<ChevronLeft />}>
      {" "}
      <Typography variant="subtitle2">Back to {to}</Typography>
    </Button>
  );
};

export default BackLink;

/* eslint-disable no-undef */
import { Box, Button } from "@mui/material";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import SiteName from "components/Site/SiteName";
import UnitSiteStatus from "components/Unit/UnitSiteStatus";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const containerStyle = {
  minWidth: "100%",
  minHeight: "400px",
  height: "100%",
};

const SiteMap = ({ list = [] }) => {
  const navigate = useNavigate();

  const initialCenter = {
    lat: -33.765515,
    lng: 151.272005,
  };

  const [zoom, setZoom] = useState(10);
  const [center, setCenter] = useState(initialCenter);
  const [locatedCenter, setLocatedCenter] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (list.length > 0 && !locatedCenter) {
      const site = list[0];
      setLocatedCenter(true);
      setCenter({
        lat: site?.latitude,
        lng: site.longitude,
      });
      setZoom(10);
    }
  }, [list]);

  const onLoad = useCallback(function callback(map) {
    map.setZoom(zoom);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const onClickSite = (item) => {
    navigate(`/sites/details/${item.id}`);
  };

  return (
    <Box height="100%">
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          options={{ zoomControlOptions: { position: 5 } }}
          center={center}
          zoom={zoom}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          <Box>
            {list.map((marker, i) => (
              <Marker
                key={i}
                {...marker}
                title={marker.nmi}
                name={marker.nmi}
                position={{
                  lat: Number(marker.latitude),
                  lng: Number(marker.longitude),
                }}
                onClick={() => setActiveMarker(marker)}
              >
                {activeMarker?.id === marker.id ? (
                  <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                    <Box width="300px">
                      <Box className="items-center">
                        <SiteName id={marker.id} bold={true} />
                        <Button onClick={() => onClickSite(marker)}>
                          Navigate to Site
                        </Button>
                      </Box>

                      {marker?.units?.map((unit, i) => (
                        <UnitSiteStatus key={i} status={unit} />
                      ))}
                    </Box>
                  </InfoWindow>
                ) : null}
              </Marker>
            ))}
          </Box>
        </GoogleMap>
      )}
    </Box>
  );
};

export default SiteMap;

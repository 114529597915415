import { LoadingButton } from "@mui/lab";
import { createName } from "actions/name";
import { FormField } from "empower-energy-owner-components";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import { Modal } from "components";

const NameModal = ({ open, onClose, id, type, name }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    item: "",
  });

  const [errors, setErrors] = useState({
    name: "",
  });

  const loading = useSelector((state) => state.name?.loading);

  useEffect(() => {
    if (id) {
      setFormData({ ...formData, item: id });
    }
  }, [id]);

  const onChange = (e) => {
    let data = { ...formData, [e.target.name]: e.target.value };

    setFormData(data);
    checkErrors(e);
  };

  const checkErrors = (e) => {
    setErrors({
      ...errors,
      [e.target.name]: isEmpty(String(e.target.value)) ? "Required" : "",
    });
  };

  const onClickSave = async (e) => {
    e.preventDefault();
    await dispatch(createName(formData));
    onClose();
  };

  const anyError = () => !Object.values(errors).every((it) => isEmpty(it));

  return (
    <Box>
      <Modal
        title={`Add/Update ${type} Name - (${name || ""})`}
        open={open}
        onClose={onClose}
        width={500}
        top={"10%"}
      >
        <form>
          <FormField
            label={`Enter ${type} Name`}
            placeholder={`Enter ${type} Name`}
            name="name"
            variant="standard"
            value={formData.name}
            onChange={onChange}
            error={errors.name}
            onBlur={checkErrors}
          />

          <Box
            className="items-center space-between"
            mt={4}
            sx={{
              "& button": {
                width: "48%",
              },
            }}
          >
            <LoadingButton
              loading={loading}
              onClick={onClickSave}
              variant="contained"
              disabled={anyError() || isEmpty(formData.name)}
            >
              Save
            </LoadingButton>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
          </Box>
        </form>
      </Modal>
    </Box>
  );
};

export default NameModal;

import { LoadingButton } from "@mui/lab";
import { Box, Typography, Button } from "@mui/material";
import { getUserDetails, setUserPreferredMFA } from "actions/user";
import { Modal } from "components";
import { FormField } from "empower-energy-owner-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const SetMFAUserModal = ({ selectedUser, open, onClose, userType = "" }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    mfa: "",
  });

  const [mfaAvailable, setMfaAvailable] = useState([]);

  const userDetails = useSelector((state) => state.user?.userDetails);
  const loading = useSelector((state) => state.user?.loading);

  useEffect(() => {
    if (selectedUser?.id) {
      dispatch(getUserDetails(selectedUser?.id, userType));
    }
  }, [selectedUser, dispatch]);

  useEffect(() => {
    if (userDetails?.id) {
      setFormData({ ...formData, mfa: userDetails?.mfa || "" });

      const mfa_options = ["SMS_MFA"];

      if (
        userDetails?.mfa_available &&
        userDetails?.mfa_available.includes("SOFTWARE_TOKEN_MFA")
      ) {
        mfa_options.push("SOFTWARE_TOKEN_MFA");
      }

      setMfaAvailable(
        mfa_options.map((it) => {
          return {
            value: it,
            label: it,
          };
        })
      );
    }
  }, [userDetails]);

  const onClickSave = () => {
    dispatch(setUserPreferredMFA(formData, selectedUser?.id, userType));
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Box>
      <Modal
        title="Set Preferred MFA"
        open={open}
        onClose={onClose}
        width={400}
        top={"20%"}
      >
        <Box mb={1}>
          <Typography variant="h6">
            {`${selectedUser?.first_name} ${selectedUser?.last_name}`}
          </Typography>
          <Typography variant="body">{selectedUser?.email}</Typography>
          <Typography variant="subtitle2" my={1}>
            {userDetails?.mfa ? (
              <span>Currently Selected MFA: {userDetails?.mfa}</span>
            ) : (
              <span>No MFA selected</span>
            )}
          </Typography>
        </Box>
        <form>
          <Box mb={1}>
            <FormField
              label="Set Preferred MFA"
              name="mfa"
              type="select"
              variant="standard"
              value={formData.mfa}
              onChange={onChange}
              options={mfaAvailable}
            />
          </Box>

          <Box
            className="items-center space-between"
            mb={1}
            sx={{
              "& button": {
                width: "48%",
              },
            }}
          >
            <LoadingButton
              loading={loading}
              onClick={onClickSave}
              variant="contained"
            >
              Save
            </LoadingButton>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
          </Box>
        </form>
      </Modal>
    </Box>
  );
};

export default SetMFAUserModal;

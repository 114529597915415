import { SET_STATUS_DETAILS_CACHED } from "actions/cached";

const initialState = {
  statusList: [],
};

export default function cached(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_STATUS_DETAILS_CACHED: {
      const statusList = state.statusList.filter((it) => it.id !== payload.id);
      statusList.push(payload);
      return {
        ...state,
        statusList,
      };
    }

    default:
      return state;
  }
}

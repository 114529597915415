import { API } from "aws-amplify";
import {
  constructStatusQueryString,
  getNotificationsFormdata,
} from "utils/actions";

export const GET_NOTIFICATIONS_LIST_REQUEST =
  "@@notifications/GET_NOTIFICATIONS_LIST_REQUEST";
export const GET_NOTIFICATIONS_LIST_SUCCESS =
  "@@notifications/GET_NOTIFICATIONS_LIST_SUCCESS";
export const GET_NOTIFICATIONS_LIST_FAILURE =
  "@@notifications/GET_NOTIFICATIONS_LIST_FAILURE";

export function getNotificationsList(formData = undefined, user) {
  return async (dispatch) => {
    dispatch({
      type: GET_NOTIFICATIONS_LIST_REQUEST,
    });
    try {
      let url = "/notification";
      if (formData) {
        const data = {
          ...formData,
          ...getNotificationsFormdata(formData, user?.username),
        };
        url = constructStatusQueryString(url, data);
      }

      const response = await API.get("cmsAPI", url);
      dispatch({
        type: GET_NOTIFICATIONS_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_NOTIFICATIONS_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_ACKNOWLEDGEBY_REQUEST =
  "@@notification/GET_ACKNOWLEDGEBY_REQUEST";
export const GET_ACKNOWLEDGEBY_SUCCESS =
  "@@notification/GET_ACKNOWLEDGEBY_SUCCESS";
export const GET_ACKNOWLEDGEBY_FAILURE =
  "@@notification/GET_ACKNOWLEDGEBY_FAILURE";

export function getAcknowledgeBy(id) {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: GET_ACKNOWLEDGEBY_REQUEST,
    });
    try {
      let url = "/user";
      url = `${url}/${id}`;
      const response = await API.get("cmsAPI", url);
      dispatch({
        type: GET_ACKNOWLEDGEBY_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_ACKNOWLEDGEBY_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_CLOSEDBY_REQUEST = "@@notification/GET_CLOSEDBY_REQUEST";
export const GET_CLOSEDBY_SUCCESS = "@@notification/GET_CLOSEDBY_SUCCESS";
export const GET_CLOSEDBY_FAILURE = "@@notification/GET_CLOSEDBY_FAILURE";

export function getClosedBy(id) {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: GET_CLOSEDBY_REQUEST,
    });
    try {
      let url = "/user";
      url = `${url}/${id}`;
      const response = await API.get("cmsAPI", url);
      dispatch({
        type: GET_CLOSEDBY_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_CLOSEDBY_FAILURE,
        payload: error,
      });
    }
  };
}

export const UPDATE_NOTIFICATION_REQUEST =
  "@@notification/UPDATE_NOTIFICATION_REQUEST";
export const UPDATE_NOTIFICATION_SUCCESS =
  "@@notification/UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_FAILURE =
  "@@notification/UPDATE_NOTIFICATION_FAILURE";

export function updateNotification(value) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_NOTIFICATION_REQUEST,
    });
    try {
      const init = {
        body: value,
      };

      let url = "/notification";
      const response = await API.put("cmsAPI", url, init);
      dispatch({
        type: UPDATE_NOTIFICATION_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_NOTIFICATION_FAILURE,
        payload: error,
      });
    }
  };
}

export const CLEAR_NOTIFICATIONS_LIST =
  "@@notification/CLEAR_NOTIFICATIONS_LIST";

export function clearNotificationsList() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_NOTIFICATIONS_LIST,
    });
  };
}

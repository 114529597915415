import { API } from "aws-amplify";

export const GET_UNIT_OPERATION_REQUEST =
  "@@operation/GET_UNIT_OPERATION_REQUEST";
export const GET_UNIT_OPERATION_SUCCESS =
  "@@operation/GET_UNIT_OPERATION_SUCCESS";
export const GET_UNIT_OPERATION_FAILURE =
  "@@operation/GET_UNIT_OPERATION_FAILURE";

export function getUnitOperation(id) {
  return async (dispatch) => {
    dispatch({
      type: GET_UNIT_OPERATION_REQUEST,
    });
    try {
      let url = `/operation?unit=${id}`;
      const response = await API.get("cmsAPI", url);
      dispatch({
        type: GET_UNIT_OPERATION_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_UNIT_OPERATION_FAILURE,
        payload: error,
      });
    }
  };
}

export const POST_OPERATION_REQUEST = "@@operation/POST_OPERATION_REQUEST";
export const POST_OPERATION_SUCCESS = "@@operation/POST_OPERATION_SUCCESS";
export const POST_OPERATION_FAILURE = "@@operation/POST_OPERATION_FAILURE";

export function createOperation(value) {
  return async (dispatch) => {
    dispatch({
      type: POST_OPERATION_REQUEST,
    });
    try {
      const init = {
        body: {
          ...value,
          start: undefined,
        },
      };

      let url = "/operation";
      const response = await API.post("cmsAPI", url, init);
      dispatch({
        type: POST_OPERATION_SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: POST_OPERATION_FAILURE,
        payload: error,
      });
    }
  };
}

import { Box, IconButton, TextField, Typography } from "@mui/material";
import { filterStyles } from "./styles";
import { Autorenew } from "@mui/icons-material";

const PageFilter = ({
  children,
  title,
  setTitle = undefined,
  placeholder = "",
  onClickRefresh = undefined,
  loading = false,
  icon = undefined,
  onClickIcon = undefined,
}) => {
  return (
    <Box sx={filterStyles}>
      <Box className="filter-title flex items-center">
        {!setTitle && <Typography variant="h3">{title}</Typography>}
        {setTitle && (
          <TextField
            fullWidth
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder={placeholder}
            size="small"
            InputLabelProps={{ shrink: true }}
          />
        )}
        {!setTitle && onClickRefresh && (
          <IconButton
            className={loading ? "rotateIcon" : ""}
            color="primary"
            onClick={onClickRefresh}
            sx={{ ml: 1 }}
          >
            <Autorenew />
          </IconButton>
        )}

        {!setTitle && icon && onClickIcon && (
          <IconButton color="primary" onClick={onClickIcon} sx={{ ml: 1 }}>
            {icon}
          </IconButton>
        )}
      </Box>
      {children}
    </Box>
  );
};

export default PageFilter;

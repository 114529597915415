import { Search } from "@mui/icons-material";
import {
  Box,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { getGroupsList } from "actions/groups";
import { getOrganisationsList } from "actions/organisation";
import { SITE_LIST_FITLERS } from "constants/site";
import { SITE_VIEW_OPTIONS } from "constants/unit";
import { FormField } from "empower-energy-owner-components";
import useDebounce from "hooks/useDebounce";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const styles = () => {
  return {
    "& .mui-field": {
      width: "180px",
      mr: 2,
      "& .MuiInputBase-root": {
        borderWidth: "1px",
      },
    },
    "& .view-list": {
      mt: "-16px",
    },
  };
};

const SiteFilter = ({ onChangeFilters, filters }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(SITE_LIST_FITLERS);

  const [stateOptions, setStateOptions] = useState([]);
  const [initialLoad, setInitialLoad] = useState(false);

  const profile = useSelector((state) => state.profile);
  const organisationList = useSelector((state) => state.organisation?.list);
  const groupList = useSelector((state) => state.groups?.list);
  const location = useSelector((state) => state.option?.location);
  const dnspOptions = useSelector((state) => state.option?.dnsp);

  const debouncedSearch = useDebounce(formData.search, 500);

  useEffect(() => {
    dispatch(getGroupsList());
  }, [dispatch]);

  useEffect(() => {
    if (profile?.is_staff) {
      dispatch(getOrganisationsList());
    }
  }, [profile]);

  useEffect(() => {
    setFormData(filters);
  }, [filters]);

  useEffect(() => {
    setStateOptions(
      location.map((it) => {
        return {
          value: it.state_name,
          label: it.state_name,
        };
      })
    );
  }, [location]);

  useEffect(() => {
    if (initialLoad) {
      onChangeFilters(formData);
    }
  }, [debouncedSearch]);

  const onChangeSearch = (e) => {
    setInitialLoad(true);
    const data = {
      ...SITE_LIST_FITLERS,
      search: e.target.value,
    };
    setFormData(data);
  };

  const onChange = (e) => {
    const data = { ...formData, [e.target.name]: e.target.value };
    setFormData(data);
    onChangeFilters(data);
  };

  const constructOptions = (list) => {
    const options = list.filter((it) => it.value !== "");
    options.unshift({
      value: "",
      label: "Select All",
    });
    return options;
  };

  return (
    <Box my={2} className="items-center row-wrap" sx={styles}>
      <Box className="items-center flex-grow">
        {profile?.is_staff && (
          <FormField
            label="Select Organisation"
            name="organisation"
            type="select"
            variant="outlined"
            value={formData.organisation}
            onChange={onChange}
            options={constructOptions(organisationList)}
          />
        )}

        <FormField
          label="Select Group"
          name="group"
          type="select"
          variant="outlined"
          value={formData.group}
          onChange={onChange}
          options={constructOptions(groupList)}
        />

        <FormField
          label="Select DNSP"
          name="dnsp"
          type="select"
          variant="outlined"
          value={formData.dnsp}
          onChange={onChange}
          options={constructOptions(dnspOptions)}
        />

        <FormField
          label="Select State"
          name="state"
          type="select"
          variant="outlined"
          value={formData.state}
          onChange={onChange}
          options={constructOptions(stateOptions)}
        />

        <Box className="view-list">
          <FormControl>
            <RadioGroup
              row
              name="view"
              value={filters?.view}
              onChange={onChange}
            >
              {SITE_VIEW_OPTIONS.map((option, i) => (
                <FormControlLabel
                  key={i}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
      <Box className="items-center">
        <TextField
          size="small"
          label="Search"
          name="search"
          variant="outlined"
          value={formData.search}
          onChange={onChangeSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default SiteFilter;

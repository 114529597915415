import { Box } from "@mui/material";
import {
  renderEditActions,
  renderStopped,
} from "components/DataTable/renderComponents";
import Severity from "components/Severity";
import SiteName from "components/Site/SiteName";
import UnitName from "components/Unit/UnitName";
import { DataTable } from "empower-energy-owner-components";
import { displayDurationBetweenDates, fromNow } from "utils/datetime";
import NotificationModal from "./NotificationModal";
import { useState } from "react";
import { isNil } from "lodash";

const NotificationList = ({
  loading,
  list,
  filters,
  onChangeFilters = undefined,
  totalCount,
}) => {
  const [selectedNotification, setSelectNotification] = useState(null);

  const columns = [
    {
      field: "unit",
      headerName: "Unit",
      width: 160,
      renderCell: (params) => <UnitName id={params.value} showState={true} />,
    },
    {
      field: "site",
      headerName: "Site",
      width: 160,
      renderCell: (params) => <SiteName id={params.value} showState={true} />,
    },
    {
      field: "event_parameter",
      headerName: "Event",
      width: 200,
    },
    {
      field: "severity",
      headerName: "Severity",
      width: 100,
      renderCell: (params) => <Severity severity={params.value} />,
    },
    {
      field: "timestamp",
      headerName: "Started",
      renderCell: (params) => fromNow(params.value),
    },
    {
      field: "stopped",
      headerName: "Stopped",
      renderCell: (params) => renderStopped(params.row),
    },
    {
      field: "counter",
      headerName: "Count",
      renderCell: (params) => params.value,
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 160,
      renderCell: (params) =>
        params.row.resolved_at
          ? displayDurationBetweenDates(
              params.row.timestamp,
              params.row.resolved_at
            )
          : "",
    },
    {
      field: "acknowledged_at",
      headerName: "Acknowledged",
      renderCell: (params) => fromNow(params.value),
    },
    {
      field: "acknowledged_by",
      headerName: "Ack. By",
      renderCell: (params) => params.value,
    },
    {
      field: "closed_at",
      headerName: "Closed",
      renderCell: (params) => fromNow(params.value),
    },
    {
      field: "id",
      width: "100",
      headerName: "Actions",
      align: "right",
      headerAlign: "right",
      renderCell: (params) => renderEditActions(params, onClickEdit),
    },
  ];

  const onClickEdit = (item) => {
    setSelectNotification(item);
  };

  const getMoreOptions = () => {
    if (filters && filters?.paginationModel && filters?.sortModel) {
      return {
        paginationMode: "server",
        paginationModel: filters?.paginationModel,
        setPaginationModel: (e) => onChangeFilters({ paginationModel: e }),
        sortingMode: "server",
        sortModel: filters?.sortModel,
        setSortModel: (e) => onChangeFilters({ sortModel: e }),
        totalCount: totalCount,
      };
    }
  };

  return (
    <Box>
      <DataTable
        rows={list}
        columns={columns}
        loading={loading}
        {...getMoreOptions()}
      />

      {!isNil(selectedNotification) && (
        <NotificationModal
          open={true}
          notification={selectedNotification}
          onClose={(refresh) => {
            setSelectNotification(null);
            if (refresh) {
              onChangeFilters(filters);
            }
          }}
        />
      )}
    </Box>
  );
};

export default NotificationList;

export const filterStyles = () => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: "16px",
    minHeight: "48px",
    "& .filter-title": {
      flexGrow: 1,
      mr: 4,
    },
    "& .filter-inputs": {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      "& .MuiTextField-root": {
        width: "300px",
      },
      "& .select-dropdown": {
        width: "160px",
        margin: "0 8px",
      },
    },
  };
};

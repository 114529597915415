import { API } from "aws-amplify";
import { SITE_VIEW } from "constants/unit";
import {
  constructOrganisationUrl,
  constructStatusQueryString,
} from "utils/actions";

export const GET_SITE_LIST_REQUEST = "@@site/GET_SITE_LIST_REQUEST";
export const GET_SITE_LIST_SUCCESS = "@@site/GET_SITE_LIST_SUCCESS";
export const GET_SITE_LIST_FAILURE = "@@site/GET_SITE_LIST_FAILURE";

export function getSiteList(formData = undefined) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_SITE_LIST_REQUEST,
    });
    try {
      const { profile } = getState();
      const { organisation } = profile;

      let url = "/site";
      if (formData) {
        url = constructStatusQueryString(url, formData);
      } else {
        constructOrganisationUrl(url, organisation);
      }

      const response = await API.get("cmsAPI", url);

      dispatch({
        type: GET_SITE_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_SITE_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_SITE_FILTER_LIST_REQUEST =
  "@@site/GET_SITE_FILTER_LIST_REQUEST";
export const GET_SITE_FILTER_LIST_SUCCESS =
  "@@site/GET_SITE_FILTER_LIST_SUCCESS";
export const GET_SITE_FILTER_LIST_FAILURE =
  "@@site/GET_SITE_FILTER_LIST_FAILURE";

export function getSiteFilterList(formData = undefined) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_SITE_FILTER_LIST_REQUEST,
    });
    try {
      const { profile } = getState();
      const { organisation } = profile;

      let url = "/site";
      if (formData) {
        url = constructStatusQueryString(url, {
          ...formData,
          limit: formData?.view === SITE_VIEW.MAP ? null : formData.limit,
          offset: formData?.view === SITE_VIEW.MAP ? null : formData.offset,
          view: null,
        });
      } else {
        constructOrganisationUrl(url, organisation);
      }

      let response = await API.get("cmsAPI", url);

      if (formData.view === SITE_VIEW.MAP) {
        response = {
          total_count: response.length,
          items: response,
        };
      }

      dispatch({
        type: GET_SITE_FILTER_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_SITE_FILTER_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_SITE_DETAILS_REQUEST = "@@site/GET_SITE_DETAILS_REQUEST";
export const GET_SITE_DETAILS_SUCCESS = "@@site/GET_SITE_DETAILS_SUCCESS";
export const GET_SITE_DETAILS_FAILURE = "@@site/GET_SITE_DETAILS_FAILURE";

export function getSiteDetails(id) {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: GET_SITE_DETAILS_REQUEST,
    });
    try {
      const response = await API.get("cmsAPI", `/site/${id}`);
      dispatch({
        type: GET_SITE_DETAILS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_SITE_DETAILS_FAILURE,
        payload: error,
      });
    }
  };
}

export const CLEAR_SITE_DETAILS = "@@site/CLEAR_SITE_DETAILS";

export function clearSiteDetails() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_SITE_DETAILS,
    });
  };
}

export const GET_SITE_EVENTS_LIST_REQUEST =
  "@@site/GET_SITE_EVENTS_LIST_REQUEST";
export const GET_SITE_EVENTS_LIST_SUCCESS =
  "@@site/GET_SITE_EVENTS_LIST_SUCCESS";
export const GET_SITE_EVENTS_LIST_FAILURE =
  "@@site/GET_SITE_EVENTS_LIST_FAILURE";

export function getSiteEventsList(site) {
  return async (dispatch) => {
    if (!site) return;
    dispatch({
      type: GET_SITE_EVENTS_LIST_REQUEST,
    });
    try {
      let url = `/event?site=${site}`;
      const response = await API.get("cmsAPI", url);
      dispatch({
        type: GET_SITE_EVENTS_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_SITE_EVENTS_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_SITE_OPTIMISATION_STRATEGY_REQUEST =
  "@@site/GET_SITE_OPTIMISATION_STRATEGY_REQUEST";
export const GET_SITE_OPTIMISATION_STRATEGY_SUCCESS =
  "@@site/GET_SITE_OPTIMISATION_STRATEGY_SUCCESS";
export const GET_SITE_OPTIMISATION_STRATEGY_FAILURE =
  "@@site/GET_SITE_OPTIMISATION_STRATEGY_FAILURE";

export function getSiteOptimisationStrategy(id) {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: GET_SITE_OPTIMISATION_STRATEGY_REQUEST,
    });
    try {
      const response = await API.get(
        "cmsAPI",
        `/site/${id}/optimisation_strategy`
      );
      dispatch({
        type: GET_SITE_OPTIMISATION_STRATEGY_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_SITE_OPTIMISATION_STRATEGY_FAILURE,
        payload: error,
      });
    }
  };
}

export const PUT_SITE_OPTIMISATION_STRATEGY_REQUEST =
  "@@site/PUT_SITE_OPTIMISATION_STRATEGY_REQUEST";
export const PUT_SITE_OPTIMISATION_STRATEGY_SUCCESS =
  "@@site/PUT_SITE_OPTIMISATION_STRATEGY_SUCCESS";
export const PUT_SITE_OPTIMISATION_STRATEGY_FAILURE =
  "@@site/PUT_SITE_OPTIMISATION_STRATEGY_FAILURE";

export function updateOptimisationStrategy(id, formData, callback) {
  return async (dispatch) => {
    if (!id) return;

    dispatch({
      type: PUT_SITE_OPTIMISATION_STRATEGY_REQUEST,
    });
    const init = {
      body: formData,
    };
    try {
      const response = await API.put(
        "cmsAPI",
        `/site/${id}/optimisation_strategy`,
        init
      );
      dispatch({
        type: PUT_SITE_OPTIMISATION_STRATEGY_SUCCESS,
        payload: response,
      });

      dispatch(getSiteOptimisationStrategy(id));
      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({
        type: PUT_SITE_OPTIMISATION_STRATEGY_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_RESTRICTED_DOCUMENT_LIST_REQUEST =
  "@@site/GET_RESTRICTED_DOCUMENT_LIST_REQUEST";
export const GET_RESTRICTED_DOCUMENT_LIST_SUCCESS =
  "@@site/GET_RESTRICTED_DOCUMENT_LIST_SUCCESS";
export const GET_RESTRICTED_DOCUMENT_LIST_FAILURE =
  "@@site/GET_RESTRICTED_DOCUMENT_LIST_FAILURE";

export function getOwnerRestrictedDocumentList(siteId) {
  return async (dispatch) => {
    dispatch({
      type: GET_RESTRICTED_DOCUMENT_LIST_REQUEST,
    });
    try {
      const response = await API.get(
        "cmsAPI",
        `/site/${siteId}/owner_restricted_document`
      );

      dispatch({
        type: GET_RESTRICTED_DOCUMENT_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_RESTRICTED_DOCUMENT_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const DELETE_RESTRICTED_DOCUMENT_REQUEST =
  "@@site/DELETE_RESTRICTED_DOCUMENT_REQUEST";
export const DELETE_RESTRICTED_DOCUMENT_SUCCESS =
  "@@site/DELETE_RESTRICTED_DOCUMENT_SUCCESS";
export const DELETE_RESTRICTED_DOCUMENT_FAILURE =
  "@@site/DELETE_RESTRICTED_DOCUMENT_FAILURE";

export function deleteOwnerRestrictedDocument(siteId, user, file_name) {
  return async (dispatch) => {
    dispatch({
      type: DELETE_RESTRICTED_DOCUMENT_REQUEST,
    });
    try {
      const response = await API.del(
        "cmsAPI",
        `/site/${siteId}/owner_restricted_document?user=${user}&file_name=${file_name}`
      );

      dispatch({
        type: DELETE_RESTRICTED_DOCUMENT_SUCCESS,
        payload: response,
      });
      dispatch(getOwnerRestrictedDocumentList(siteId));
    } catch (error) {
      dispatch({
        type: DELETE_RESTRICTED_DOCUMENT_FAILURE,
        payload: error,
      });
    }
  };
}

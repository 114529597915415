import { Box, Typography } from "@mui/material";
import { USER_TYPE } from "components/UserPage/utils";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const OrganisationName = ({ id, userType = USER_TYPE.FLEET, bold = false }) => {
  const [name, setName] = useState("");

  const list = useSelector((state) => state?.organisation?.list);
  const installerList = useSelector(
    (state) => state.organisation?.installerList
  );

  useEffect(() => {
    const orgList = userType === USER_TYPE.INSTALLER ? installerList : list;

    if (id && orgList.length > 0) {
      const item = orgList.find((it) => it.id === id);
      if (item) {
        setName(item.name || id);
      }
    }
  }, [id, list]);

  return (
    <Box className="items-center">
      <Typography
        variant="body1"
        fontSize={bold ? "16px" : "13px"}
        fontWeight={bold ? 500 : 400}
      >
        {name}
      </Typography>
    </Box>
  );
};

export default OrganisationName;

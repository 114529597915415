import { API } from "aws-amplify";

export const GET_NETWORK_ACCESS_LIST_REQUEST =
  "@@network/GET_NETWORK_ACCESS_LIST_REQUEST";
export const GET_NETWORK_ACCESS_LIST_SUCCESS =
  "@@network/GET_NETWORK_ACCESS_LIST_SUCCESS";
export const GET_NETWORK_ACCESS_LIST_FAILURE =
  "@@network/GET_NETWORK_ACCESS_LIST_FAILURE";

export function getNetworkAccessList(id) {
  if (!id) return;
  return async (dispatch) => {
    dispatch({
      type: GET_NETWORK_ACCESS_LIST_REQUEST,
    });
    try {
      const response = await API.get("cmsAPI", `/unit/${id}/aplist`);
      dispatch({
        type: GET_NETWORK_ACCESS_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_NETWORK_ACCESS_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_NETWORK_SETTINGS_REQUEST =
  "@@network/GET_NETWORK_SETTINGS_REQUEST";
export const GET_NETWORK_SETTINGS_SUCCESS =
  "@@network/GET_NETWORK_SETTINGS_SUCCESS";
export const GET_NETWORK_SETTINGS_FAILURE =
  "@@network/GET_NETWORK_SETTINGS_FAILURE";

export function getNetworkSettings(id) {
  if (!id) return;
  return async (dispatch) => {
    dispatch({
      type: GET_NETWORK_SETTINGS_REQUEST,
    });
    try {
      const response = await API.get("cmsAPI", `/unit/${id}/network`);
      dispatch({
        type: GET_NETWORK_SETTINGS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_NETWORK_SETTINGS_FAILURE,
        payload: error,
      });
    }
  };
}

export const PUT_NETWORK_SETTINGS_REQUEST =
  "@@network/PUT_NETWORK_SETTINGS_REQUEST";
export const PUT_NETWORK_SETTINGS_SUCCESS =
  "@@network/PUT_NETWORK_SETTINGS_SUCCESS";
export const PUT_NETWORK_SETTINGS_FAILURE =
  "@@network/PUT_NETWORK_SETTINGS_FAILURE";

export function updateNetworkSettings(id, data, callback) {
  if (!id) return;
  return async (dispatch) => {
    await dispatch(setCounter(1));
    dispatch({
      type: PUT_NETWORK_SETTINGS_REQUEST,
    });
    try {
      const init = {
        body: {
          settings: data,
        },
      };
      const response = await API.put("cmsAPI", `/unit/${id}/network`, init);
      dispatch({
        type: PUT_NETWORK_SETTINGS_SUCCESS,
        payload: response,
      });

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({
        type: PUT_NETWORK_SETTINGS_FAILURE,
        payload: error,
      });
    }
  };
}

export const CLEAR_PREVIOUS_NETWORK_SETTINGS =
  "@@network/CLEAR_PREVIOUS_NETWORK_SETTINGS";

export function clearPreviousNetworkSettings() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_PREVIOUS_NETWORK_SETTINGS,
    });
  };
}

export const SET_COUNTER = "@@network/SET_COUNTER";

export function setCounter(value) {
  return async (dispatch) => {
    dispatch({
      type: SET_COUNTER,
      payload: value,
    });
  };
}

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHECK_SESSION_REQUEST,
  CHECK_SESSION_SUCCESS,
  CHECK_SESSION_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  SESSION_REFRESH_REQUEST,
  SESSION_REFRESH_SUCCESS,
  SESSION_REFRESH_FAILURE,
  UPDATE_DETAILS_REQUEST,
  UPDATE_DETAILS_SUCCESS,
  UPDATE_DETAILS_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUBMIT_REQUEST,
  FORGOT_PASSWORD_SUBMIT_SUCCESS,
  FORGOT_PASSWORD_SUBMIT_FAILURE,
  RESET_FORGOT_PASSWORD_FLAG,
  CLEAR_SESSION_ERROR,
  SETUP_MFA_REQUEST,
  SETUP_MFA_SUCCESS,
  SETUP_MFA_FAILURE,
  VERIFY_MFA_REQUEST,
  VERIFY_MFA_SUCCESS,
  VERIFY_MFA_FAILURE,
  CONFIRM_SIGNIN_REQUEST,
  CONFIRM_SIGNIN_SUCCESS,
  CONFIRM_SIGNIN_FAILURE,
} from "actions/session";
import { toast } from "react-toastify";

const initialState = {
  loading: false,
  user: undefined,
  session: undefined,
  error: {},
  codeSent: "",
  mfaSetupCode: "",
  mfaSetupLoading: false,
  mfaVerifyLoading: false,
};

export default function session(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_REQUEST:
    case CHANGE_PASSWORD_REQUEST:
    case CHECK_SESSION_REQUEST:
    case LOGOUT_REQUEST:
    case SESSION_REFRESH_REQUEST:
    case UPDATE_DETAILS_REQUEST:
    case FORGOT_PASSWORD_REQUEST:
    case FORGOT_PASSWORD_SUBMIT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: {},
      };
    }

    case LOGIN_SUCCESS:
    case CHANGE_PASSWORD_SUCCESS:
    case CHECK_SESSION_SUCCESS:
    case UPDATE_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        user: payload,
        session: payload.signInUserSession,
      };
    }

    case SESSION_REFRESH_SUCCESS: {
      return {
        ...state,
        loading: false,
        session: payload,
      };
    }

    case FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        codeSent: "resetPassword",
      };
    }

    case FORGOT_PASSWORD_SUBMIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        codeSent: "",
      };
    }

    case RESET_FORGOT_PASSWORD_FLAG: {
      return {
        ...state,
        codeSent: "",
      };
    }

    case LOGOUT_SUCCESS:
    case LOGOUT_FAILURE: {
      return {
        ...state,
        ...initialState,
      };
    }

    case LOGIN_FAILURE:
    case CHANGE_PASSWORD_FAILURE:
    case CHECK_SESSION_FAILURE:
    case SESSION_REFRESH_FAILURE:
    case UPDATE_DETAILS_FAILURE:
    case FORGOT_PASSWORD_FAILURE:
    case FORGOT_PASSWORD_SUBMIT_FAILURE: {
      return {
        ...state,
        loading: false,
        session: undefined,
        error: {
          ...payload,
          message: payload?.message || "Something went wrong",
        },
      };
    }

    case CLEAR_SESSION_ERROR: {
      return {
        ...state,
        error: {},
      };
    }

    case SETUP_MFA_REQUEST: {
      return {
        ...state,
        mfaSetupLoading: true,
        mfaSetupCode: "",
      };
    }

    case SETUP_MFA_SUCCESS: {
      return {
        ...state,
        mfaSetupLoading: false,
        mfaSetupCode: payload,
      };
    }

    case SETUP_MFA_FAILURE: {
      toast.error(payload.message);
      return {
        ...state,
        mfaSetupLoading: false,
        mfaSetupCode: "",
      };
    }

    case VERIFY_MFA_REQUEST: {
      return {
        ...state,
        mfaVerifyLoading: true,
      };
    }

    case VERIFY_MFA_SUCCESS: {
      toast.success("MFA Setup done.");
      return {
        ...state,
        user: payload,
        session: payload.signInUserSession,
        mfaVerifyLoading: false,
      };
    }

    case VERIFY_MFA_FAILURE: {
      toast.error("Token is not verified");
      return {
        ...state,
        mfaVerifyLoading: false,
      };
    }

    case CONFIRM_SIGNIN_REQUEST: {
      return {
        ...state,
        mfaVerifyLoading: true,
      };
    }

    case CONFIRM_SIGNIN_SUCCESS: {
      return {
        ...state,
        mfaVerifyLoading: false,
      };
    }

    case CONFIRM_SIGNIN_FAILURE: {
      return {
        ...state,
        mfaVerifyLoading: false,
      };
    }

    default:
      return state;
  }
}

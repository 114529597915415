import { Box, Tooltip, Typography } from "@mui/material";
import {
  Cancel,
  CheckCircle,
  CloudDoneOutlined,
  CloudOff,
  SignalCellularOff,
  SignalWifiOff,
  Warning,
} from "@mui/icons-material";
import UnitStatusIcon from "./UnitStatusIcon";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { isNil } from "lodash";
import {
  EthernetIcon,
  NetworkSignal,
  WifiSignal,
} from "empower-energy-owner-components";

const styles = (theme) => {
  return {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
    "& .wizard-status-child": {
      marginRight: "10px",
      height: "32px",
    },
    "& .wizard-status-child-icon": {
      marginTop: "4px",
    },
  };
};

const STATUS_CHECK = {
  SUCCESS: "SUCCESS",
  WARNING: "WARNING",
  ERROR: "ERROR",
};

const UnitSiteStatus = ({ status }) => {
  const [statusCheck, setStatusCheck] = useState(STATUS_CHECK.ERROR);
  const [statusTooltip, setStatusTooltip] = useState("Online");

  useEffect(() => {
    if (status.running && status.online && !status.backup && status.connected) {
      setStatusCheck(STATUS_CHECK.SUCCESS);
    } else if (
      status.running &&
      status.online &&
      (status.backup || !status.connected)
    ) {
      setStatusCheck(STATUS_CHECK.WARNING);
    } else {
      setStatusCheck(STATUS_CHECK.ERROR);
    }
    
    var tooltips = []
    if (status.backup) {
      tooltips.push("Backup active")
    }
    if (!status.connected) {
      tooltips.push("Grid disconnected")
    }
    if (!status.running){
      tooltips.push("Faults active")
    }
    if (!status.online){
      tooltips.push("Network disconnected")
    }
    if (tooltips.length == 0) {
      setStatusTooltip("Online")
    } else {
      setStatusTooltip(tooltips.join(", "))
    }
  }, [status]);

  return (
    <Box sx={(theme) => styles(theme)}>
      <Tooltip title={statusTooltip} placement="top" arrow>
        {statusCheck === STATUS_CHECK.SUCCESS && (
          <CheckCircle
            className="wizard-status-child wizard-status-child-icon"
            sx={(theme) => ({
              color: theme.palette.success.main,
              fontSize: "20px",
            })}
          />
        )}

        {statusCheck === STATUS_CHECK.ERROR && (
          <Cancel
            className="wizard-status-child wizard-status-child-icon"
            sx={(theme) => ({
              color: theme.palette.error.main,
              fontSize: "20px",
            })}
          />
        )}

        {statusCheck === STATUS_CHECK.WARNING && (
          <Warning
            className="wizard-status-child wizard-status-child-icon"
            sx={(theme) => ({
              color: theme.palette.warning.main,
              fontSize: "20px",
            })}
          />
        )}
      </Tooltip>

      <Typography
        className="wizard-status-child"
        variant="subtitle1"
        alignItems="center"
        display="flex"
        color="primary"
      >
        <Link to={`/units/details/${status.id}`} className="text-link">
          {status?.serial}
        </Link>
      </Typography>

      {status?.online ? (
        <Tooltip title="Connected" placement="top" arrow>
          <CloudDoneOutlined className="wizard-status-child" />
        </Tooltip>
      ) : (
        <Tooltip title="Disconnected" placement="top" arrow>
          <CloudOff className="wizard-status-child" />
        </Tooltip>
      )}

      {isNil(status.wifi) && isNil(status.ethernet) && (
        <Tooltip title="Network not configured" placement="top" arrow>
          <SignalWifiOff className="wizard-status-child" />
        </Tooltip>
      )}

      {!isNil(status.wifi) && (
        <>
          {status?.wifi === 0 ? (
            <Tooltip title="Wi-Fi disconnected" placement="top" arrow>
              <SignalWifiOff className="wizard-status-child" />
            </Tooltip>
          ) : (
            <Tooltip title="Wi-Fi connected" placement="top" arrow>
              <WifiSignal signal={status?.wifi} />
            </Tooltip>
          )}
        </>
      )}

      {!isNil(status.ethernet) && (
        <>
          {status?.ethernet === 0 ? (
            <Tooltip title="Ethernet disconnected" placement="top" arrow>
              <EthernetIcon configured={false} />
            </Tooltip>
          ) : (
            <Tooltip title="Ethernet connected" placement="top" arrow>
              <EthernetIcon configured={true} />
            </Tooltip>
          )}
        </>
      )}

      {isNil(status?.modem) || status?.modem === 0 ? (
        <Tooltip title="Fallback network unavailable" placement="top" arrow>
          <SignalCellularOff className="wizard-status-child" />
        </Tooltip>
      ) : (
        <Tooltip title="Fallback network available" placement="top" arrow>
          <NetworkSignal signal={status?.modem} />
        </Tooltip>
      )}

      {status?.running_description && (
        <Box mr={2}>
          <UnitStatusIcon
            type="operation"
            value1={status?.running_description}
            value2={status?.running_sub_description}
            tooltips={["Running"]}
            width="unset"
          />
        </Box>
      )}
    </Box>
  );
};

export default UnitSiteStatus;

import {
  ADD_EDIT_DAYS_OF_WEEK,
  ADD_EDIT_PERIOD_TIME_RANGE,
  ADD_EDIT_SEASON_DATE_RANGE,
  ADD_EDIT_TOU_PERIOD,
  CLEAR_TARIFF,
  DELETE_PERIOD_TIME_RANGE,
  DELETE_SEASON_DATE_RANGE,
  DELETE_TOU_PERIOD,
  GET_SITE_TARIFF_LIST_FAILURE,
  GET_SITE_TARIFF_LIST_REQUEST,
  GET_SITE_TARIFF_LIST_SUCCESS,
  GET_UNIT_TARIFF_LIST_FAILURE,
  GET_UNIT_TARIFF_LIST_REQUEST,
  GET_UNIT_TARIFF_LIST_SUCCESS,
  POST_TARIFF_FAILURE,
  POST_TARIFF_REQUEST,
  POST_TARIFF_SUCCESS,
  RESET_TARIFF_DETAILS,
  SET_SELECTED_CONFIGURED_SEASON,
  UPDATE_TARIFF_FAILURE,
  UPDATE_TARIFF_REQUEST,
  UPDATE_TARIFF_SUCCESS,
} from "actions/tariff";
import {
  GET_TARIFF_DETAILS_FAILURE,
  GET_TARIFF_DETAILS_REQUEST,
  GET_TARIFF_DETAILS_SUCCESS,
} from "actions/tariff";
import { getTariffColorsByPrices } from "components/TariffChart/chartUtils";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { cloneDeep, orderBy } from "lodash";
import {
  convertSeasonToChart,
  deleteTariffPeriodTimeRange,
  deleteUpdteSeasonDateRange,
  getTariffSeasons,
  initialTariff,
  updatePeriodTimeRange,
  updateSeasonDateRange,
} from "utils/tariff";

dayjs.extend(advancedFormat);

const initialState = {
  loading: false,
  error: {},
  siteTariffList: [],
  unitTariffList: [],
  details: initialTariff,
  tariffPrices: [],
  tariffSeasons: [],
  tariffSeasonChart: [],
  tariffColors: [],
  selectedConfiguredSeason: null,
  isTariffUpdated: false,
};

const orderTariffSeasons = (tariffSeasons) =>
  orderBy(tariffSeasons, ["start_month"], "asc");

export default function tariff(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SITE_TARIFF_LIST_REQUEST:
    case GET_UNIT_TARIFF_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_SITE_TARIFF_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        siteTariffList: payload,
      };
    }

    case GET_UNIT_TARIFF_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        unitTariffList: payload,
      };
    }

    case GET_SITE_TARIFF_LIST_FAILURE:
    case GET_UNIT_TARIFF_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_TARIFF_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
        details: initialTariff,
      };
    }

    case GET_TARIFF_DETAILS_SUCCESS: {
      const { definition } = payload;
      const tariffPrices = definition.prices.map((it, i) => {
        return {
          ...it,
          id: i,
        };
      });

      const tariffSeasonChart = definition.seasons.map((it, i) => {
        return {
          ...it,
          id: i,
        };
      });

      const tariffSeasons = getTariffSeasons(tariffSeasonChart);

      return {
        ...state,
        loading: false,
        details: payload,
        tariffPrices,
        tariffSeasons: orderTariffSeasons(tariffSeasons),
        tariffSeasonChart,
        tariffColors: getTariffColorsByPrices(tariffPrices),
        isTariffUpdated: false,
      };
    }

    case GET_TARIFF_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case RESET_TARIFF_DETAILS: {
      return {
        ...state,
        tariffPrices: [],
        tariffSeasons: [],
        tariffSeasonChart: [],
        tariffColors: [],
        selectedConfiguredSeason: null,
        isTariffUpdated: false,
      };
    }

    case POST_TARIFF_REQUEST:
    case UPDATE_TARIFF_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_TARIFF_SUCCESS:
    case UPDATE_TARIFF_SUCCESS: {
      return {
        ...state,
        loading: false,
        isTariffUpdated: false,
      };
    }

    case POST_TARIFF_FAILURE:
    case UPDATE_TARIFF_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case CLEAR_TARIFF: {
      return {
        ...state,
        ...initialState,
      };
    }

    case ADD_EDIT_TOU_PERIOD: {
      const tariffPrices = cloneDeep(state.tariffPrices);
      if (payload?.id) {
        const index = tariffPrices.findIndex((it) => it.id === payload?.id);
        tariffPrices[index] = payload;
      } else {
        tariffPrices.push({
          ...payload,
          id: tariffPrices.length + 1,
        });
      }

      return {
        ...state,
        tariffPrices,
        tariffColors: getTariffColorsByPrices(tariffPrices),
        isTariffUpdated: true,
      };
    }

    case DELETE_TOU_PERIOD: {
      let tariffPrices = cloneDeep(state.tariffPrices);

      tariffPrices = tariffPrices.filter((it) => it.id !== payload.id);

      return {
        ...state,
        tariffPrices,
        tariffColors: getTariffColorsByPrices(tariffPrices),
        isTariffUpdated: true,
      };
    }

    case ADD_EDIT_SEASON_DATE_RANGE: {
      const tariffSeasons = updateSeasonDateRange(state.tariffSeasons, payload);

      const tariffSeasonChart = convertSeasonToChart(
        tariffSeasons,
        state.tariffSeasonChart
      );

      return {
        ...state,
        tariffSeasons: orderTariffSeasons(tariffSeasons),
        tariffSeasonChart: tariffSeasonChart,
        isTariffUpdated: true,
      };
    }

    case DELETE_SEASON_DATE_RANGE: {
      const tariffSeasons = deleteUpdteSeasonDateRange(
        state.tariffSeasons,
        payload
      );

      const tariffSeasonChart = convertSeasonToChart(
        tariffSeasons,
        state.tariffSeasonChart
      );

      return {
        ...state,
        tariffSeasons: orderTariffSeasons(tariffSeasons),
        tariffSeasonChart: tariffSeasonChart,
        isTariffUpdated: true,
      };
    }

    case ADD_EDIT_PERIOD_TIME_RANGE: {
      const { formData, pricingPeriod } = payload;

      const result = updatePeriodTimeRange(
        formData,
        state.tariffSeasonChart,
        state.selectedConfiguredSeason,
        pricingPeriod
      );

      return {
        ...state,
        tariffSeasonChart: result.tariffSeasonChart,
        selectedConfiguredSeason: result.selectedConfiguredSeason,
        isTariffUpdated: true,
      };
    }

    case DELETE_PERIOD_TIME_RANGE: {
      const { formData, pricingPeriod } = payload;

      const result = deleteTariffPeriodTimeRange(
        formData,
        state.tariffSeasonChart,
        state.selectedConfiguredSeason,
        pricingPeriod
      );

      return {
        ...state,
        tariffSeasonChart: result.tariffSeasonChart,
        selectedConfiguredSeason: result.selectedConfiguredSeason,
        isTariffUpdated: true,
      };
    }

    case ADD_EDIT_DAYS_OF_WEEK: {
      const selectedConfiguredSeason = cloneDeep(
        state.selectedConfiguredSeason
      );
      selectedConfiguredSeason.days_of_week = payload;

      const tariffSeasonChart = cloneDeep(state.tariffSeasonChart);

      const index = tariffSeasonChart.findIndex(
        (it) => it.name === selectedConfiguredSeason.name
      );
      tariffSeasonChart[index] = selectedConfiguredSeason;

      return {
        ...state,
        selectedConfiguredSeason,
        tariffSeasonChart,
        isTariffUpdated: true,
      };
    }

    case SET_SELECTED_CONFIGURED_SEASON: {
      return {
        ...state,
        selectedConfiguredSeason: payload,
      };
    }

    default:
      return state;
  }
}

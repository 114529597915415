import {
  GET_SITE_LIST_REQUEST,
  GET_SITE_LIST_SUCCESS,
  GET_SITE_LIST_FAILURE,
  GET_SITE_DETAILS_REQUEST,
  GET_SITE_DETAILS_SUCCESS,
  GET_SITE_DETAILS_FAILURE,
  GET_SITE_FILTER_LIST_REQUEST,
  GET_SITE_FILTER_LIST_FAILURE,
  GET_SITE_FILTER_LIST_SUCCESS,
  CLEAR_SITE_DETAILS,
  GET_SITE_EVENTS_LIST_REQUEST,
  GET_SITE_EVENTS_LIST_SUCCESS,
  GET_SITE_EVENTS_LIST_FAILURE,
  GET_SITE_OPTIMISATION_STRATEGY_REQUEST,
  GET_SITE_OPTIMISATION_STRATEGY_SUCCESS,
  GET_SITE_OPTIMISATION_STRATEGY_FAILURE,
  GET_RESTRICTED_DOCUMENT_LIST_REQUEST,
  GET_RESTRICTED_DOCUMENT_LIST_SUCCESS,
  GET_RESTRICTED_DOCUMENT_LIST_FAILURE,
  PUT_SITE_OPTIMISATION_STRATEGY_REQUEST,
  PUT_SITE_OPTIMISATION_STRATEGY_FAILURE,
  PUT_SITE_OPTIMISATION_STRATEGY_SUCCESS,
  DELETE_RESTRICTED_DOCUMENT_REQUEST,
  DELETE_RESTRICTED_DOCUMENT_SUCCESS,
  DELETE_RESTRICTED_DOCUMENT_FAILURE,
} from "actions/site";
import { orderBy } from "lodash";

const initialState = {
  loading: false,
  error: {},
  list: [],
  siteOptions: [],
  details: {},
  filteredList: [],
  events: [],
  eventsLoading: false,
  totalCount: 0,
  siteOptimisationStrategyLoading: false,
  siteOptimisationStrategy: {},
  restrictedDocumentList: [],
  restrictedDocumentListLoading: false,
};

export default function site(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SITE_LIST_REQUEST:
    case GET_SITE_DETAILS_REQUEST:
    case GET_SITE_FILTER_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_SITE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: payload,
        siteOptions: orderBy(payload, ["nmi"]).map((it) => {
          return {
            ...it,
            value: it.id,
            label: it.nmi + " " + it.units?.map((u) => u.serial).join(","),
          };
        }),
      };
    }

    case GET_SITE_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        details: payload,
      };
    }

    case GET_SITE_FILTER_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        filteredList: payload.items,
        totalCount: payload?.total_count,
      };
    }

    case GET_SITE_LIST_FAILURE:
    case GET_SITE_DETAILS_FAILURE:
    case GET_SITE_FILTER_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case CLEAR_SITE_DETAILS: {
      return {
        ...state,
        details: {},
      };
    }

    case GET_SITE_EVENTS_LIST_REQUEST: {
      return {
        ...state,
        eventsLoading: true,
      };
    }

    case GET_SITE_EVENTS_LIST_SUCCESS: {
      return {
        ...state,
        eventsLoading: false,
        events: orderBy(payload, ["timestamp"], "desc").map((it, i) => {
          return {
            ...it,
            id: i,
            stopped: it.timestamp,
          };
        }),
      };
    }

    case GET_SITE_EVENTS_LIST_FAILURE: {
      return {
        ...state,
        eventsLoading: false,
      };
    }

    case GET_SITE_OPTIMISATION_STRATEGY_REQUEST:
    case PUT_SITE_OPTIMISATION_STRATEGY_REQUEST: {
      return {
        ...state,
        siteOptimisationStrategyLoading: true,
      };
    }

    case GET_SITE_OPTIMISATION_STRATEGY_SUCCESS: {
      let siteOptimisationStrategy = {};
      if (payload.length > 0) {
        siteOptimisationStrategy = payload[0];
      }
      return {
        ...state,
        siteOptimisationStrategy,
        siteOptimisationStrategyLoading: false,
      };
    }

    case PUT_SITE_OPTIMISATION_STRATEGY_SUCCESS:
    case GET_SITE_OPTIMISATION_STRATEGY_FAILURE:
    case PUT_SITE_OPTIMISATION_STRATEGY_FAILURE: {
      return {
        ...state,
        siteOptimisationStrategyLoading: false,
      };
    }

    case GET_RESTRICTED_DOCUMENT_LIST_REQUEST: {
      return {
        ...state,
        restrictedDocumentListLoading: true,
        restrictedDocumentList: [],
      };
    }

    case GET_RESTRICTED_DOCUMENT_LIST_SUCCESS: {
      return {
        ...state,
        restrictedDocumentListLoading: false,
        restrictedDocumentList: payload.map((item, i) => {
          return {
            ...item,
            id: i,
          };
        }),
      };
    }

    case GET_RESTRICTED_DOCUMENT_LIST_FAILURE: {
      return {
        ...state,
        restrictedDocumentListLoading: false,
        restrictedDocumentList: [],
      };
    }

    case DELETE_RESTRICTED_DOCUMENT_REQUEST: {
      return {
        ...state,
        restrictedDocumentListLoading: true,
      };
    }

    case DELETE_RESTRICTED_DOCUMENT_SUCCESS:
    case DELETE_RESTRICTED_DOCUMENT_FAILURE: {
      return {
        ...state,
        restrictedDocumentListLoading: false,
      };
    }

    default:
      return state;
  }
}

import { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";

import BasicLayout from "layout/BasicLayout";
import AuthLayout from "layout/AuthLayout";

const Login = lazy(() =>
  import(/* webpackChunkName: "Login" */ "./pages/Session/Login")
);

const Logout = lazy(() =>
  import(/* webpackChunkName: "Logout" */ "./pages/Session/Logout")
);

const ResetPassword = lazy(() =>
  import(
    /* webpackChunkName: "ResetPassword" */ "./pages/Session/ResetPassword"
  )
);

const ChangePassword = lazy(() =>
  import(
    /* webpackChunkName: "ChangePassword" */ "./pages/Session/ChangePassword"
  )
);

const FirstLogin = lazy(() =>
  import(/* webpackChunkName: "FirstLogin" */ "./pages/Session/FirstLogin")
);

const Dashboard = lazy(() =>
  import(/* webpackChunkName: "Dashboard" */ "./pages/Dashboard")
);

const Profile = lazy(() =>
  import(/* webpackChunkName: "Profile" */ "./pages/Profile")
);

const Users = lazy(() =>
  import(/* webpackChunkName: "Users" */ "./pages/Users/Fleet")
);

const Installer = lazy(() =>
  import(/* webpackChunkName: "Installer" */ "./pages/Users/Installer")
);

const Owner = lazy(() =>
  import(/* webpackChunkName: "Owner" */ "./pages/Users/Owner")
);

const Groups = lazy(() =>
  import(/* webpackChunkName: "Groups" */ "./pages/Groups")
);

const Slots = lazy(() =>
  import(/* webpackChunkName: "Slots" */ "./pages/Slots")
);

const Scheduling = lazy(() =>
  import(/* webpackChunkName: "Scheduling" */ "./pages/Scheduling")
);

const AddEditSchedule = lazy(() =>
  import(
    /* webpackChunkName: "AddEditSchedule" */ "./pages/Scheduling/Schedules/AddEditSchedule"
  )
);

const Events = lazy(() =>
  import(/* webpackChunkName: "Events" */ "./pages/Events")
);

const Units = lazy(() =>
  import(/* webpackChunkName: "Units" */ "./pages/Units")
);

const UnitDetails = lazy(() =>
  import(/* webpackChunkName: "UnitDetails" */ "./pages/Units/UnitDetails")
);

const Sites = lazy(() =>
  import(/* webpackChunkName: "Sites" */ "./pages/Sites")
);

const SiteDetals = lazy(() =>
  import(/* webpackChunkName: "SiteDetals" */ "./pages/Sites/SiteDetails")
);

const Configuration = lazy(() =>
  import(/* webpackChunkName: "Configuration" */ "./pages/Configuration")
);

const PatchDetails = lazy(() =>
  import(
    /* webpackChunkName: "PatchDetails" */ "./components/Patches/PatchDetails"
  )
);

const Notifications = lazy(() =>
  import(/* webpackChunkName: "Notifications" */ "./pages/Notifications")
);

const PerformanceDetails = lazy(() =>
  import(
    /* webpackChunkName: "PerformanceDetails" */ "./pages/Dashboard/Performance/PerformanceDetails"
  )
);

const PerformanceUnitDetails = lazy(() =>
  import(
    /* webpackChunkName: "PerformanceUnitDetails" */ "./pages/Dashboard/Performance/PerformanceUnitDetails"
  )
);

const AccessCheck = lazy(() =>
  import(/* webpackChunkName: "AccessCheck" */ "./pages/Session/AccessCheck")
);

const UnitTariffs = lazy(() =>
  import(/* webpackChunkName: "UnitTariffs" */ "./pages/Units/UnitTariffs")
);

const AddEditUnitTariff = lazy(() =>
  import(
    /* webpackChunkName: "AddEditUnitTariff" */ "./pages/Units/AddEditUnitTariff"
  )
);

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <BasicLayout />,
      children: [
        { path: "", element: <Login /> },
        { path: "change-password", element: <ChangePassword /> },
        { path: "first-login", element: <FirstLogin /> },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "logout", element: <Logout /> },
      ],
    },
    {
      path: "/access-check",
      element: <AuthLayout />,
      children: [{ path: "", element: <AccessCheck pageTitle="Welcome" /> }],
    },
    {
      path: "/dashboard",
      element: <AuthLayout />,
      children: [
        {
          path: "",
          element: <Dashboard pageTitle="Dashboard" accessKey="dashboard" />,
        },
      ],
    },
    {
      path: "/performance",
      element: <AuthLayout />,
      children: [
        { path: ":id", element: <PerformanceDetails accessKey="dashboard" /> },
        {
          path: ":id/:unitId",
          element: <PerformanceUnitDetails accessKey="dashboard" />,
        },
      ],
    },
    {
      path: "/profile",
      element: <AuthLayout />,
      children: [
        { path: "", element: <Profile pageTitle="Profile Management" /> },
      ],
    },
    {
      path: "/users",
      element: <AuthLayout />,
      children: [
        { path: "", element: <Navigate to="/fleet" replace /> },
        {
          path: "fleet",
          element: <Users pageTitle="Fleet Users" accessKey="users_fleet" />,
        },
        {
          path: "installer",
          element: (
            <Installer
              pageTitle="Installer Users"
              accessKey="users_installer"
            />
          ),
        },
        {
          path: "owner",
          element: <Owner pageTitle="Owner Users" accessKey="users_owner" />,
        },
      ],
    },
    {
      path: "/groups",
      element: <AuthLayout />,
      children: [
        { path: "", element: <Groups pageTitle="Groups" accessKey="groups" /> },
      ],
    },
    {
      path: "/slots",
      element: <AuthLayout />,
      children: [
        { path: "", element: <Slots pageTitle="Slots" accessKey="slots" /> },
      ],
    },
    {
      path: "/scheduling",
      element: <AuthLayout />,
      children: [
        {
          path: "",
          element: <Scheduling pageTitle="Scheduling" accessKey="scheduling" />,
        },
        {
          path: "schedules/:id",
          element: (
            <AddEditSchedule
              pageTitle="Update Schedule"
              accessKey="scheduling"
            />
          ),
        },
        {
          path: "schedules",
          element: (
            <AddEditSchedule pageTitle="Add Schedule" accessKey="scheduling" />
          ),
        },
      ],
    },
    {
      path: "/events",
      element: <AuthLayout />,
      children: [
        { path: "", element: <Events pageTitle="Events" accessKey="events" /> },
      ],
    },
    {
      path: "/units",
      element: <AuthLayout />,
      children: [
        { path: "", element: <Units pageTitle="Units" accessKey="units" /> },
        {
          path: "details/:id",
          element: <UnitDetails pageTitle="Unit Details" accessKey="units" />,
        },
        {
          path: "details/:id/tariffs",
          children: [
            {
              path: "",
              element: (
                <UnitTariffs pageTitle="Available Tariffs" accessKey="units" />
              ),
            },
            {
              path: ":tariffId",
              element: (
                <AddEditUnitTariff pageTitle="Edit Tariff" accessKey="units" />
              ),
              exact: true,
            },
            {
              path: ":tariffId/:action",
              element: (
                <AddEditUnitTariff
                  pageTitle="Create Tariff"
                  accessKey="units"
                />
              ),
              exact: true,
            },
            {
              path: "add",
              element: (
                <AddEditUnitTariff
                  pageTitle="Create Tariff"
                  accessKey="units"
                />
              ),
            },
          ],
        },
      ],
    },
    {
      path: "/sites",
      element: <AuthLayout />,
      children: [
        { path: "", element: <Sites pageTitle="Sites" accessKey="sites" /> },
        {
          path: "details/:id",
          element: <SiteDetals pageTitle="Site Details" accessKey="sites" />,
        },
      ],
    },
    {
      path: "/configuration",
      element: <AuthLayout />,
      children: [
        {
          path: "",
          element: (
            <Configuration
              pageTitle="Configuration"
              accessKey="configuration"
            />
          ),
        },
        {
          path: "patch/:model/:patch",
          element: <PatchDetails />,
        },
      ],
    },
    {
      path: "/notifications",
      element: <AuthLayout />,
      children: [
        {
          path: "",
          element: (
            <Notifications
              pageTitle="Notifications"
              accessKey="notifications"
            />
          ),
        },
      ],
    },
    { path: "*", element: <Navigate to="/" replace /> },
  ]);
}

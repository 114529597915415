import { Box, Button, InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteConfirmationDialog, PageFilter } from "components";
import { Add, Search } from "@mui/icons-material";
import { renderActions } from "components/DataTable/renderComponents";
import { isNil } from "lodash";
import { deleteOrganisation, getOrganisationsList } from "actions/organisation";
import AddEditOrganisationModal from "./AddEditOrganisationModal";
import { USER_TYPE } from "./utils";
import { DataTable } from "empower-energy-owner-components";

const styles = () => {
  return {
    "& .search-text": {
      width: "200px",
      mr: 2,
    },
  };
};

const Organisations = ({ userType = USER_TYPE.FLEET }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    search: "",
  });

  const [selectedOrganisation, setSelectedOrganisation] = useState(null);
  const [selectedDeleteOrganisation, setSelectedDeletOrganisation] =
    useState(null);

  const list = useSelector((state) => state.organisation?.list);
  const loading = useSelector((state) => state.organisation?.loading);
  const installerList = useSelector(
    (state) => state.organisation?.installerList
  );

  const columns = [
    {
      field: "name",
      headerName: "Organisation Name",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "id",
      width: 200,
      headerAlign: "right",
      align: "right",
      headerName: "Action",
      renderCell: (params) =>
        renderActions(params, onClickEdit, openDeleteModal),
    },
  ];

  useEffect(() => {
    dispatch(getOrganisationsList(userType, formData));
  }, [dispatch, formData]);

  const onChange = (e) => {
    const data = { ...formData, [e.target.name]: e.target.value };
    setFormData(data);
  };

  const onClickEdit = (item) => {
    setSelectedOrganisation(item);
  };

  const openDeleteModal = (item) => {
    setSelectedDeletOrganisation(item);
  };

  const onClickAdd = () => {
    setSelectedOrganisation("");
  };

  const onClickDelete = () => {
    dispatch(deleteOrganisation(selectedDeleteOrganisation.id, userType));
    setSelectedDeletOrganisation(null);
  };

  return (
    <Box sx={styles}>
      <PageFilter title={`${userType} Organisations`}></PageFilter>

      <Box my={2} className="items-center">
        <Box className="flex-grow"></Box>
        <Box className="items-center">
          <TextField
            className="search-text"
            size="small"
            label="Search"
            name="search"
            variant="outlined"
            value={formData.search}
            onChange={onChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <Button
            onClick={onClickAdd}
            color="primary"
            variant="contained"
            startIcon={<Add />}
          >
            Add New Organisation
          </Button>
        </Box>
      </Box>

      <DataTable
        rows={userType === USER_TYPE.INSTALLER ? installerList : list}
        columns={columns}
        loading={loading}
      />

      {!isNil(selectedOrganisation) && (
        <AddEditOrganisationModal
          selectedOrganisation={selectedOrganisation}
          open={true}
          onClose={() => setSelectedOrganisation(null)}
          userType={userType}
        />
      )}

      {!isNil(selectedDeleteOrganisation) && (
        <DeleteConfirmationDialog
          open={true}
          onClose={() => setSelectedDeletOrganisation(null)}
          onClickDelete={onClickDelete}
          data="organisation"
        />
      )}
    </Box>
  );
};

export default Organisations;

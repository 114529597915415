import { Edit, InfoOutlined } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  capitalize,
} from "@mui/material";
import NameModal from "components/Name/NameModal";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const UnitName = ({
  id,
  showState = false,
  editable = false,
  fontWeight = 400,
}) => {
  const [unit, setUnit] = useState({});
  const [name, setName] = useState("");

  const [openNameModal, setOpenNameModal] = useState(false);

  const list = useSelector((state) => state.unit?.list);
  const aliases = useSelector((state) => state?.profile?.aliases);

  useEffect(() => {
    if (id && list.length > 0) {
      const item = list.find((it) => it.id === id);

      if (item) {
        let aliasName = item?.serial;
        const aliasUnit = aliases.find((it) => it.item === item.id);
        if (aliasUnit) {
          aliasName = aliasUnit?.name;
        }

        setUnit(item);
        setName(aliasName);
      } else {
        setUnit({});
        setName("");
      }
    } else {
      setUnit({});
      setName("");
    }
  }, [id, list, aliases]);

  return (
    <Box className="items-center">
      <Tooltip title="Click to Navigate" placement="top" arrow>
        <Link to={`/units/details/${id}`} className="text-link">
          <Typography
            variant="body2"
            fontSize={editable ? "16px" : "13px"}
            fontWeight={editable ? 500 : fontWeight}
          >
            {name}
          </Typography>
        </Link>
      </Tooltip>

      {showState && ["development", "virtual"].includes(unit?.state) && (
        <Tooltip title={capitalize(unit.state)} placement="top" arrow>
          <InfoOutlined sx={{ ml: 1, fontSize: "20px", color: "blue" }} />
        </Tooltip>
      )}
      {editable && (
        <Tooltip title="Click to Edit" placement="top" arrow>
          <IconButton
            sx={{ ml: 1 }}
            color="primary"
            onClick={() => setOpenNameModal(true)}
          >
            <Edit fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      {openNameModal && (
        <NameModal
          open={openNameModal}
          onClose={() => setOpenNameModal(false)}
          id={id}
          type="Unit"
          name={name}
        />
      )}
    </Box>
  );
};

export default UnitName;

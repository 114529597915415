import { API } from "aws-amplify";
import { constructOrganisationUrl } from "utils/actions";

export const GET_EVENTS_LIST_REQUEST = "@@event/GET_EVENTS_LIST_REQUEST";
export const GET_EVENTS_LIST_SUCCESS = "@@event/GET_EVENTS_LIST_SUCCESS";
export const GET_EVENTS_LIST_FAILURE = "@@event/GET_EVENTS_LIST_FAILURE";

export function getEventsList() {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_EVENTS_LIST_REQUEST,
    });
    try {
      const { profile } = getState();
      const { organisation } = profile;
      let url = "/event";
      const response = await API.get(
        "cmsAPI",
        constructOrganisationUrl(url, organisation)
      );
      dispatch({
        type: GET_EVENTS_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_EVENTS_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const tabStyles = () => {
  return {
    padding: "0",
    display: "flex",
    alignItems: "center",
    borderBottom: "2px solid #E0E5F0",
    mb: 2,
    "& .MuiTabs-root": {
      minHeight: "32px",
    },
    "& .MuiTab-root": {
      padding: "12px 4px",
      minHeight: "32px",
      margin: "0 12px",
      "&:first-of-type": {
        marginLeft: "0",
      },
    },
  };
};

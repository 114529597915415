import { Box, Button, InputAdornment, TextField } from "@mui/material";
import { useEffect, useState, Fragment } from "react";
import { USER_TYPE } from "./utils";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, getUserList, userAction } from "actions/user";
import {
  ConfirmDialog,
  DeleteConfirmationDialog,
  OrganisationName,
  PageFilter,
} from "components";
import { Add, Search } from "@mui/icons-material";
import {
  renderEnabled,
  renderName,
} from "components/DataTable/renderComponents";
import AddEditUserModal from "./AddEditUserModal";
import { isNil } from "lodash";
import { DataTable, FormField } from "empower-energy-owner-components";
import AssignSiteUserModal from "./AssignSiteUserModal";
import SetMFAUserModal from "./SetMFAUserModal";

const styles = () => {
  return {
    "& .mui-field": {
      width: "200px",
      mr: 2,
      mb: 0,
      "& .MuiInputBase-root": {
        borderWidth: "1px",
      },
    },
    "& .search-text": {
      width: "200px",
      mr: 2,
    },
    "& .button-link": {
      p: 0,
      minWidth: 0,
      px: "2px",
    },
    "& .bar": {
      height: "16px",
      mx: "3px",
      borderRight: "1px solid #004057",
    },
  };
};

const Users = ({ userType = USER_TYPE.FLEET }) => {
  const dispatch = useDispatch();

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedDeleteUser, setSelectedDeletUser] = useState(null);
  const [assignUser, setSelectAssignUser] = useState(null);
  const [enableUser, setEnableUser] = useState(null);
  const [disableUser, setDisableUser] = useState(null);
  const [resendUser, setResendUser] = useState(null);
  const [mfaUser, setMfaUser] = useState(null);

  const [formData, setFormData] = useState({
    organisationId: "",
    search: "",
  });

  const list = useSelector((state) => state.user?.list);
  const loading = useSelector((state) => state.user?.loading);

  const organisationList = useSelector((state) => state.organisation?.list);
  const installerList = useSelector(
    (state) => state.organisation?.installerList
  );

  const columns = [
    {
      field: "email",
      headerName: "Email",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "first_name",
      headerName: "Name",
      width: 160,
      renderCell: renderName,
    },
    {
      field: "phone_number",
      headerName: "Phone No",
      width: 100,
    },
    {
      field: "access_type_label",
      headerName: "Access Type",
      width: 100,
    },
    {
      field: "organisation",
      headerName: "Organisation",
      width: 140,
      renderCell: (params) => (
        <OrganisationName id={params.value} userType={userType} />
      ),
    },
    {
      field: "enabled",
      headerName: "Enabled",
      width: 80,
      renderCell: (params) => renderEnabled(params.value),
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
    },
    {
      field: "id",
      width: 260,
      headerAlign: "right",
      align: "right",
      newColumn: true,
      headerName: "Action",
      renderCell: (params) => renderUserActions(params.row),
    },
  ];

  const ownerColumns = [
    {
      field: "email",
      headerName: "Email",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "first_name",
      headerName: "Name",
      width: 180,
      renderCell: renderName,
    },
    {
      field: "access_type_label",
      headerName: "Access Type",
      width: 100,
    },
    {
      field: "enabled",
      headerName: "Enabled",
      width: 80,
      renderCell: (params) => renderEnabled(params.value),
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
    },
    {
      field: "id",
      width: 340,
      headerAlign: "right",
      align: "right",
      newColumn: true,
      headerName: "Action",
      renderCell: (params) => renderUserActions(params.row),
    },
  ];

  useEffect(() => {
    dispatch(getUserList(userType, formData));
  }, [dispatch, formData]);

  const onClickAdd = () => {
    setSelectedUser("");
  };

  const onClickDelete = () => {
    dispatch(deleteUser(selectedDeleteUser.id, userType));
    setSelectedDeletUser(null);
  };

  const onClickEnable = async () => {
    await dispatch(
      userAction(
        enableUser.id,
        {
          enabled: true,
        },
        userType,
        "enable"
      )
    );
    setEnableUser(null);
  };

  const onClickDisable = async () => {
    await dispatch(
      userAction(
        disableUser.id,
        {
          enabled: false,
        },
        userType,
        "enable"
      )
    );
    setDisableUser(null);
  };

  const onClickResendInvite = async () => {
    await dispatch(
      userAction(
        resendUser.id,
        {
          resend: true,
        },
        userType,
        "invite"
      )
    );
    setResendUser(null);
  };

  const onChange = (e) => {
    const data = { ...formData, [e.target.name]: e.target.value };
    setFormData(data);
  };

  const constructOptions = (list) => {
    const options = list.filter((it) => it.value !== "");
    options.unshift({
      value: "",
      label: "Select All",
    });
    return options;
  };

  const renderUserActions = (item) => {
    const buttons = [
      {
        label: "Assign Sites",
        value: "assignSites",
        onClick: setSelectAssignUser,
      },
      { label: "Enable", value: "enable", onClick: setEnableUser },
      { label: "Disable", value: "disable", onClick: setDisableUser },
      { label: "MFA", value: "mfa", onClick: setMfaUser },
      { label: "Resend Invite", value: "resendInvite", onClick: setResendUser },
      { label: "Edit", value: "edit", onClick: setSelectedUser },
      { label: "Delete", value: "delete", onClick: setSelectedDeletUser },
    ];
    return (
      <Box className="items-center">
        {buttons.map((button, i) => (
          <Fragment key={`${item.id}${i}`}>
            {(button.value === "enable" && item.enabled) ||
            (button.value === "disable" && !item.enabled) ||
            (button.value === "resendInvite" &&
              item.status !== "FORCE_CHANGE_PASSWORD") ||
            (button.value === "mfa" && userType !== USER_TYPE.FLEET) ||
            (button.value === "assignSites" && userType !== USER_TYPE.OWNER) ? (
              <div></div>
            ) : (
              <>
                <Button
                  className="button-link"
                  onClick={() => button.onClick(item)}
                >
                  {button.label}
                </Button>
                {buttons.length !== i + 1 && <Box className="bar"></Box>}
              </>
            )}
          </Fragment>
        ))}
      </Box>
    );
  };

  return (
    <Box sx={styles}>
      <PageFilter title={`${userType} Users`}></PageFilter>

      <Box my={2} className="items-center">
        <Box className="flex-grow">
          {userType !== USER_TYPE.OWNER && (
            <FormField
              label="Select Organisation"
              name="organisationId"
              type="select"
              variant="outlined"
              value={formData.organisationId}
              onChange={onChange}
              options={constructOptions(
                userType === USER_TYPE.INSTALLER
                  ? installerList
                  : organisationList
              )}
            />
          )}
        </Box>
        <Box className="items-center">
          <TextField
            className="search-text"
            size="small"
            label="Search"
            name="search"
            variant="outlined"
            value={formData.search}
            onChange={onChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <Button
            onClick={onClickAdd}
            color="primary"
            variant="contained"
            startIcon={<Add />}
          >
            Add New User
          </Button>
        </Box>
      </Box>

      <DataTable
        rows={list}
        columns={userType !== USER_TYPE.OWNER ? columns : ownerColumns}
        loading={loading}
      />

      {!isNil(selectedUser) && (
        <AddEditUserModal
          selectedUser={selectedUser}
          open={true}
          onClose={() => setSelectedUser(null)}
          userType={userType}
        />
      )}

      {!isNil(mfaUser) && (
        <SetMFAUserModal
          selectedUser={mfaUser}
          open={true}
          onClose={() => setMfaUser(null)}
          userType={userType}
        />
      )}

      {!isNil(selectedDeleteUser) && (
        <DeleteConfirmationDialog
          open={true}
          onClose={() => setSelectedDeletUser(null)}
          onClickDelete={onClickDelete}
          data={`${userType} user`}
        />
      )}

      {!isNil(assignUser) && (
        <AssignSiteUserModal
          open={true}
          onClose={() => setSelectAssignUser(null)}
          selectedUser={assignUser}
        />
      )}

      {!isNil(enableUser) && (
        <ConfirmDialog
          title={`Enable ${userType} User`}
          message={`Account ${enableUser?.email} will be enabled.`}
          open={true}
          onClose={() => setEnableUser(null)}
          onClickConfirm={onClickEnable}
        />
      )}

      {!isNil(disableUser) && (
        <ConfirmDialog
          title={`Disable ${userType} User`}
          message={`Account ${disableUser?.email} will be disabled.`}
          open={true}
          onClose={() => setDisableUser(null)}
          onClickConfirm={onClickDisable}
        />
      )}

      {!isNil(resendUser) && (
        <ConfirmDialog
          title={`Resend Invitation for ${userType} User`}
          message={`Resend Invitation to ${resendUser?.email}`}
          open={true}
          onClose={() => setResendUser(null)}
          onClickConfirm={onClickResendInvite}
        />
      )}
    </Box>
  );
};

export default Users;

import { Box, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { USER_OPTIONS, USER_OPTIONS_TABS, USER_TYPE } from "./utils";
import Users from "./Users";
import Organisations from "./Organisations";
import { tabStyles } from "components/styles";
import { getOrganisationsList } from "actions/organisation";
import { useDispatch } from "react-redux";

const UserPage = ({ userType = USER_TYPE.FLEET, tabAccess = [] }) => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(USER_OPTIONS.USERS);

  useEffect(() => {
    dispatch(getOrganisationsList(userType));
  }, [dispatch]);

  const onChangeTab = (e, value) => {
    setActiveTab(value);
  };

  return (
    <Box>
      <Box sx={tabStyles}>
        <Tabs value={activeTab} onChange={onChangeTab}>
          {USER_OPTIONS_TABS &&
            USER_OPTIONS_TABS.map((item, i) => {
              if (item.restricts?.includes(userType)) return;
              if (!tabAccess.includes(item.accessKey)) return;
              return <Tab key={i} value={item.value} label={item.label} />;
            })}
        </Tabs>
      </Box>

      {activeTab === USER_OPTIONS.USERS && <Users userType={userType} />}

      {activeTab === USER_OPTIONS.ORGANISATION && (
        <Organisations userType={userType} />
      )}
    </Box>
  );
};

export default UserPage;

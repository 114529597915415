export const USER_TYPE = {
  FLEET: "Fleet",
  OWNER: "Owner",
  INSTALLER: "Installer",
};

export const USER_OPTIONS = {
  USERS: "Users",
  ORGANISATION: "Organisations",
};

export const USER_OPTIONS_TABS = [
  { label: USER_OPTIONS.USERS, value: USER_OPTIONS.USERS, accessKey: "users" },
  {
    label: USER_OPTIONS.ORGANISATION,
    value: USER_OPTIONS.ORGANISATION,
    restricts: [USER_TYPE.OWNER],
    accessKey: "organisations",
  },
];

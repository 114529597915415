import { API } from "aws-amplify";
import {
  constructOrganisationUrl,
  constructStatusQueryString,
} from "utils/actions";

export const GET_NOTES_LIST_REQUEST = "@@notes/GET_NOTES_LIST_REQUEST";
export const GET_NOTES_LIST_SUCCESS = "@@notes/GET_NOTES_LIST_SUCCESS";
export const GET_NOTES_LIST_FAILURE = "@@notes/GET_NOTES_LIST_FAILURE";

export function getNotesList() {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_NOTES_LIST_REQUEST,
    });
    try {
      const { profile } = getState();
      const { organisation } = profile;
      let url = "/note";
      const response = await API.get(
        "cmsAPI",
        constructOrganisationUrl(url, organisation)
      );
      dispatch({
        type: GET_NOTES_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_NOTES_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const POST_NOTES_REQUEST = "@@notes/POST_NOTES_REQUEST";
export const POST_NOTES_SUCCESS = "@@notes/POST_NOTES_SUCCESS";
export const POST_NOTES_FAILURE = "@@notes/POST_NOTES_FAILURE";

export function createNotes(value, site, formData, type = "unit") {
  return async (dispatch) => {
    dispatch({
      type: POST_NOTES_REQUEST,
    });
    try {
      const init = {
        body: {
          ...value,
        },
      };

      let url = "/note";
      const response = await API.post("cmsAPI", url, init);
      dispatch({
        type: POST_NOTES_SUCCESS,
        payload: response,
      });

      if (type === "unit") {
        if (value.unit && site) {
          dispatch(getUnitNotesList(value.unit, site, formData));
        }
      } else if (type === "site") {
        if (value.unit && site) {
          dispatch(getSiteNotesList(site, formData));
        }
      }
    } catch (error) {
      dispatch({
        type: POST_NOTES_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_UNIT_NOTES_LIST_REQUEST =
  "@@notes/GET_UNIT_NOTES_LIST_REQUEST";
export const GET_UNIT_NOTES_LIST_SUCCESS =
  "@@notes/GET_UNIT_NOTES_LIST_SUCCESS";
export const GET_UNIT_NOTES_LIST_FAILURE =
  "@@notes/GET_UNIT_NOTES_LIST_FAILURE";

export function getUnitNotesList(unit, site, formData = undefined) {
  return async (dispatch) => {
    if (!(unit && site)) return;
    dispatch({
      type: GET_UNIT_NOTES_LIST_REQUEST,
    });
    try {
      let url = `/note?unit=${unit}&site=${site}`;
      if (formData) {
        url = constructStatusQueryString(url, formData);
      }
      const response = await API.get("cmsAPI", url);
      dispatch({
        type: GET_UNIT_NOTES_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_UNIT_NOTES_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_SITE_NOTES_LIST_REQUEST =
  "@@notes/GET_SITE_NOTES_LIST_REQUEST";
export const GET_SITE_NOTES_LIST_SUCCESS =
  "@@notes/GET_SITE_NOTES_LIST_SUCCESS";
export const GET_SITE_NOTES_LIST_FAILURE =
  "@@notes/GET_SITE_NOTES_LIST_FAILURE";

export function getSiteNotesList(site, formData = undefined) {
  return async (dispatch) => {
    if (!site) return;
    dispatch({
      type: GET_SITE_NOTES_LIST_REQUEST,
    });
    try {
      let url = `/note?site=${site}`;
      if (formData) {
        url = constructStatusQueryString(url, formData);
      }
      const response = await API.get("cmsAPI", url);
      dispatch({
        type: GET_SITE_NOTES_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_SITE_NOTES_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const CLEAR_NOTES = "@@notes/CLEAR_NOTES";

export function clearNotes() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_NOTES,
    });
  };
}

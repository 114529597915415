import { API } from "aws-amplify";
import { setAliases } from "./profile";

export const POST_NAME_REQUEST = "@@name/POST_NAME_REQUEST";
export const POST_NAME_SUCCESS = "@@name/POST_NAME_SUCCESS";
export const POST_NAME_FAILURE = "@@name/POST_NAME_FAILURE";

export function createName(value) {
  return async (dispatch) => {
    dispatch({
      type: POST_NAME_REQUEST,
    });
    try {
      const init = {
        body: value,
      };

      let url = "/name";
      const response = await API.post("cmsAPI", url, init);
      dispatch({
        type: POST_NAME_SUCCESS,
        payload: response,
      });

      dispatch(setAliases(response));
    } catch (error) {
      dispatch({
        type: POST_NAME_FAILURE,
        payload: error,
      });
    }
  };
}

import { LoadingButton } from "@mui/lab";
import { Box, Button, TextField, Typography } from "@mui/material";
import { createNotes } from "actions/notes";
import {
  updateNotification,
  getAcknowledgeBy,
  getClosedBy,
} from "actions/notifications";
import { getUnitDetails } from "actions/unit";
import { Modal } from "components";
import { renderDuration, renderStopped } from "components/DataTable/renderComponents";
import { isEmpty, isNil } from "lodash";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fromNow, getFullDateTime } from "utils/datetime";

const styles = () => {
  return {
    "& .contained-button": {
      marginRight: "8px",
    },
    "& .acknowledgeBy": {
      marginLeft: "2px",
    },
  };
};

const NotificationModal = ({ open, onClose, notification }) => {
  const dispatch = useDispatch();

  const [message, setMessage] = useState("");

  const acknowledgeBy = useSelector(
    (state) => state.notifications?.acknowledgeBy
  );
  const closedBy = useSelector((state) => state.notifications?.closedBy);

  const notesLoading = useSelector((state) => state.notes?.loading);
  const loading = useSelector((state) => state.notifications?.loading);
  const unitDetails = useSelector((state) => state.unit?.details);

  useEffect(() => {
    if (notification.unit !== unitDetails?.id) {
      dispatch(getUnitDetails(notification.unit));
    }

    if (notification.acknowledged_by) {
      dispatch(getAcknowledgeBy(notification.acknowledged_by));
    }

    if (notification.closed_by) {
      dispatch(getClosedBy(notification.closed_by));
    }
  }, [dispatch, notification]);

  const saveNotes = async () => {
    const data = {
      unit: notification.unit,
      source: "notification",
      message: message,
      source_id: notification.id,
    };
    await dispatch(createNotes(data, "", {}, "notification"));
    onClose(false);
  };

  const onClickAcknowledge = async () => {
    const data = {
      acknowledged: [notification.id],
      message: message,
    };

    await dispatch(updateNotification(data));
    onClose(true);
  };

  const onClickMarkClose = async () => {
    const data = {
      closed: [notification.id],
      message: message,
    };

    await dispatch(updateNotification(data));
    onClose(true);
  };

  return (
    <Box>
      <Modal open={open} onClose={() => onClose(false)} width={700} top={"10%"}>
        <Box sx={styles}>
          <Box className="flex">
            <Box className="flex-grow">
              <Typography variant="h2">{unitDetails?.serial}</Typography>
            </Box>

            <Box className="flex flex-col align-end">
              <Typography className="flex">
                Started: {getFullDateTime(notification.timestamp)} (
                {fromNow(notification.timestamp)})
              </Typography>
              <Typography className="flex">Duration: {renderDuration(notification)}</Typography>
              <Typography>{renderStopped(notification)}</Typography>
              <Typography>Count: {notification.counter}</Typography>
            </Box>
          </Box>

          <Box mb={2}>
            <Typography variant="h3" fontWeight={400}>
              {notification.event_parameter}
            </Typography>

            <Typography>{notification?.description}</Typography>
          </Box>

          <Box>
            {!isNil(notification?.acknowledged_at) && (
              <Box my={1}>
                <Typography className="flex">
                  {getFullDateTime(notification.acknowledged_at)} (
                  {fromNow(notification.acknowledged_at)}) -
                  {!isEmpty(acknowledgeBy) && (
                    <span className="acknowledgeBy">{`${acknowledgeBy.first_name} ${acknowledgeBy.last_name}`}</span>
                  )}
                </Typography>

                <Typography>Notification acknowledged</Typography>
              </Box>
            )}
            {!isNil(notification?.closed_at) && (
              <Box my={1}>
                <Typography className="flex">
                  {getFullDateTime(notification.closed_at)} (
                  {fromNow(notification.closed_at)}) -
                  {!isEmpty(closedBy) && (
                    <span className="acknowledgeBy">{`${closedBy.first_name} ${closedBy.last_name}`}</span>
                  )}
                </Typography>

                <Typography>Notification closed</Typography>
              </Box>
            )}
          </Box>

          <Box className="flex flex-col align-end">
            <Link
              to={`/units/details/${notification.unit}?tab=History`}
              className="text-link"
            >
              view unit history
            </Link>

            <Box my={1} width={"100%"}>
              <TextField
                label="Note (Optional)"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows={3}
                fullWidth
                multiline
              ></TextField>
            </Box>

            <Box className="flex" mt={1}>
              {isNil(notification?.acknowledged_at) && (
                <LoadingButton
                  onClick={onClickAcknowledge}
                  variant="contained"
                  className="contained-button"
                  loading={loading}
                >
                  Acknowledge
                </LoadingButton>
              )}
              {!isNil(notification?.acknowledged_at) && (
                <LoadingButton
                  onClick={saveNotes}
                  variant="contained"
                  className="contained-button"
                  loading={notesLoading}
                >
                  Save Note
                </LoadingButton>
              )}
              {!isNil(notification?.acknowledged_at) &&
                isNil(notification?.closed_at) && (
                  <LoadingButton
                    variant="contained"
                    className="contained-button"
                    loading={loading}
                    onClick={onClickMarkClose}
                  >
                    Mark as Closed
                  </LoadingButton>
                )}
              <Button onClick={() => onClose(false)} variant="outlined">
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default memo(NotificationModal);

import { Modal } from "components";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { QRCodeSVG } from "qrcode.react";
import { Loader } from "empower-energy-owner-components";
import { useEffect, useState } from "react";
import { setupMFA, verifyMFA } from "actions/session";
import { LoadingButton } from "@mui/lab";
import OTPInput from "react-otp-input";

const styles = () => {
  return {
    minHeight: "200px",
    "& .loader": {
      display: "flex",
      alignItems: "center",
    },
    "& .mfa-section": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      "& .mfa-code": {
        px: 2,
        py: 1,
        backgroundColor: "#004057",
        color: "white",
      },
    },

    "& .mui-field": {
      minHeight: "78px",
    },
  };
};

const MFASetupModal = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const [otp, setOtp] = useState("");

  const user = useSelector((state) => state.session?.user);
  const mfaSetupLoading = useSelector(
    (state) => state.session?.mfaSetupLoading
  );
  const mfaSetupCode = useSelector((state) => state.session?.mfaSetupCode);
  const mfaVerifyLoading = useSelector(
    (state) => state.session?.mfaVerifyLoading
  );
  
  const mfa_app_name = process.env.REACT_APP_MFA_APP_NAME ?? "EmpowerEnergyFleet";

  useEffect(() => {
    if (user) {
      dispatch(
        setupMFA(user, (res) => {
          if (!res) {
            onClose();
          }
        })
      );
    }
  }, [user]);

  const onClickVerify = () => {
    dispatch(
      verifyMFA(user, otp, () => {
        onClose();
      })
    );
  };

  return (
    <Modal
      width="400px"
      top="20%"
      left="50%"
      title="Setup Multi-Factor Authentication"
      open={open}
      showHeaderBorder={true}
    >
      <Box mt={4} sx={styles}>
        {mfaSetupLoading ? (
          <Box className="loader">
            <Loader show={true} />
          </Box>
        ) : (
          mfaSetupCode && (
            <Box className="mfa-section">
              <Typography variant="subtitle1" mb={3}>
                Setup Multi Factor Authentication using authenticator app ex.
                Authy, Google Authenticator etc.
              </Typography>
              <QRCodeSVG
                value={`otpauth://totp/${mfa_app_name}:${user?.attributes?.email}?secret=${mfaSetupCode}&issuer=Cognito`}
              />
              <Typography my={3} variant="subtitle1">
                Scan QR Code in your authenticator app.
              </Typography>

              <Box mb={2}>
                <Typography variant="subtitle1" mb={1} align="center">
                  Enter generated OTP to verify the setup
                </Typography>
                <OTPInput
                  inputStyle="otpInputStyle"
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span></span>}
                  renderInput={(props) => <input {...props} />}
                  shouldAutoFocus
                />
              </Box>

              <LoadingButton
                sx={{ mb: 2 }}
                onClick={onClickVerify}
                variant="contained"
                loading={mfaVerifyLoading}
                disabled={otp.length < 6}
              >
                Verify OTP
              </LoadingButton>
            </Box>
          )
        )}
      </Box>
    </Modal>
  );
};

export default MFASetupModal;

import { API } from "aws-amplify";
import { USER_TYPE } from "components/UserPage/utils";
import { constructStatusQueryString } from "utils/actions";

export const GET_ORGANISATION_REQUEST =
  "@@organisation/GET_ORGANISATION_REQUEST";
export const GET_ORGANISATION_SUCCESS =
  "@@organisation/GET_ORGANISATION_SUCCESS";
export const GET_ORGANISATION_FAILURE =
  "@@organisation/GET_ORGANISATION_FAILURE";

export function getOrganisationDetails(id) {
  return async (dispatch) => {
    dispatch({
      type: GET_ORGANISATION_REQUEST,
    });
    try {
      let url = `/organisation/${id}`;
      const response = await API.get("cmsAPI", url);
      dispatch({
        type: GET_ORGANISATION_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_ORGANISATION_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_ORGANISATIONS_LIST_REQUEST =
  "@@organisation/GET_ORGANISATIONS_LIST_REQUEST";
export const GET_ORGANISATIONS_LIST_SUCCESS =
  "@@organisation/GET_ORGANISATIONS_LIST_SUCCESS";
export const GET_ORGANISATIONS_LIST_FAILURE =
  "@@organisation/GET_ORGANISATIONS_LIST_FAILURE";

export function getOrganisationsList(userType = "", formData = {}) {
  return async (dispatch) => {
    dispatch({
      type: GET_ORGANISATIONS_LIST_REQUEST,
    });
    try {
      let url = "/organisation";
      if (userType === USER_TYPE.INSTALLER) {
        url = "/installer_organisation";
      }

      if (formData) {
        url = constructStatusQueryString(url, formData);
      }

      const response = await API.get("cmsAPI", url);
      dispatch({
        type: GET_ORGANISATIONS_LIST_SUCCESS,
        payload: response,
        userType,
      });
    } catch (error) {
      dispatch({
        type: GET_ORGANISATIONS_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const POST_ORGANISATION_REQUEST =
  "@@organisation/POST_ORGANISATION_REQUEST";
export const POST_ORGANISATION_SUCCESS =
  "@@organisation/POST_ORGANISATION_SUCCESS";
export const POST_ORGANISATION_FAILURE =
  "@@organisation/POST_ORGANISATION_FAILURE";

export function createOrganisation(value, userType, callback) {
  return async (dispatch) => {
    dispatch({
      type: POST_ORGANISATION_REQUEST,
    });
    try {
      const init = {
        body: value,
      };

      let url = "/organisation";
      if (userType === USER_TYPE.INSTALLER) {
        url = "/installer_organisation";
      }
      const response = await API.post("cmsAPI", url, init);
      dispatch({
        type: POST_ORGANISATION_SUCCESS,
        payload: response,
      });

      dispatch(getOrganisationsList(userType));

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({
        type: POST_ORGANISATION_FAILURE,
        payload: error,
      });
    }
  };
}

export const PUT_ORGANISATION_REQUEST =
  "@@organisation/PUT_ORGANISATION_REQUEST";
export const PUT_ORGANISATION_SUCCESS =
  "@@organisation/PUT_ORGANISATION_SUCCESS";
export const PUT_ORGANISATION_FAILURE =
  "@@organisation/PUT_ORGANISATION_FAILURE";

export function updateOrganisation(value, userType, callback) {
  return async (dispatch) => {
    dispatch({
      type: PUT_ORGANISATION_REQUEST,
    });
    try {
      const init = {
        body: value,
      };

      let url = `/organisation`;
      if (userType === USER_TYPE.INSTALLER) {
        url = "/installer_organisation";
      }
      const response = await API.put("cmsAPI", `${url}/${value.id}`, init);
      dispatch({
        type: PUT_ORGANISATION_SUCCESS,
        payload: response,
      });

      dispatch(getOrganisationsList(userType));

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({
        type: PUT_ORGANISATION_FAILURE,
        payload: error,
      });
    }
  };
}

export const DELETE_ORGANISATION_REQUEST =
  "@@organisation/DELETE_ORGANISATION_REQUEST";
export const DELETE_ORGANISATION_SUCCESS =
  "@@organisation/DELETE_ORGANISATION_SUCCESS";
export const DELETE_ORGANISATION_FAILURE =
  "@@organisation/DELETE_ORGANISATION_FAILURE";

export function deleteOrganisation(id, userType) {
  return async (dispatch) => {
    dispatch({
      type: DELETE_ORGANISATION_REQUEST,
    });
    try {
      let url = `/organisation`;
      if (userType === USER_TYPE.INSTALLER) {
        url = "/installer_organisation";
      }
      const response = await API.del("cmsAPI", `${url}/${id}`);
      dispatch({
        type: DELETE_ORGANISATION_SUCCESS,
        payload: response,
      });

      dispatch(getOrganisationsList(userType));
    } catch (error) {
      dispatch({
        type: DELETE_ORGANISATION_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_SITE_ORGANISATIONS_LIST_REQUEST =
  "@@organisation/GET_SITE_ORGANISATIONS_LIST_REQUEST";
export const GET_SITE_ORGANISATIONS_LIST_SUCCESS =
  "@@organisation/GET_SITE_ORGANISATIONS_LIST_SUCCESS";
export const GET_SITE_ORGANISATIONS_LIST_FAILURE =
  "@@organisation/GET_SITE_ORGANISATIONS_LIST_FAILURE";

export function getSiteOrganisationsList(userType = "", formData = {}) {
  return async (dispatch) => {
    dispatch({
      type: GET_SITE_ORGANISATIONS_LIST_REQUEST,
    });
    try {
      let url = "/organisation";
      if (userType === USER_TYPE.INSTALLER) {
        url = "/installer_organisation";
      }

      if (formData) {
        url = constructStatusQueryString(url, formData);
      }

      const response = await API.get("cmsAPI", url);
      dispatch({
        type: GET_SITE_ORGANISATIONS_LIST_SUCCESS,
        payload: response,
        userType,
      });
    } catch (error) {
      dispatch({
        type: GET_SITE_ORGANISATIONS_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

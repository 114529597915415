import { Cancel, CheckCircle, Delete, Edit } from "@mui/icons-material";
import { Box, Button, IconButton, Link, Typography } from "@mui/material";
import UnitSiteStatus from "components/Unit/UnitSiteStatus";
import UnitStatusIconGroup from "components/Unit/UnitStatusIconGroup";
import { MODEL_OPTIONS_TABS } from "constants/configuration";
import { format, formatISO } from "date-fns";
import { capitalize, isNil } from "lodash";
import { displayDurationBetweenDates } from "utils/datetime";

export const renderName = (params) => {
  if (!params.row?.first_name) return "";
  return <Box>{`${params.row?.first_name} ${params.row?.last_name}`}</Box>;
};

export const renderActions = (params, onClickEdit, onClickDelete) => {
  return (
    <Box className="items-center">
      <IconButton color="primary" onClick={() => onClickEdit(params.row)}>
        <Edit fontSize="small" />
      </IconButton>

      <IconButton color="primary" onClick={() => onClickDelete(params.row)}>
        <Delete fontSize="small" />
      </IconButton>
    </Box>
  );
};

export const renderDeleteActions = (params, onClickDelete) => {
  return (
    <Box className="items-center">
      <Button
        className="button-link"
        onClick={(e) => onClickDelete(e, params.row)}
      >
        Delete
      </Button>
    </Box>
  );
};

export const renderEditActions = (params, onClickEdit) => {
  return (
    <Box className="">
      <IconButton color="primary" onClick={() => onClickEdit(params.row)}>
        <Edit fontSize="small" />
      </IconButton>
    </Box>
  );
};

export const renderAssign = (params, setAssignUser) => {
  return (
    <Button
      onClick={() => setAssignUser(params.row)}
      style={{ textDecoration: "underline" }}
    >
      Assign/Remove Sites
    </Button>
  );
};

export const renderLink = (params, link, onClick) => {
  return (
    <Button
      onClick={() => onClick(params.row)}
      style={{ textDecoration: "underline" }}
      sx={{
        p: 0,
        minWidth: 0,
        px: "4px",
      }}
    >
      {link}
    </Button>
  );
};

export const renderDate = (date, showAllInfo = true) => {
  if (!date) return "";
  return (
    <Box>{`${format(
      new Date(date),
      showAllInfo ? "dd/MM/yyyy, HH:mm:ss" : "dd/MM/yyyy, HH:mm a"
    )} ${showAllInfo ? "[Local]" : ""}`}</Box>
  );
};

export const renderStatusIcon = (value) => {
  return (
    <Box>
      {value ? (
        <CheckCircle
          sx={(theme) => ({
            color: theme.palette.success.main,
            fontSize: "20px",
          })}
        />
      ) : (
        <Cancel
          sx={(theme) => ({
            color: theme.palette.error.main,
            fontSize: "20px",
          })}
        />
      )}
    </Box>
  );
};

export const renderPercent = (value) => (
  <Box className="items-center">{value ?? 0} %</Box>
);

export const renderUnitStatus = (params) => {
  return <UnitSiteStatus status={params.row} />;
};

export const renderUnitStatusIcons = (params) => {
  const status = params.row;
  return <UnitStatusIconGroup status={status} />;
};

export const renderEnabled = (value) => (
  <Box>{value ? "Enabled" : "Disabled"}</Box>
);

export const renderModel = (value) => {
  const item = MODEL_OPTIONS_TABS.find((it) => it.value === value);
  if (item) {
    return item.label;
  }

  return value;
};

export const renderYesNo = (value) => (value ? "Yes" : "No");

export const renderFileHash = (item) => {
  const onClickDownload = (e) => {
    e.stopPropagation();
    window.open(item.download_link);
  };

  if (item.error) {
    return item.error;
  }

  if (item.download_link) {
    return (
      <Link className="ellipsize-left pointer" onClick={onClickDownload}>
        {item.file_hash}
      </Link>
    );
  }

  return <Box className="ellipsize-left">{item.file_hash}</Box>;
};

export const renderStopped = (item) => {
  let status = "";
  if (!isNil(item.closed_at)) {
    status = "Stopped";
  } else if (item.resolved_at && isNil(item.closed_at)) {
    status = "Stopped";
  } else if (isNil(item.resolved_at)) {
    status = "Active";
  }
  return (
    <Box
      sx={{
        color: status === "Active" ? "#FF4842" : "#212B36",
      }}
    >
      {status}
    </Box>
  );
};

export const renderDuration = (item) => {
  let start = item.timestamp;
  let current_time = formatISO(new Date());
  let end = !isNil(item.resolved_at) ? item.resolved_at : current_time;
  var duration = displayDurationBetweenDates(start, end);
  if (isNil(item.resolved_at)) {
    duration += "+";
  }

  return <Box>{duration}</Box>;
};

export const renderSource = (item) => {
  return <Box>{capitalize(item.source)}</Box>;
};

export const Field = ({ label, value }) => {
  return (
    <Box className="items-center" sx={{ width: "100%" }} mb={1.5}>
      <Box sx={{ width: "160px" }}>
        <Typography variant="body2" fontWeight={500}>
          {label}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2" fontWeight={500}>
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

export const renderCost = (value) => <Box>${value}</Box>;

export const renderValue = (value) => <Box>{value ? value : "-"}</Box>;

export const renderExternalLink = (item) => {
  const onClickDownload = (e) => {
    e.stopPropagation();
    window.open(item.link);
  };

  if (item.error) {
    return item.error;
  }

  if (item.link) {
    return (
      <Link
        className="ellipsize-left pointer"
        onClick={onClickDownload}
        sx={{
          width: "100% !important",
          fontWeight: "500",
        }}
      >
        {item.label}
      </Link>
    );
  }

  return <Box className="ellipsize-left">{item.label}</Box>;
};

import {
  format,
  formatDistanceToNowStrict,
  intervalToDuration,
  isValid,
  parseISO,
  addDays,
  formatDuration,
  endOfHour,
  startOfDay,
  endOfDay,
} from "date-fns";

export const convertTZ = (date, tzString) => {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: tzString,
    })
  );
};

export const WEEKDAY_KEYS = [
  {
    value: "sunday",
    label: "Sunday",
    weekday: false,
    weekend: true,
  },
  {
    value: "monday",
    label: "Monday",
    weekday: true,
    weekend: false,
  },
  {
    value: "tuesday",
    label: "Tuesday",
    weekday: true,
    weekend: false,
  },
  {
    value: "wednesday",
    label: "Wednesday",
    weekday: true,
    weekend: false,
  },
  {
    value: "thursday",
    label: "Thursday",
    weekday: true,
    weekend: false,
  },
  {
    value: "friday",
    label: "Friday",
    weekday: true,
    weekend: false,
  },
  {
    value: "saturday",
    label: "Saturday",
    weekday: false,
    weekend: true,
  },
  {
    value: "phday",
    label: "Public Holiday",
    weekday: false,
    weekend: true,
  },
];

export const getDaystoString = (entry) => {
  let eVal = true;
  WEEKDAY_KEYS.map((it) => it.value).forEach((it) => {
    eVal = eVal && entry[it];
  });
  if (eVal) {
    return "Every day";
  }

  let wdVal = true;
  WEEKDAY_KEYS.filter((it) => it.weekday)
    .map((it) => it.value)
    .forEach((it) => {
      wdVal = wdVal && entry[it];
    });
  if (wdVal) {
    return "Weekdays";
  }

  let weVal = true;
  WEEKDAY_KEYS.filter((it) => it.weekend)
    .map((it) => it.value)
    .forEach((it) => {
      weVal = weVal && entry[it];
    });
  if (weVal) {
    return "Weekends";
  }

  let days = [];
  WEEKDAY_KEYS.forEach((it) => {
    if (entry[it.value]) {
      days.push(it.label);
    }
  });
  if (days) {
    return days.join(", ");
  }
};

export const formatDate = (dateTime, formatString = "dd/MM/yyyy, HH:mm:ss") => {
  return format(new Date(isValid(dateTime) ? dateTime : null), formatString);
};

export const getFullDateTime = (dateTime) => {
  return format(
    new Date(isValid(parseISO(dateTime)) ? dateTime : null),
    "dd/MM/yyyy, HH:mm:ss"
  );
};

export const fromNow = (dateTime) => {
  if (!dateTime) return "";
  return formatDistanceToNowStrict(new Date(dateTime), {
    includeSeconds: true,
    addSuffix: true,
  });
};

export const displayDurationBetweenDates = (dateTimeFrom, dateTimeTo) => {
  let d = intervalToDuration({
    start: parseISO(dateTimeFrom),
    end: parseISO(dateTimeTo),
  });
  return formatDuration(d, { zero: false });
};

export const getNextDay = (dateTime) => {
  const date = !dateTime ? new Date() : dateTime;
  return addDays(date, 1);
};

export const getMSTime = (date) => Math.floor(date.getTime() / 1000);

export const getReportDates = (unit) => {
  let reportTo = 0;
  let reportFrom = 0;

  if (["6h", "12h"].includes(unit)) {
    reportTo = getMSTime(endOfHour(new Date())) + 1;

    const nUnit = Number(unit.replace("h", ""));
    reportFrom = reportTo - nUnit * 3600;
  } else if (unit === "today") {
    reportTo = getMSTime(endOfDay(new Date())) + 1;
    reportFrom = getMSTime(startOfDay(new Date()));
  } else if (["2d", "7d", "30d"].includes(unit)) {
    reportTo = getMSTime(endOfDay(new Date())) + 1;

    const nUnit = Number(unit.replace("d", ""));
    reportFrom = reportTo - nUnit * 86400;
  }

  return {
    reportTo,
    reportFrom,
  };
};

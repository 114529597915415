import {
  GET_PROFILE_DETAIL_REQUEST,
  GET_PROFILE_DETAIL_SUCCESS,
  GET_PROFILE_DETAIL_FAILURE,
  CLEAR_PROFILE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_SUCCESS,
  SET_ORGANISATION,
  SET_ALIASES,
} from "actions/profile";
import {
  getSelectedOrganisation,
  setSelectedOrganisation,
} from "utils/localStorage";

const initialState = {
  loading: false,
  error: {},
  id: "",
  access_type: "",
  email: "",
  first_name: "",
  last_name: "",
  status: "",
  menu: {},
  organisations: [],
  organisation: "",
  aliases: [],
  mfa: null,
};

export default function profile(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROFILE_DETAIL_REQUEST:
    case UPDATE_PROFILE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_PROFILE_DETAIL_SUCCESS: {
      let organisation = payload.organisation;
      let organisations = payload?.organisations ?? [];
      const selectedOrganisation = getSelectedOrganisation();
      if (selectedOrganisation) {
        if (
          organisations.filter((it) => it.id === selectedOrganisation).length >
          0
        ) {
          organisation = selectedOrganisation;
        }
      }
      setSelectedOrganisation(organisation);
      return {
        ...state,
        loading: false,
        ...payload,
        organisation: organisation,
        organisations: organisations.map((it) => {
          return {
            ...it,
            value: it.id,
            label: it.name,
          };
        }),
      };
    }

    case UPDATE_PROFILE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_PROFILE_DETAIL_FAILURE:
    case UPDATE_PROFILE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case CLEAR_PROFILE: {
      return {
        ...initialState,
      };
    }

    case SET_ORGANISATION: {
      setSelectedOrganisation(payload);
      return {
        ...state,
        organisation: payload,
      };
    }

    case SET_ALIASES: {
      return {
        ...state,
        aliases: payload,
      };
    }

    default:
      return state;
  }
}

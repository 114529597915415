import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import { setOrganisation } from "actions/profile";
import Modal from "components/Modal";
import { FormField } from "empower-energy-owner-components";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const SwitchOrganisation = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    organisation: "",
  });
  const [selectedOrganisation, setSelectedOrganisation] = useState(null);

  const loading = useSelector((state) => state.profile?.loading);
  const profile = useSelector((state) => state.profile);
  const organisations = useSelector((state) => state.profile?.organisations);
  const organisation = useSelector((state) => state.profile?.organisation);

  useEffect(() => {
    const org = organisations.find((it) => it.id === organisation);
    setSelectedOrganisation(org);
  }, [organisation]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onClickSave = async () => {
    await dispatch(setOrganisation(profile, formData.organisation));
    onClose();
    navigate("/access-check");
  };

  return (
    <Modal
      title="Switch Organisation"
      open={open}
      onClose={onClose}
      width={400}
      top={"10%"}
    >
      <Box>
        <form>
          {selectedOrganisation?.label && (
            <Typography mb={2}>
              Current Organisation - {selectedOrganisation?.label}
            </Typography>
          )}
          <FormField
            label="Select Organisation"
            name="organisation"
            type="select"
            variant="standard"
            value={formData.organisation}
            onChange={onChange}
            options={organisations}
          />
          <Box
            className="items-center space-between"
            mt={4}
            sx={{
              "& button": {
                width: "48%",
              },
            }}
          >
            <LoadingButton
              loading={loading}
              onClick={onClickSave}
              variant="contained"
              disabled={isEmpty(formData.organisation)}
            >
              Save
            </LoadingButton>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default SwitchOrganisation;

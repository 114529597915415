import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "components";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { createNotes } from "actions/notes";
import { FormField } from "empower-energy-owner-components";

const AddNotesModal = ({ open, onClose, details, filters, type = "unit" }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    site: "",
    unit: "",
    source: "fleet",
    message: "",
  });

  const [errors, setErrors] = useState({
    message: "",
  });

  const loading = useSelector((state) => state.notes?.loading);

  useEffect(() => {
    if (details?.id) {
      setFormData({ ...formData, unit: details?.id, site: details?.id });
    }
  }, [details]);

  const onChange = (e) => {
    let data = { ...formData, [e.target.name]: e.target.value };

    setFormData(data);
    checkErrors(e);
  };

  const checkErrors = (e) => {
    setErrors({
      ...errors,
      [e.target.name]: isEmpty(String(e.target.value)) ? "Required" : "",
    });
  };

  const onClickSave = async (e) => {
    e.preventDefault();
    const data = {
      ...formData,
      unit: type === "unit" ? formData.unit : undefined,
      site: type === "site" ? formData.site : undefined,
    };

    const siteId = type === "unit" ? details?.site?.id : details?.id;

    await dispatch(createNotes(data, siteId, filters, type));
    onClose();
  };

  const anyError = () => !Object.values(errors).every((it) => isEmpty(it));

  return (
    <Box>
      <Modal
        title={`Add Note`}
        open={open}
        onClose={onClose}
        width={500}
        top={"10%"}
      >
        <form>
          <FormField
            label="Enter Message"
            placeholder="Enter Message"
            name="message"
            variant="outlined"
            value={formData.message}
            onChange={onChange}
            error={errors.message}
            onBlur={checkErrors}
            multiline
            rows={4}
            helperText={`${formData.message.length}/1000`}
            maxLength={1000}
          />

          <Box
            className="items-center space-between"
            mt={4}
            sx={{
              "& button": {
                width: "48%",
              },
            }}
          >
            <LoadingButton
              loading={loading}
              onClick={onClickSave}
              variant="contained"
              disabled={anyError() || isEmpty(formData.message)}
            >
              Save
            </LoadingButton>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
          </Box>
        </form>
      </Modal>
    </Box>
  );
};

export default AddNotesModal;

import {
  GET_NOTES_LIST_REQUEST,
  GET_NOTES_LIST_SUCCESS,
  GET_NOTES_LIST_FAILURE,
  POST_NOTES_REQUEST,
  POST_NOTES_FAILURE,
  POST_NOTES_SUCCESS,
  GET_UNIT_NOTES_LIST_FAILURE,
  GET_UNIT_NOTES_LIST_SUCCESS,
  GET_UNIT_NOTES_LIST_REQUEST,
  GET_SITE_NOTES_LIST_FAILURE,
  GET_SITE_NOTES_LIST_SUCCESS,
  GET_SITE_NOTES_LIST_REQUEST,
  CLEAR_NOTES,
} from "actions/notes";

const initialState = {
  loading: false,
  error: {},
  list: [],
  details: {},
  unitNotes: [],
  unitNotesLoading: false,
  unitNotesTotalCount: 0,
  siteNotes: [],
  siteNotesLoading: false,
  siteNotesTotalCount: 0,
};

export default function notes(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_NOTES_LIST_REQUEST:
    case POST_NOTES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_NOTES_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: payload.map((it, i) => {
          return {
            ...it,
            id: it?.id || i,
          };
        }),
      };
    }

    case POST_NOTES_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_NOTES_LIST_FAILURE:
    case POST_NOTES_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_UNIT_NOTES_LIST_REQUEST: {
      return {
        ...state,
        unitNotesLoading: true,
      };
    }

    case GET_UNIT_NOTES_LIST_SUCCESS: {
      return {
        ...state,
        unitNotesLoading: false,
        unitNotesTotalCount: payload.total_count,
        unitNotes: payload.items.map((it, i) => {
          return {
            ...it,
            id: i,
          };
        }),
      };
    }

    case GET_UNIT_NOTES_LIST_FAILURE: {
      return {
        ...state,
        unitNotesLoading: false,
      };
    }

    case GET_SITE_NOTES_LIST_REQUEST: {
      return {
        ...state,
        siteNotesLoading: true,
      };
    }

    case GET_SITE_NOTES_LIST_SUCCESS: {
      return {
        ...state,
        siteNotesLoading: false,
        siteNotesTotalCount: payload.total_count,
        siteNotes: payload.items.map((it, i) => {
          return {
            ...it,
            id: i,
          };
        }),
      };
    }

    case GET_SITE_NOTES_LIST_FAILURE: {
      return {
        ...state,
        siteNotesLoading: false,
      };
    }

    case CLEAR_NOTES: {
      return {
        ...state,
        unitNotes: [],
        unitNotesLoading: false,
        unitNotesTotalCount: 0,
        siteNotes: [],
        siteNotesLoading: false,
        siteNotesTotalCount: 0,
      };
    }

    default:
      return state;
  }
}

import { API, Auth } from "aws-amplify";
import { toast } from "react-toastify";
// import { updateUser } from "./user";

export const GET_PROFILE_DETAIL_REQUEST =
  "@@profile/GET_PROFILE_DETAIL_REQUEST";
export const GET_PROFILE_DETAIL_SUCCESS =
  "@@profile/GET_PROFILE_DETAIL_SUCCESS";
export const GET_PROFILE_DETAIL_FAILURE =
  "@@profile/GET_PROFILE_DETAIL_FAILURE";

export function getProfile() {
  return async (dispatch) => {
    dispatch({
      type: GET_PROFILE_DETAIL_REQUEST,
    });
    try {
      const user = await Auth.currentAuthenticatedUser();
      const response = await API.get("cmsAPI", `/user/${user.username}`);
      dispatch({
        type: GET_PROFILE_DETAIL_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_PROFILE_DETAIL_FAILURE,
        payload: error,
      });
    }
  };
}

export const SET_SELECTED_SITE = "@@profile/SET_SELECTED_SITE";

export function setSelectedSite(value) {
  return async (dispatch) => {
    dispatch({
      type: SET_SELECTED_SITE,
      payload: value,
    });
  };
}

export const CLEAR_PROFILE = "@@profile/CLEAR_PROFILE";

export function clearProfile() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_PROFILE,
    });
  };
}

export const UPDATE_PROFILE_REQUEST = "@@profile/UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "@@profile/UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "@@profile/UPDATE_PROFILE_FAILURE";

export function updateProfile(attributes) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_PROFILE_REQUEST,
    });
    try {
      const user = await Auth.currentAuthenticatedUser();
      const response = await Auth.updateUserAttributes(user, attributes);
      dispatch({
        type: UPDATE_PROFILE_SUCCESS,
        payload: response,
      });

      toast.success("Profile updated successfully!");
    } catch (error) {
      dispatch({
        type: UPDATE_PROFILE_FAILURE,
        payload: error,
      });
    }
  };
}

export const SET_ORGANISATION = "@@profile/SET_ORGANISATION";

export function setOrganisation(profile, organisation) {
  return async (dispatch) => {
    // dispatch(updateUser({ id: profile?.id, organisation: organisation }));
    dispatch({
      type: SET_ORGANISATION,
      payload: organisation,
    });
  };
}

export const SET_ALIASES = "@@profile/SET_ALIASES";

export function setAliases(list) {
  return async (dispatch) => {
    dispatch({
      type: SET_ALIASES,
      payload: list,
    });
  };
}

import { Search } from "@mui/icons-material";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
} from "@mui/material";
import { getGroupsList } from "actions/groups";
import { getOptions } from "actions/option"
import { getOrganisationsList } from "actions/organisation";
import {
  NOTIFICATIONS_ACKNOWLEDGE_OPTIONS,
  NOTIFICATIONS_LIST_FITLERS,
  NOTIFICATIONS_STATUS_OPTIONS,
} from "constants/notifications";
import { LIFECYCLE_OPTIONS } from "constants/unit";
import { FormField } from "empower-energy-owner-components";
import useDebounce from "hooks/useDebounce";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const styles = () => {
  return {
    "& .mui-field": {
      width: "180px",
      mr: 2,
      "& .MuiInputBase-root": {
        borderWidth: "1px",
      },
    },
  };
};

const NotificationFilter = ({ onChangeFilters, filters }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(NOTIFICATIONS_LIST_FITLERS);

  const [initialLoad, setInitialLoad] = useState(false);

  const organisationList = useSelector((state) => state.organisation?.list);
  const groupList = useSelector((state) => state.groups?.list);
  const severityList = useSelector((state) => state.option?.severity);
  const eventTypeList = useSelector((state) => state.option?.event_parameter);

  const debouncedSearch = useDebounce(formData.search, 500);

  useEffect(() => {
    dispatch(getOrganisationsList());
    dispatch(getGroupsList());
    dispatch(getOptions());
  }, [dispatch]);

  useEffect(() => {
    setFormData(filters);
  }, [filters]);

  useEffect(() => {
    if (initialLoad) {
      onChangeFilters(formData);
    }
  }, [debouncedSearch]);

  const onChangeSearch = (e) => {
    setInitialLoad(true);
    const data = {
      ...NOTIFICATIONS_LIST_FITLERS,
      search: e.target.value,
    };
    setFormData(data);
  };

  const onChange = (e) => {
    const data = { ...formData, [e.target.name]: e.target.value };
    setFormData(data);
    onChangeFilters(data);
  };

  const constructOptions = (list) => {
    if (!list) {
      return []
    }
    const options = list.filter((it) => it.value !== "");
    options.unshift({
      value: "",
      label: "Select All",
    });
    return options;
  };

  return (
    <Box my={2} className="" sx={styles}>
      <Box className="items-center flex-grow">
        <FormField
          size="small"
          label="Unit or Site"
          name="search"
          variant="outlined"
          value={formData.search}
          onChange={onChangeSearch}
          className="search-field"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <FormField
          label="Select Organisation"
          name="organisationId"
          type="select"
          variant="outlined"
          value={formData.organisationId}
          onChange={onChange}
          options={constructOptions(organisationList)}
        />

        <FormField
          label="Select Group"
          name="group"
          type="select"
          variant="outlined"
          value={formData.group}
          onChange={onChange}
          options={constructOptions(groupList)}
        />

        <FormField
          label="Select Lifecycle"
          name="lifecycle"
          type="select"
          variant="outlined"
          value={formData.lifecycle}
          onChange={onChange}
          options={constructOptions(LIFECYCLE_OPTIONS)}
        />

        <FormField
          label="Select Event Type"
          name="eventType"
          type="select"
          variant="outlined"
          value={formData.eventType}
          onChange={onChange}
          options={constructOptions(eventTypeList)}
        />

        <FormField
          label="Select Severity"
          name="severity"
          type="select"
          variant="outlined"
          value={formData.severity}
          onChange={onChange}
          options={constructOptions(severityList)}
        />
      </Box>

      <Box className="items-center">
        <FormControl>
          <FormLabel>Status</FormLabel>
          <RadioGroup
            row
            name="resolved"
            value={filters?.resolved}
            onChange={onChange}
          >
            {NOTIFICATIONS_STATUS_OPTIONS.map((option, i) => (
              <FormControlLabel
                key={i}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </FormControl>

        <FormControl sx={{ ml: 4 }}>
          <FormLabel>Acknowledgement</FormLabel>
          <RadioGroup
            row
            name="acknowledged"
            value={filters?.acknowledged}
            onChange={onChange}
          >
            {NOTIFICATIONS_ACKNOWLEDGE_OPTIONS.map((option, i) => (
              <FormControlLabel
                key={i}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
};

export default NotificationFilter;

export const SELECTED_ORGANISATION = "@empower-energy-organisation";

export const getSelectedOrganisation = () => {
  const organisation = window.localStorage.getItem(SELECTED_ORGANISATION);
  if (organisation !== "undefined") {
    return JSON.parse(organisation);
  } else {
    return undefined;
  }
};

export const setSelectedOrganisation = (value) => {
  window.localStorage.setItem(SELECTED_ORGANISATION, JSON.stringify(value));
};

export const removeSession = () => {};

const KEY = "@empower-energy-";

export const SITE_FILTERS_KEY = `${KEY}sites-filters`;
export const UNIT_FILTERS_KEY = `${KEY}units-filters`;
export const NOTIFICATIONS_FILTERS_KEY = `${KEY}notifications-filters`;
export const DASHBOARD_PERFORMANCE_FILTERS_KEY = `${KEY}dashboard-performance-filters`;

export const getDatatableFilters = (key) => {
  const filters = localStorage.getItem(key);
  if (filters !== "undefined") {
    return JSON.parse(filters);
  } else {
    return undefined;
  }
};

export const setDatatableFilters = (key, filters) => {
  localStorage.setItem(key, JSON.stringify(filters));
};

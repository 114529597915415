import {
  GET_NOTIFICATIONS_LIST_REQUEST,
  GET_NOTIFICATIONS_LIST_SUCCESS,
  GET_NOTIFICATIONS_LIST_FAILURE,
  GET_ACKNOWLEDGEBY_SUCCESS,
  GET_ACKNOWLEDGEBY_REQUEST,
  GET_ACKNOWLEDGEBY_FAILURE,
  UPDATE_NOTIFICATION_REQUEST,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAILURE,
  CLEAR_NOTIFICATIONS_LIST,
  GET_CLOSEDBY_SUCCESS,
  GET_CLOSEDBY_REQUEST,
  GET_CLOSEDBY_FAILURE,
} from "actions/notifications";
import { isNil } from "lodash";

const initialState = {
  loading: false,
  error: {},
  list: [],
  acknowledgeBy: {},
  closedBy: {},
};

export default function notifications(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_NOTIFICATIONS_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_NOTIFICATIONS_LIST_SUCCESS: {
      let list = [];
      let totalCount = 0;
      if (!isNil(payload.items)) {
        list = payload.items;
        totalCount = payload.total_count;
      } else {
        list = payload;
      }

      return {
        ...state,
        loading: false,
        list,
        totalCount,
      };
    }

    case GET_NOTIFICATIONS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_ACKNOWLEDGEBY_SUCCESS: {
      return {
        ...state,
        acknowledgeBy: payload,
      };
    }

    case GET_ACKNOWLEDGEBY_REQUEST:
    case GET_ACKNOWLEDGEBY_FAILURE: {
      return {
        ...state,
        acknowledgeBy: {},
      };
    }

    case GET_CLOSEDBY_SUCCESS: {
      return {
        ...state,
        closedBy: payload,
      };
    }

    case GET_CLOSEDBY_REQUEST:
    case GET_CLOSEDBY_FAILURE: {
      return {
        ...state,
        closedBy: {},
      };
    }

    case UPDATE_NOTIFICATION_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case UPDATE_NOTIFICATION_SUCCESS:
    case UPDATE_NOTIFICATION_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case CLEAR_NOTIFICATIONS_LIST: {
      return {
        ...state,
        list: [],
        totalCount: 0,
      };
    }

    default:
      return state;
  }
}

import { Box, Typography } from "@mui/material";
import { sidebarStyle } from "./styles";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { MENU_OPTIONS } from "constants/menu";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [menuOptions, setMenuOptions] = useState([]);

  const menu = useSelector((state) => state?.profile?.menu);

  useEffect(() => {
    if (menu) {
      const menuAccessList = Object.keys(menu);
      const menuOptionList = [];
      MENU_OPTIONS.forEach((item) => {
        if (menuAccessList.includes(item.access)) {
          menuOptionList.push(item);
        }
      });

      setMenuOptions(menuOptionList);
    }
  }, [menu]);

  const onClick = (item) => {
    navigate(item.to);
  };

  const checkActive = (item) => {
    let className = "";
    if (location.pathname.includes(item.to)) {
      className = "active";
    }
    return className;
  };

  return (
    <Box sx={(theme) => sidebarStyle(theme)}>
      {menuOptions.map((item, i) => (
        <Box
          key={i}
          className={`sidebarItem ${checkActive(item)}`}
          onClick={() => onClick(item)}
        >
          <div>{item.icon}</div>
          <Typography variant="caption" mt={-0.5}>
            {item.label}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Sidebar;

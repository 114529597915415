import { Box, Tooltip, Typography } from "@mui/material";
import play from "images/dashboard/play.svg";
import battery from "images/dashboard/battery.svg";
import batteryFull from "images/dashboard/batteryFull.svg";
import grid from "images/dashboard/grid.svg";
import solar from "images/dashboard/solar.svg";
import solarHallow from "images/dashboard/solar_hallow.svg";
import thermostat from "images/dashboard/thermostat.svg";
import storage from "images/dashboard/storage.svg";
import memory from "images/dashboard/memory.svg";
import GridArrow from "./GridArrow";
import { useState, useEffect } from "react";
import { Clear } from "@mui/icons-material";

const getIcon = (type) => {
  switch (type) {
    case "operation":
      return play;

    case "grid":
      return grid;

    case "solar":
      return solar;

    case "solarHallow":
      return solarHallow;

    case "battery":
      return battery;

    case "batteryFull":
      return batteryFull;

    case "temp":
      return thermostat;

    case "memory":
      return memory;

    case "storage":
      return storage;
    default:
      return play;
  }
};

const UnitStatusIcon = ({
  type,
  value1,
  value2,
  tooltips = [],
  width = "70px",
  status,
}) => {
  const [message, setMessage] = useState("");
  const [nVal, setNVal] = useState(0);
  const [disconnectedGrid, setDisconnectedGrid] = useState(false);

  const Icon = getIcon(type);

  useEffect(() => {
    if (value2 && type === "grid") {
      setNVal(Number(value2.replaceAll("kW", "")));
    }
  }, [value2]);

  useEffect(() => {
    if (tooltips.length > 0) {
      setMessage(tooltips.join("\n"));
    }
  }, [tooltips]);

  useEffect(() => {
    if (type === "grid" && status) {
      setDisconnectedGrid(false);
      if (!status.connected) {
        setDisconnectedGrid(true);
        setMessage("Disconnected from grid");
      } else if (!status?.grid_present) {
        setMessage("Grid not available");
      } else {
        setMessage("Grid Voltage\nGrid Power");
      }
    }
  }, [status]);

  return (
    <Tooltip
      title={
        message && <span style={{ whiteSpace: "pre-line" }}>{message}</span>
      }
      placement="top"
      arrow
    >
      <Box
        className="items-center flex-end"
        mr={2.5}
        my={0.5}
        sx={{
          "&:last-child": { mr: 0 },
          minWidth:
            type === "grid"
              ? "90px"
              : ["temp", "memory", "storage"].includes(type)
              ? "60px"
              : width,
        }}
      >
        <Box
          className="items-center flex-grow"
          sx={{
            position: "relative",
          }}
        >
          <img src={Icon} />
          {type === "grid" && !status?.grid_present && (
            <Box
              sx={{
                position: "absolute",
                top: "-10px",
                left: "6px",
                fontSize: "30px",
                transform: "rotate(45deg)",
              }}
            >
              /
            </Box>
          )}
        </Box>
        {type === "grid" && (
          <Box
            sx={() => {
              return {
                width: "16px",
                height: "35px",
                display: "flex",
                alignItems: "flex-end",
                mt: "2px",
              };
            }}
          >
            {disconnectedGrid ? (
              <Clear sx={{ fontSize: "16px", color: "red" }} />
            ) : (
              nVal !== 0 && <GridArrow positive={nVal > 0} />
            )}
          </Box>
        )}
        <Box className="flex flex-col align-end">
          <Typography fontSize="14px">{value1}</Typography>
          <Typography fontSize="14px" lineHeight={1} width="max-content">
            {value2}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
};

export default UnitStatusIcon;

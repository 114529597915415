import { Box, Typography, IconButton } from "@mui/material";
import { headerStyle } from "./styles";
import { useSelector } from "react-redux";
import empowerCircle from "images/empowerCircle.svg";
import { useNavigate } from "react-router-dom";
import { Dashboard, ManageAccounts } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { DropPopper } from "empower-energy-owner-components";
import SwitchOrganisation from "./SwitchOrganisation";

const Header = () => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [organisationName, setOrganisationName] = useState("");
  const [switchOrgModal, setSwitchOrgModal] = useState(false);

  const page = useSelector((state) => state.page);
  const profile = useSelector((state) => state.profile);

  const ACCOUNT_OPTIONS = [
    { label: "Profile", value: "profile" },
    { label: "Switch Organisation", value: "switch-organisation" },
    { label: "Logout", value: "logout" },
  ];

  useEffect(() => {
    if (profile.organisation && profile.organisations) {
      const org = profile.organisations.find(
        (it) => it.id === profile.organisation
      );
      if (org) {
        setOrganisationName(org.label);
      }
    }
  }, [profile]);

  const openActionPopover = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const onChangePopper = (value) => {
    if (value === "profile") {
      navigate("/profile");
    } else if (value === "switch-organisation") {
      setSwitchOrgModal(true);
    } else if (value === "logout") {
      navigate("/logout");
    }
  };

  const onClickDashboard = () => {
    navigate("/access-check");
  };

  return (
    <Box sx={(theme) => headerStyle(theme)}>
      <Box className="flexBox">
        <img src={empowerCircle} alt="" className="space" />
        <Typography variant="h2">{page?.pageTitle}</Typography>
      </Box>
      <Box className="items-center">
        <Typography mr={1}>
          Hi, {profile?.first_name || profile?.email}
          {organisationName && <span> | {organisationName}</span>}
        </Typography>
        <IconButton color="primary" onClick={onClickDashboard}>
          <Dashboard />
        </IconButton>
        <IconButton color="primary" onClick={openActionPopover}>
          <ManageAccounts />
        </IconButton>
        <DropPopper
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          options={ACCOUNT_OPTIONS}
          onChange={onChangePopper}
        />
      </Box>

      {switchOrgModal && (
        <SwitchOrganisation
          open={true}
          onClose={() => setSwitchOrgModal(false)}
        />
      )}
    </Box>
  );
};

export default Header;

import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { cloneDeep, orderBy } from "lodash";

dayjs.extend(advancedFormat);

export const initialTariffDefinition = {
  name: "",
  prices: [],
  seasons: [],
};

export const initialTariff = {
  definition: initialTariffDefinition,
  id: null,
  name: "",
  dnsp: null,
  dnsp_default: false,
  optimisation_reset_time: "00:00:00",
  site: null,
  source: "custom",
  tariff_type: "residential",
};

export const getDefaultTariffValues = (formData, site) => {
  const data = {
    name: formData.name,
    optimisation_reset_time: formData.optimisation_reset_time,
    tariff_type: formData.tariff_type,
    source: formData.source,
    site: formData.dnsp ? null : site?.id,
    dnsp: formData.dnsp,
  };

  return data;
};

export const getTariffDefinition = (
  formData,
  tariffPrices,
  tariffSeasonChart
) => {
  let data = {};

  data.name = formData.name;
  data.prices = tariffPrices.map((it) => {
    return {
      ...it,
      id: null,
    };
  });

  data.seasons = tariffSeasonChart.map((it) => {
    return {
      ...it,
      id: null,
    };
  });

  return data;
};

export const getTariffSeasons = (seasons = []) => {
  const tariffSeasons = [];
  seasons.forEach((it) => {
    it.date_ranges.forEach((dt, index) => {
      let season = {
        id: `${it.name}${index}`,
        name: it.name,
        startDate: "",
        endDate: "",
        start_day: dt.start_day,
        start_month: dt.start_month,
      };
      season.startDate = dayjs(new Date(2022, 0, 1))
        .set("date", dt.start_day)
        .set("month", dt.start_month - 1);

      season.endDate = dayjs(new Date(2022, 0, 1))
        .set("date", dt.end_day)
        .set("month", dt.end_month - 1);

      tariffSeasons.push(season);
    });
  });

  return tariffSeasons;
};

export const convertSeasonToChart = (tariffSeasons, tariffSeasonChart) => {
  const seasons = [];
  const items = groupByName(tariffSeasons);
  Object.keys(items).forEach((name) => {
    const chartItem = tariffSeasonChart.find((it) => it.name === name);

    let season = {
      name: name,
      priority: chartItem?.priority || 1,
      date_ranges: [],
      days_of_week: chartItem?.days_of_week || [],
    };

    items[name].forEach((dt) => {
      season.date_ranges.push({
        end_day: dayjs(dt.endDate).get("date"),
        end_month: dayjs(dt.endDate).get("month") + 1,
        start_day: dayjs(dt.startDate).get("date"),
        start_month: dayjs(dt.startDate).get("month") + 1,
      });
    });

    seasons.push(season);
  });
  return seasons;
};

const getBetweenSeason = (list, formData) => {
  const item = list
    .filter((it) => it.id !== formData.id)
    .find(
      (it) =>
        it.start_month < formData?.start_month &&
        dayjs(it.endDate).get("month") + 1 >= formData?.start_month
    );

  return item;
};

const getStartMonthSeason = (list, formData) => {
  const item = list
    .filter((it) => it.id !== formData.id)
    .find((it) => it.start_month === formData?.start_month);

  return item;
};

export const updateSeasonDateRange = (seasons = [], formData) => {
  let list = cloneDeep(seasons);
  const inBetweenSeason = getBetweenSeason(list, formData);

  const sameStartMonthSeason = getStartMonthSeason(list, formData);

  let season = {
    name: formData.name,
    startDate: "",
    endDate: "",
    start_day: 1,
    start_month: formData.start_month,
  };
  season.startDate = dayjs(new Date(2022, 0, 1))
    .set("date", 1)
    .set("month", formData.start_month - 1);

  // First entry
  if (list.length === 0) {
    season.endDate = dayjs(new Date(2022, 0, 1)).set("month", 11).set("date", 31); //31st December
    console.log(season.endDate)
  }

  if (inBetweenSeason) {
    season.endDate = inBetweenSeason.endDate;
    inBetweenSeason.endDate = dayjs(season.startDate).subtract(1, "day");

    //update in list
    const betweenIndex = list.findIndex((it) => it.id === inBetweenSeason.id);
    if (betweenIndex >= 0) {
      list[betweenIndex] = inBetweenSeason;
    }
  } else {
    if (formData?.id) {
      season.endDate = formData.endDate;

      //reset previous season end date
      const prevSeasonIndex = list.findIndex((it) => it.id === formData.id) - 1;
      if (prevSeasonIndex >= 0) {
        if (
          dayjs(list[prevSeasonIndex].endDate).diff(season.startDate, "month") <
          0
        ) {
          list[prevSeasonIndex].endDate = dayjs(season.startDate).subtract(
            1,
            "day"
          );
        }
      }
    } else {
      //a new season with the same start date as an existing season
      if (sameStartMonthSeason) {
        sameStartMonthSeason.startDate = dayjs(
          sameStartMonthSeason.startDate
        ).add(1, "month");
        sameStartMonthSeason.start_month = sameStartMonthSeason.start_month + 1;
        const sameStartMonthSeasonIndex = list.findIndex(
          (it) => it.id === sameStartMonthSeason.id
        );
        if (sameStartMonthSeasonIndex >= 0) {
          list[sameStartMonthSeasonIndex] = sameStartMonthSeason;
        }

        season.endDate = dayjs(season.startDate).endOf("month");
      }
    }
  }

  if (formData.id) {
    //Edit
    season.id = formData.id;
    const seasonIndex = list.findIndex((it) => it.id === season.id);
    if (seasonIndex >= 0) {
      list[seasonIndex] = season;
    }
  } else {
    // new
    season.id = `${formData.name}${list.length}`;

    list.push(season);
  }

  return list;
};

export const deleteUpdteSeasonDateRange = (seasons = [], formData) => {
  let list = cloneDeep(seasons);

  const prevSeasonIndex = list.findIndex((it) => it.id === formData.id) - 1;
  if (prevSeasonIndex >= 0) {
    list[prevSeasonIndex].endDate = formData.endDate;
  }

  list = list.filter((it) => it.id !== formData.id);

  if (formData?.start_month === 1 && list.length > 0) {
    list = orderBy(list, ["start_month"], "asc");

    list[0].startDate = dayjs(new Date(2022, 0, 1)).set("date", 1).set("month", 0);

    list[0].start_month = 1;
  }

  return list;
};

const groupByName = (list) => {
  return list.reduce((result, item) => {
    const { name } = item;

    if (!result[name]) {
      result[name] = [];
    }

    result[name].push(item);

    return result;
  }, {});
};

export const getTimePeriodList = (pricingPeriod) => {
  const timePeriodList = [];
  pricingPeriod.time_periods.forEach((it) => {
    it.time_ranges.forEach((tr, index) => {
      let period = {
        id: `${it.name}${index}`,
        name: it.name,
        start_hour: tr.start_hour,
        end_hour: tr.end_hour,
      };
      timePeriodList.push(period);
    });
  });

  return orderBy(timePeriodList, "start_hour");
};

const inBetweenTimeRange = (list, formData) => {
  const item = list
    .filter((it) => it.id !== formData.id)
    .find(
      (it) =>
        it.start_hour < formData?.start_hour &&
        it.end_hour > formData?.start_hour
    );

  return item;
};

const getStartTimeRangePeriod = (list, formData) => {
  const item = list
    .filter((it) => it.id !== formData.id)
    .find((it) => it.start_hour === formData?.start_hour);

  return item;
};

export const updateSeasonPricingPeriod = (
  seasons,
  season,
  pricingPeriod,
  timePeriodList
) => {
  const newSeason = cloneDeep(season);
  const time_periods = [];
  const items = groupByName(timePeriodList);
  Object.keys(items).forEach((name) => {
    let period = {
      name: name,
      priority: 1,
      time_ranges: [],
    };

    items[name].forEach((item) => {
      period.time_ranges.push({
        start_hour: item.start_hour,
        end_hour: item.end_hour,
      });
    });

    time_periods.push(period);
  });

  const index = season.days_of_week.findIndex(
    (it) => it.name === pricingPeriod.name
  );
  if (index >= 0) {
    newSeason.days_of_week[index].time_periods = time_periods;
  } else {
    newSeason.days_of_week.push({
      ...pricingPeriod,
      time_periods: time_periods,
    });
  }

  const newSeasons = cloneDeep(seasons);

  const seasonIndex = newSeasons.findIndex((it) => it.name === newSeason.name);

  newSeasons[seasonIndex] = newSeason;

  return { selectedConfiguredSeason: newSeason, tariffSeasonChart: newSeasons };
};

export const updatePeriodTimeRange = (
  formData,
  seasons,
  season,
  pricingPeriod
) => {
  let timePeriodList = getTimePeriodList(pricingPeriod);

  const inBetween = inBetweenTimeRange(timePeriodList, formData);

  const sameStartTimeRangePeriod = getStartTimeRangePeriod(
    timePeriodList,
    formData
  );

  let period = {
    name: formData?.name,
    start_hour: formData?.start_hour,
  };

  if (timePeriodList.length === 0) {
    period.end_hour = 24; //First item
  }

  if (inBetween) {
    period.end_hour = inBetween.end_hour;
    inBetween.end_hour = period.start_hour;

    //update in list
    const betweenIndex = timePeriodList.findIndex(
      (it) => it.id === inBetween.id
    );
    if (betweenIndex >= 0) {
      timePeriodList[betweenIndex] = inBetween;
    }
  } else {
    if (formData?.id) {
      period.end_hour = formData.end_hour;

      //reset previous season end date
      const prevPeriodIndex =
        timePeriodList.findIndex((it) => it.id === formData.id) - 1;
      if (prevPeriodIndex >= 0) {
        timePeriodList[prevPeriodIndex].end_hour = period.start_hour;
      }
    } else {
      if (sameStartTimeRangePeriod) {
        sameStartTimeRangePeriod.start_hour =
          sameStartTimeRangePeriod.start_hour + 1;

        const sameStartIndex = timePeriodList.findIndex(
          (it) => it.id === sameStartTimeRangePeriod.id
        );
        if (sameStartIndex >= 0) {
          timePeriodList[sameStartIndex] = sameStartTimeRangePeriod;
        }

        period.end_hour = period.start_hour + 1;
      }
    }
  }

  if (formData.id) {
    //Edit
    period.id = formData.id;
    const periodIndex = timePeriodList.findIndex((it) => it.id === period.id);
    if (periodIndex >= 0) {
      timePeriodList[periodIndex] = period;
    }
  } else {
    // new
    period.id = `${formData.name}${timePeriodList.length}`;

    timePeriodList.push(period);
  }

  timePeriodList = orderBy(timePeriodList, "start_hour");

  return updateSeasonPricingPeriod(
    seasons,
    season,
    pricingPeriod,
    timePeriodList
  );
};

export const deleteTariffPeriodTimeRange = (
  formData,
  seasons,
  season,
  pricingPeriod
) => {
  let timePeriodList = getTimePeriodList(pricingPeriod);

  const prevIndex = timePeriodList.findIndex((it) => it.id === formData.id) - 1;
  if (prevIndex >= 0) {
    timePeriodList[prevIndex].end_hour = formData.end_hour;
  }

  timePeriodList = timePeriodList.filter((it) => it.id !== formData.id);

  if (formData?.start_hour === 0 && timePeriodList.length > 0) {
    timePeriodList = orderBy(timePeriodList, ["start_hour"], "asc");

    timePeriodList[0].start_hour = 0;
  }

  timePeriodList = orderBy(timePeriodList, "start_hour");

  return updateSeasonPricingPeriod(
    seasons,
    season,
    pricingPeriod,
    timePeriodList
  );
};

export const PRICING_PERIODS = {
  WEEKDAYS: {
    name: "Weekdays (Mon-Fri)",
    holidays: false,
    priority: 1,
    days_of_week: [0, 1, 2, 3, 4],
    time_periods: [],
  },
  WEEKENDS: {
    name: "Weekends (Sat, Sun)",
    holidays: false,
    priority: 1,
    days_of_week: [5, 6],
    time_periods: [],
  },
  EVERYDAY: {
    name: "Every Day (Mon-Sun)",
    holidays: true,
    priority: 1,
    days_of_week: [0, 1, 2, 3, 4, 5, 6],
    time_periods: [],
  },
};

import { API } from "aws-amplify";
import { constructOrganisationUrl } from "utils/actions";

export const GET_SLOT_LIST_REQUEST = "@@slot/GET_SLOT_LIST_REQUEST";
export const GET_SLOT_LIST_SUCCESS = "@@slot/GET_SLOT_LIST_SUCCESS";
export const GET_SLOT_LIST_FAILURE = "@@slot/GET_SLOT_LIST_FAILURE";

export function getSlotList() {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_SLOT_LIST_REQUEST,
    });
    try {
      const { profile } = getState();
      const { organisation } = profile;
      let url = "/slot";
      const response = await API.get(
        "cmsAPI",
        constructOrganisationUrl(url, organisation)
      );
      dispatch({
        type: GET_SLOT_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_SLOT_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const POST_ASSIGN_SLOT_REQUEST = "@@slot/POST_ASSIGN_SLOT_REQUEST";
export const POST_ASSIGN_SLOT_SUCCESS = "@@slot/POST_ASSIGN_SLOT_SUCCESS";
export const POST_ASSIGN_SLOT_FAILURE = "@@slot/POST_ASSIGN_SLOT_FAILURE";

export function assignSlot(data) {
  return async (dispatch) => {
    dispatch({
      type: POST_ASSIGN_SLOT_REQUEST,
    });
    try {
      const init = {
        body: data,
      };
      const response = await API.put("cmsAPI", `/slot/${data.number}`, init);
      dispatch({
        type: POST_ASSIGN_SLOT_SUCCESS,
        payload: response,
      });
      dispatch(getSlotList());
    } catch (error) {
      dispatch({
        type: POST_ASSIGN_SLOT_FAILURE,
        payload: error,
      });
    }
  };
}

export const DELETE_ASSIGN_SLOT_REQUEST = "@@slot/DELETE_ASSIGN_SLOT_REQUEST";
export const DELETE_ASSIGN_SLOT_SUCCESS = "@@slot/DELETE_ASSIGN_SLOT_SUCCESS";
export const DELETE_ASSIGN_SLOT_FAILURE = "@@slot/DELETE_ASSIGN_SLOT_FAILURE";

export function deleteAssignSlot(id) {
  return async (dispatch) => {
    dispatch({
      type: DELETE_ASSIGN_SLOT_REQUEST,
    });
    try {
      await API.del("cmsAPI", `/slot/${id}`);
      dispatch({
        type: DELETE_ASSIGN_SLOT_SUCCESS,
        payload: {},
      });
      dispatch(getSlotList());
    } catch (error) {
      dispatch({
        type: DELETE_ASSIGN_SLOT_FAILURE,
        payload: error,
      });
    }
  };
}

import { Close } from "@mui/icons-material";
import {
  AlertTitle,
  Collapse,
  IconButton,
  Alert as MuiAlert,
} from "@mui/material";

const Alert = ({
  open,
  onClose,
  title = "",
  message = "",
  severity = "error",
  children,
}) => {
  return (
    <Collapse in={open}>
      <MuiAlert
        severity={severity}
        action={
          onClose && (
            <IconButton
              color="inherit"
              size="small"
              onClick={() => {
                onClose(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          )
        }
        sx={{ mb: 2 }}
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {message}
        {children}
      </MuiAlert>
    </Collapse>
  );
};

export default Alert;

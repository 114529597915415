import { Box } from "@mui/material";
import { getUnitStatusForSite } from "actions/status";
import UnitSiteStatus from "components/Unit/UnitSiteStatus";
import UnitStatusIconGroup from "components/Unit/UnitStatusIconGroup";
import { DataTable } from "empower-energy-owner-components";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

const styles = () => {
  return {
    "& .MuiDataGrid-root": {
      "& .MuiDataGrid-columnHeaders": {
        display: "none",
      },
      "& .MuiDataGrid-cell": {
        whiteSpace: "normal !important",
      },
      "& .MuiDataGrid-row": {
        ":hover": {
          cursor: "pointer",
        },
      },
    },
  };
};

const UnitStatusItem = ({ unit }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState({});

  useEffect(() => {
    if (unit?.id) {
      dispatch(
        getUnitStatusForSite(unit.id, (response) => {
          if (response) {
            setStatus(response);
          }
        })
      );
    }
  }, [dispatch, unit]);

  return (
    <Box
      className="items-center"
      sx={{
        width: "100%",
      }}
    >
      <UnitSiteStatus status={status} />
      <UnitStatusIconGroup status={status} />
    </Box>
  );
};

const UnitStatusList = ({ details }) => {
  const navigate = useNavigate();

  const columns = [
    {
      field: "serial",
      headerName: "Serial",
      flex: 1,
      renderCell: (params) => <UnitStatusItem unit={params.row} />,
    },
  ];

  const onRowClick = (item) => {
    navigate(`/units/details/${item.row.id}`);
  };

  return (
    <Box sx={styles}>
      <DataTable
        rows={details?.units}
        columns={columns}
        onRowClick={onRowClick}
      />
    </Box>
  );
};

export default UnitStatusList;

import { Box, Button, Tooltip, Typography } from "@mui/material";
import UnitStatusIcon from "./UnitStatusIcon";
import { History, WarningAmber } from "@mui/icons-material";
import { fromNow } from "utils/datetime";
import { useEffect, useState } from "react";
import NetworkModal from "components/Modal/NetworkModal/NetworkModal";

const UnitStatusIconGroup = ({
  status,
  wrapperClass = "flex-end",
  showConfigureNetwork = false,
}) => {
  const [openNetworkModal, setOpenNetworkModal] = useState(false);
  const [selectedUnit, setSelectUnit] = useState({});

  useEffect(() => {
    if (status.id !== selectedUnit?.id) {
      setSelectUnit(status);
    }
  }, [status]);

  const getValue = (value) => value ?? 0;

  const getPower = (value) => {
    if (!value) return "0.0kW";
    return (value / 1000).toFixed(1) + "kW";
  };

  const getSolarType = (value) => {
    if (!value || value < 50) return "solarHallow";
    return "solar";
  };

  const getBatteryType = (value) => {
    if (value < -100) return "battery";
    return "batteryFull";
  };

  return (
    <Box className={`items-center ${wrapperClass}`} sx={{ width: "100%" }}>
      <Box className="items-center" sx={{ flexWrap: "wrap" }}>
        <UnitStatusIcon
          type="grid"
          value1={getValue(status?.grid_voltage) + "V"}
          value2={getValue(getPower(status?.grid_power))}
          status={status}
        />
        <UnitStatusIcon
          type={getSolarType(status?.solar_power)}
          value2={getValue(getPower(status?.solar_power))}
          tooltips={["Solar Power"]}
        />
        <UnitStatusIcon
          type={getBatteryType(status?.battery_power)}
          value1={getValue(status?.battery_soc) + "%"}
          value2={getValue(getPower(status?.battery_power))}
          tooltips={["Battery State of Charge", "Battery Power"]}
        />
        <UnitStatusIcon
          type="temp"
          value1={getValue(status?.temp_ambient) + "C"}
          value2={getValue(status?.temp_inverter) + "C"}
          tooltips={["Ambient Temperature", "Inverter Temperature"]}
        />
        <UnitStatusIcon
          type="memory"
          value1={getValue(status?.system_load_5min)}
          value2={getValue(status?.system_memory) + "%"}
          tooltips={["System Load (5min)", "System Memory"]}
        />
        <UnitStatusIcon
          type="storage"
          value1={getValue(status?.system_storage_data) + "%"}
          value2={getValue(status?.system_storage_log) + "%"}
          tooltips={["System Storage - Data", "System Storage - Log"]}
        />
        <Tooltip title="Last Seen" placement="top" arrow>
          <Box className="items-center" sx={{ width: "132px" }}>
            <History />
            <Typography variant="body2" ml={1}>
              {status?.last_seen ? fromNow(status?.last_seen) : "-"}
            </Typography>
          </Box>
        </Tooltip>
        {showConfigureNetwork && (
          <Box className="items-center" ml={1}>
            {!status?.wifi && !status?.ethernet && (
              <Box className="items-center" mr={1} width={200}>
                <WarningAmber
                  sx={{
                    color: "#FF4842",
                  }}
                />
                <Typography ml={1} variant="subtitle2">
                  4G FALLBACK ACTIVE CONNECTION LIMITED
                </Typography>
              </Box>
            )}
            <Button
              variant="outlined"
              onClick={() => setOpenNetworkModal(true)}
            >
              Configure Network
            </Button>
          </Box>
        )}

        {openNetworkModal && (
          <NetworkModal
            open={true}
            onClose={() => setOpenNetworkModal(false)}
            selectedUnit={selectedUnit}
          />
        )}
      </Box>
    </Box>
  );
};

export default UnitStatusIconGroup;

import {
  GET_SCHEDULES_LIST_REQUEST,
  GET_SCHEDULES_LIST_SUCCESS,
  GET_SCHEDULES_LIST_FAILURE,
  GET_ASSIGNED_LIST_REQUEST,
  GET_ASSIGNED_LIST_SUCCESS,
  GET_ASSIGNED_LIST_FAILURE,
  DELETE_SCHEDULE_REQUEST,
  DELETE_SCHEDULE_FAILURE,
  DELETE_SCHEDULE_SUCCESS,
  UPDATE_SCHEDULE_REQUEST,
  UPDATE_SCHEDULE_FAILURE,
  UPDATE_SCHEDULE_SUCCESS,
  ASSIGN_SCHEDULE_REQUEST,
  ASSIGN_SCHEDULE_FAILURE,
  ASSIGN_SCHEDULE_SUCCESS,
  DELETE_ASSIGN_SCHEDULE_REQUEST,
  DELETE_ASSIGN_SCHEDULE_FAILURE,
  DELETE_ASSIGN_SCHEDULE_SUCCESS,
  GET_SCHEDULE_DETAILS_REQUEST,
  GET_SCHEDULE_DETAILS_SUCCESS,
  GET_SCHEDULE_DETAILS_FAILURE,
  UPDATE_SCHEDULE_DETAILS_REQUEST,
  UPDATE_SCHEDULE_DETAILS_SUCCESS,
  UPDATE_SCHEDULE_DETAILS_FAILURE,
  GET_SCHEDULE_OPTIONS_LIST_REQUEST,
  GET_SCHEDULE_OPTIONS_LIST_SUCCESS,
  GET_SCHEDULE_OPTIONS_LIST_FAILURE,
  CLEAR_SCHEDULE_DETAILS,
  CREATE_SCHEDULE_REQUEST,
  CREATE_SCHEDULE_SUCCESS,
  CREATE_SCHEDULE_FAILURE,
} from "actions/schedules";
import { orderBy } from "lodash";

const initialState = {
  loading: false,
  error: {},
  list: [],
  details: {},
  assignedList: [],
  optionsList: [],
};

export default function schedules(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SCHEDULES_LIST_REQUEST:
    case GET_ASSIGNED_LIST_REQUEST:
    case DELETE_SCHEDULE_REQUEST:
    case UPDATE_SCHEDULE_REQUEST:
    case UPDATE_SCHEDULE_DETAILS_REQUEST:
    case CREATE_SCHEDULE_REQUEST:
    case ASSIGN_SCHEDULE_REQUEST:
    case DELETE_ASSIGN_SCHEDULE_REQUEST:
    case GET_SCHEDULE_OPTIONS_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_SCHEDULES_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: payload.map((it) => {
          return {
            ...it,
            action: it.id,
          };
        }),
      };
    }

    case GET_ASSIGNED_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        assignedList: payload,
      };
    }

    case DELETE_SCHEDULE_SUCCESS:
    case UPDATE_SCHEDULE_SUCCESS:
    case UPDATE_SCHEDULE_DETAILS_SUCCESS:
    case CREATE_SCHEDULE_SUCCESS:
    case ASSIGN_SCHEDULE_SUCCESS:
    case DELETE_ASSIGN_SCHEDULE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_SCHEDULES_LIST_FAILURE:
    case GET_ASSIGNED_LIST_FAILURE:
    case DELETE_SCHEDULE_FAILURE:
    case UPDATE_SCHEDULE_FAILURE:
    case UPDATE_SCHEDULE_DETAILS_FAILURE:
    case CREATE_SCHEDULE_FAILURE:
    case ASSIGN_SCHEDULE_FAILURE:
    case DELETE_ASSIGN_SCHEDULE_FAILURE:
    case GET_SCHEDULE_OPTIONS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_SCHEDULE_OPTIONS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        optionsList: payload,
      };
    }

    case GET_SCHEDULE_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
        details: {},
      };
    }

    case GET_SCHEDULE_DETAILS_SUCCESS: {
      let details = payload;
      details.entries = orderBy(details.entries, ["index"]);

      return {
        ...state,
        loading: false,
        details: details,
      };
    }

    case GET_SCHEDULE_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        details: {},
      };
    }

    case CLEAR_SCHEDULE_DETAILS: {
      return {
        ...state,
        details: {},
      };
    }

    default:
      return state;
  }
}

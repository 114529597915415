import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import enLocale from "dayjs/locale/en-au";
import { InputAdornment, TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AccessTime } from "@mui/icons-material";

enLocale.formats = {
  ...enLocale.formats,
  L: "",
};

const BasicTimePicker = ({ name, label, value, onChange, variant }) => {
  const [selectedTime, setSelectedTime] = useState(null);

  useEffect(() => {
    setSelectedTime(dayjs(value));
  }, [value]);

  const onChangeTime = (date) => {
    setSelectedTime(date);
    const e = {
      target: {
        name: name,
        value: date,
      },
    };
    onChange(e);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-au"}>
      <TimePicker
        label={label}
        value={selectedTime}
        onChange={onChangeTime}
        ampm={false}
        size="small"
        sx={{
          width: "140px",
          "& input": {
            p: "8px 12px",
          },
        }}
        renderInput={(props) => (
          <TextField
            {...props}
            variant={variant}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AccessTime />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default BasicTimePicker;

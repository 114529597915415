import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "components";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { createOrganisation, updateOrganisation } from "actions/organisation";
import { FormField } from "empower-energy-owner-components";

const AddEditOrganisationModal = ({
  selectedOrganisation,
  open,
  onClose,
  userType,
}) => {
  const dispatch = useDispatch();

  const [isEdit, setEdit] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
  });

  const [errors, setErrors] = useState({
    name: "",
  });

  const loading = useSelector((state) => state.organisation?.loading);

  useEffect(() => {
    if (!isEmpty(selectedOrganisation)) {
      setEdit(true);
      setFormData(selectedOrganisation);
    } else {
      setEdit(false);
    }
  }, [selectedOrganisation]);

  const onChange = (e) => {
    let data = { ...formData, [e.target.name]: e.target.value };

    setFormData(data);
    checkErrors(e);
  };

  const checkErrors = (e) => {
    setErrors({
      ...errors,
      [e.target.name]: isEmpty(String(e.target.value)) ? "Required" : "",
    });
  };

  const onClickSave = (e) => {
    e.preventDefault();
    if (isEdit) {
      dispatch(
        updateOrganisation(formData, userType, () => {
          onClose();
        })
      );
    } else {
      dispatch(
        createOrganisation(formData, userType, () => {
          onClose();
        })
      );
    }
  };

  const anyError = () => !Object.values(errors).every((it) => isEmpty(it));

  return (
    <Box>
      <Modal
        title={`${isEdit ? "Edit" : "Add New"} ${userType} Organisation`}
        open={open}
        onClose={onClose}
        width={500}
        top={"10%"}
      >
        <form>
          <FormField
            label="Organisation Name"
            placeholder="Organisation Name"
            name="name"
            variant="standard"
            value={formData.name}
            onChange={onChange}
            error={errors.name}
            onBlur={checkErrors}
          />

          <Box
            className="items-center space-between"
            mt={4}
            sx={{
              "& button": {
                width: "48%",
              },
            }}
          >
            <LoadingButton
              loading={loading}
              onClick={onClickSave}
              variant="contained"
              disabled={anyError() || isEmpty(formData.name)}
            >
              {isEdit ? "Save" : "Confirm"}
            </LoadingButton>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
          </Box>
        </form>
      </Modal>
    </Box>
  );
};

export default AddEditOrganisationModal;

import { Edit } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import NameModal from "components/Name/NameModal";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const SiteName = ({ id, editable = false, bold = false }) => {
  const [name, setName] = useState("");

  const [openNameModal, setOpenNameModal] = useState(false);

  const list = useSelector((state) => state.site?.list);
  const aliases = useSelector((state) => state?.profile?.aliases);

  useEffect(() => {
    if (id && list.length > 0) {
      const item = list.find((it) => it.id === id);
      if (item) {
        let aliasName = item?.nmi;
        const aliasSite = aliases.find((it) => it.item === item.id);
        if (aliasSite) {
          aliasName = aliasSite?.name;
        }

        setName(aliasName);
      }
    }
  }, [id, list, aliases]);

  return (
    <Box className="items-center">
      <Tooltip title="Click to Navigate" placement="top" arrow>
        <Link to={`/sites/details/${id}`} className="text-link">
          <Typography
            variant="body1"
            fontSize={editable || bold ? "16px" : "13px"}
            fontWeight={editable || bold ? 500 : 400}
          >
            {name}
          </Typography>
        </Link>
      </Tooltip>
      {editable && (
        <Tooltip title="Click to Edit" placement="top" arrow>
          <IconButton
            sx={{ ml: 1 }}
            color="primary"
            onClick={() => setOpenNameModal(true)}
          >
            <Edit fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      {openNameModal && (
        <NameModal
          open={openNameModal}
          onClose={() => setOpenNameModal(false)}
          id={id}
          type="Site"
          name={name}
        />
      )}
    </Box>
  );
};

export default SiteName;

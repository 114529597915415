import { createStore, compose } from "redux";
import { createBrowserHistory } from "history";
import { persistStore, persistReducer } from "redux-persist";
import createIdbStorage from "redux-persist-indexeddb-storage";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import rootReducer from "../reducers";
import createMiddleware from "./middleware";

const history = createBrowserHistory();

// Use IndexedDB storage
const storage = createIdbStorage({
  name: "fleet_manager_db",
  storeName: "keyval",
});

const persistConfig = {
  key: "fleet_manager",
  storage,
  stateReconciler: hardSet,
  whitelist: ["session", "page"], // Persist only essential parts of the state
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = (preloadedState) => {
  const store = createStore(
    persistedReducer,
    preloadedState,
    compose(createMiddleware())
  );

  const persistor = persistStore(store, null, () => {
    console.log("Restoration complete");
  });

  persistor.subscribe(() => {
    try {
      // persistor.persist();
    } catch (e) {
      if (e.code === 22) {
        // Storage quota exceeded
        console.error("Storage quota exceeded");
        // Implement logic to handle quota exceeded error, such as clearing some part of the state
      }
    }
  });

  return { history, persistor, store };
};

export default configureStore;

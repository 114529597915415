import {
  NOTIFICATIONS_ACKNOWLEDGE,
  NOTIFICATIONS_STATUS,
} from "constants/notifications";
import { cloneDeep } from "lodash";

export const constructOrganisationUrl = (url, organisation) => {
  if (!organisation) {
    return url;
  }
  let prefix = url.includes("?") ? "&" : "?";
  let newUrl = `${url}${prefix}organisation=${organisation}`;
  return newUrl;
};

export const constructStatusQueryString = (url, data) => {
  if (!data) return url;
  let qString = url;

  qString = `${qString}${url.includes("?") ? "&" : "?"}`;

  const list = [];

  Object.keys(data).forEach((key) => {
    if (data[key] !== null && data[key] !== "") {
      let value = data[key];
      if (value === "null") {
        value = null;
      }
      list.push(`${STATUS_KEY[key]}=${value}`);
    }
  });
  return qString + list.join("&");
};

const STATUS_KEY = {
  unit: "unit",
  site: "site",
  organisationId: "organisation",
  organisation: "filter__organisation__eq",
  group: "filter__group__eq",
  installer: "filter__installer_organisation__eq",
  lifecycle: "filter__state__eq",
  control_mode_status: "filter__control_mode_status__eq",
  search: "filter__search__like",
  online: "filter__online__eq",
  state: "filter__state__eq",
  dnsp: "filter__dnsp__eq",
  limit: "limit",
  offset: "offset",
  order: "order",
  pageSize: "limit",
  page: "offset",
  eventType: "filter__event_parameter__eq",
  severity: "filter__severity__eq",
  timestampGt: "filter__timestamp__gt",
  resolved: "filter__resolved_at",
  resolvedNeq: "filter__resolved_at__neq",
  closed: "filter__closed_at__eq",
  closedNeq: "filter__closed_at__neq",
  acknowledgedBy: "filter__acknowledged_by__eq",
  acknowledged: "filter__acknowledged_at__eq",
  reportFrom: "report_from",
  reportTo: "report_to",
  chart: "chart",
  list: "list",
};

export const getNotificationsFormdata = (formdata, username) => {
  let data = cloneDeep(formdata);
  if (data.resolved) {
    if (data.resolved === NOTIFICATIONS_STATUS.ALL) {
      data.resolved = null;
    } else if (data.resolved === NOTIFICATIONS_STATUS.ACTIVE) {
      data.resolved = "null";
    } else if (data.resolved === NOTIFICATIONS_STATUS.OPEN) {
      data.resolved = null;
      data["closed"] = "null";
    } else if (data.resolved === NOTIFICATIONS_STATUS.CLOSED) {
      data.resolved = null;
      data["closedNeq"] = "null";
    }
  }

  if (data.acknowledged) {
    if (data.acknowledged === NOTIFICATIONS_ACKNOWLEDGE.ALL) {
      data.acknowledged = null;
    } else if (data.acknowledged === NOTIFICATIONS_ACKNOWLEDGE.UNACKNOWLEDGED) {
      data.acknowledged = "null";
    } else if (
      data.acknowledged === NOTIFICATIONS_ACKNOWLEDGE.MYNOTIFICATIONS
    ) {
      data.acknowledged = null;
      data["acknowledgedBy"] = username;
    }
  }
  return data;
};

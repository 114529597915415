import { API } from "aws-amplify";

export const GET_PATCH_DETAILS_REQUEST = "@@upgrade/GET_PATCH_DETAILS_REQUEST";
export const GET_PATCH_DETAILS_SUCCESS = "@@upgrade/GET_PATCH_DETAILS_SUCCESS";
export const GET_PATCH_DETAILS_FAILURE = "@@upgrade/GET_PATCH_DETAILS_FAILURE";

export function getPatchDetails(patch, model) {
  return async (dispatch) => {
    dispatch({
      type: GET_PATCH_DETAILS_REQUEST,
    });
    try {
      let url = `/model/${model}/patch/${patch}`;
      const response = await API.get("upgradeAPI", url);
      dispatch({
        type: GET_PATCH_DETAILS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_PATCH_DETAILS_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_PATCHES_LIST_REQUEST = "@@upgrade/GET_PATCHES_LIST_REQUEST";
export const GET_PATCHES_LIST_SUCCESS = "@@upgrade/GET_PATCHES_LIST_SUCCESS";
export const GET_PATCHES_LIST_FAILURE = "@@upgrade/GET_PATCHES_LIST_FAILURE";

export function getPatchesList() {
  return async (dispatch) => {
    dispatch({
      type: GET_PATCHES_LIST_REQUEST,
    });
    try {
      const response = await API.get("upgradeAPI", "");
      dispatch({
        type: GET_PATCHES_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_PATCHES_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const POST_PATCH_REQUEST = "@@upgrade/POST_PATCH_REQUEST";
export const POST_PATCH_SUCCESS = "@@upgrade/POST_PATCH_SUCCESS";
export const POST_PATCH_FAILURE = "@@upgrade/POST_PATCH_FAILURE";

export function createPatch(formData, file) {
  return async (dispatch) => {
    dispatch({
      type: POST_PATCH_REQUEST,
    });
    try {
      const init = {
        body: formData,
      };

      const response = await API.post("upgradeAPI", "", init);

      if (response.upload_link && file) {
        const form = new FormData();
        let url = response.upload_link.url;
        Object.keys(response.upload_link.fields).forEach((it) => {
          form.append(it, response.upload_link.fields[it]);
        });

        form.append("file", file);

        const requestOptions = {
          method: "POST",
          body: form,
        };
        await fetch(url, requestOptions);
      }

      dispatch({
        type: POST_PATCH_SUCCESS,
        payload: response,
      });

      dispatch(getPatchesList());
    } catch (error) {
      dispatch({
        type: POST_PATCH_FAILURE,
        payload: error,
      });
    }
  };
}

export const PUT_PATCH_REQUEST = "@@upgrade/PUT_PATCH_REQUEST";
export const PUT_PATCH_SUCCESS = "@@upgrade/PUT_PATCH_SUCCESS";
export const PUT_PATCH_FAILURE = "@@upgrade/PUT_PATCH_FAILURE";

export function updatePatch(formData, file, source = "list") {
  return async (dispatch) => {
    dispatch({
      type: PUT_PATCH_REQUEST,
    });
    try {
      const init = {
        body: formData,
      };

      let url = `/model/${formData.model}/patch/${formData.patch}`;

      const response = await API.put("upgradeAPI", url, init);

      if (response.upload_link && file) {
        const form = new FormData();
        let url = response.upload_link.url;
        Object.keys(response.upload_link.fields).forEach((it) => {
          form.append(it, response.upload_link.fields[it]);
        });

        form.append("file", file);

        const requestOptions = {
          method: "POST",
          body: form,
        };
        await fetch(url, requestOptions);
      }

      dispatch({
        type: PUT_PATCH_SUCCESS,
        payload: response,
      });

      if (source === "details") {
        dispatch(getPatchDetails(formData.patch, formData.model));
      } else {
        dispatch(getPatchesList());
      }
    } catch (error) {
      dispatch({
        type: PUT_PATCH_FAILURE,
        payload: error,
      });
    }
  };
}

export const DEPRECATE_PATCH_REQUEST = "@@upgrade/DEPRECATE_PATCH_REQUEST";
export const DEPRECATE_PATCH_SUCCESS = "@@upgrade/DEPRECATE_PATCH_SUCCESS";
export const DEPRECATE_PATCH_FAILURE = "@@upgrade/DEPRECATE_PATCH_FAILURE";

export function deprecatePatch(formData) {
  return async (dispatch) => {
    dispatch({
      type: DEPRECATE_PATCH_REQUEST,
    });
    try {
      let url = `/model/${formData.model}/patch/${formData.patch}/deprecate`;

      const response = await API.put("upgradeAPI", url);
      dispatch({
        type: DEPRECATE_PATCH_SUCCESS,
        payload: response,
      });

      dispatch(getPatchesList());
    } catch (error) {
      dispatch({
        type: DEPRECATE_PATCH_FAILURE,
        payload: error,
      });
    }
  };
}

export const RELEASE_PATCH_REQUEST = "@@upgrade/RELEASE_PATCH_REQUEST";
export const RELEASE_PATCH_SUCCESS = "@@upgrade/RELEASE_PATCH_SUCCESS";
export const RELEASE_PATCH_FAILURE = "@@upgrade/RELEASE_PATCH_FAILURE";

export function releasePatch(formData, stage) {
  return async (dispatch) => {
    if (!stage) return;
    dispatch({
      type: RELEASE_PATCH_REQUEST,
    });
    try {
      const init = {
        body: {
          stage,
        },
      };

      let url = `/model/${formData.model}/patch/${formData.patch}/release`;

      const response = await API.put("upgradeAPI", url, init);
      dispatch({
        type: RELEASE_PATCH_SUCCESS,
        payload: response,
      });

      dispatch(getPatchDetails(formData.patch, formData.model));
    } catch (error) {
      dispatch({
        type: RELEASE_PATCH_FAILURE,
        payload: error,
      });
    }
  };
}

import { API } from "aws-amplify";

export const GET_SITE_TARIFF_LIST_REQUEST =
  "@@tariff/GET_SITE_TARIFF_LIST_REQUEST";
export const GET_SITE_TARIFF_LIST_SUCCESS =
  "@@tariff/GET_SITE_TARIFF_LIST_SUCCESS";
export const GET_SITE_TARIFF_LIST_FAILURE =
  "@@tariff/GET_SITE_TARIFF_LIST_FAILURE";

export function getSiteTariffList(siteId) {
  return async (dispatch) => {
    dispatch({
      type: GET_SITE_TARIFF_LIST_REQUEST,
    });
    try {
      const response = await API.get("cmsAPI", `/tariff?site=${siteId}`);

      dispatch({
        type: GET_SITE_TARIFF_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_SITE_TARIFF_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_UNIT_TARIFF_LIST_REQUEST =
  "@@tariff/GET_UNIT_TARIFF_LIST_REQUEST";
export const GET_UNIT_TARIFF_LIST_SUCCESS =
  "@@tariff/GET_UNIT_TARIFF_LIST_SUCCESS";
export const GET_UNIT_TARIFF_LIST_FAILURE =
  "@@tariff/GET_UNIT_TARIFF_LIST_FAILURE";

export function getUnitTariffList(siteId, unitId) {
  return async (dispatch) => {
    dispatch({
      type: GET_UNIT_TARIFF_LIST_REQUEST,
    });
    try {
      const response = await API.get(
        "cmsAPI",
        `/tariff?site=${siteId}&unit=${unitId}`
      );

      dispatch({
        type: GET_UNIT_TARIFF_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_UNIT_TARIFF_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_TARIFF_DETAILS_REQUEST = "@@tariff/GET_TARIFF_DETAILS_REQUEST";
export const GET_TARIFF_DETAILS_SUCCESS = "@@tariff/GET_TARIFF_DETAILS_SUCCESS";
export const GET_TARIFF_DETAILS_FAILURE = "@@tariff/GET_TARIFF_DETAILS_FAILURE";

export function getTariffDetais(id) {
  return async (dispatch) => {
    dispatch({
      type: GET_TARIFF_DETAILS_REQUEST,
    });
    try {
      const response = await API.get("cmsAPI", `/tariff/${id}`);

      dispatch({
        type: GET_TARIFF_DETAILS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_TARIFF_DETAILS_FAILURE,
        payload: error,
      });
    }
  };
}

export const POST_TARIFF_REQUEST = "@@tariff/POST_TARIFF_REQUEST";
export const POST_TARIFF_SUCCESS = "@@tariff/POST_TARIFF_SUCCESS";
export const POST_TARIFF_FAILURE = "@@tariff/POST_TARIFF_FAILURE";

export function createTariff(formData) {
  return async (dispatch) => {
    dispatch({
      type: POST_TARIFF_REQUEST,
    });
    try {
      const init = {
        body: formData,
      };
      const response = await API.post("cmsAPI", `/tariff`, init);

      dispatch({
        type: POST_TARIFF_SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: POST_TARIFF_FAILURE,
      });
    }
  };
}

export const UPDATE_TARIFF_REQUEST = "@@tariff/UPDATE_TARIFF_REQUEST";
export const UPDATE_TARIFF_SUCCESS = "@@tariff/UPDATE_TARIFF_SUCCESS";
export const UPDATE_TARIFF_FAILURE = "@@tariff/UPDATE_TARIFF_FAILURE";

export function updateTariff(id, formData) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_TARIFF_REQUEST,
    });
    try {
      const init = {
        body: formData,
      };
      const response = await API.put("cmsAPI", `/tariff/${id}`, init);

      dispatch({
        type: UPDATE_TARIFF_SUCCESS,
        payload: response,
      });
      dispatch(getTariffDetais(id));
    } catch (error) {
      dispatch({
        type: UPDATE_TARIFF_FAILURE,
      });
    }
  };
}

export const CLEAR_TARIFF = "@@tariff/CLEAR_TARIFF";

export function clearTariff() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_TARIFF,
    });
  };
}

export const ADD_EDIT_TOU_PERIOD = "@@tariff/ADD_EDIT_TOU_PERIOD";

export function addEditTouPeriod(formData) {
  return async (dispatch) => {
    dispatch({
      type: ADD_EDIT_TOU_PERIOD,
      payload: formData,
    });
  };
}

export const DELETE_TOU_PERIOD = "@@tariff/DELETE_TOU_PERIOD";

export function deleteTouPeriod(period) {
  return async (dispatch) => {
    dispatch({
      type: DELETE_TOU_PERIOD,
      payload: period,
    });
  };
}

export const ADD_EDIT_SEASON_DATE_RANGE = "@@tariff/ADD_EDIT_SEASON_DATE_RANGE";

export function addEditSeasonDateRange(formData) {
  return async (dispatch) => {
    dispatch({
      type: ADD_EDIT_SEASON_DATE_RANGE,
      payload: formData,
    });
  };
}

export const DELETE_SEASON_DATE_RANGE = "@@tariff/DELETE_SEASON_DATE_RANGE";

export function deleteSeasonDateRange(formData) {
  return async (dispatch) => {
    dispatch({
      type: DELETE_SEASON_DATE_RANGE,
      payload: formData,
    });
  };
}

export const SET_SELECTED_CONFIGURED_SEASON =
  "@@tariff/SET_SELECTED_CONFIGURED_SEASON";

export function setSelectedConfiguredSeasonAction(season) {
  return async (dispatch) => {
    dispatch({
      type: SET_SELECTED_CONFIGURED_SEASON,
      payload: season,
    });
  };
}

export const ADD_EDIT_PERIOD_TIME_RANGE = "@@tariff/ADD_EDIT_PERIOD_TIME_RANGE";

export function addEditPeriodTimeRange(formData, pricingPeriod) {
  return async (dispatch) => {
    dispatch({
      type: ADD_EDIT_PERIOD_TIME_RANGE,
      payload: { formData, pricingPeriod },
    });
  };
}

export const DELETE_PERIOD_TIME_RANGE = "@@tariff/DELETE_PERIOD_TIME_RANGE";

export function deletePeriodTimeRange(formData, pricingPeriod) {
  return async (dispatch) => {
    dispatch({
      type: DELETE_PERIOD_TIME_RANGE,
      payload: { formData, pricingPeriod },
    });
  };
}

export const ADD_EDIT_DAYS_OF_WEEK = "@@tariff/ADD_EDIT_DAYS_OF_WEEK";

export function addEditDaysOfWeek(daysOfWeek) {
  return async (dispatch) => {
    dispatch({
      type: ADD_EDIT_DAYS_OF_WEEK,
      payload: daysOfWeek,
    });
  };
}

export const RESET_TARIFF_DETAILS = "@@tariff/RESET_TARIFF_DETAILS";

export function resetTariffDetails(tariffId) {
  return async (dispatch, getState) => {
    if (tariffId) {
      const { tariff } = getState();
      if (tariff.details) {
        dispatch({
          type: GET_TARIFF_DETAILS_SUCCESS,
          payload: tariff.details,
        });
      }
    } else {
      dispatch({
        type: RESET_TARIFF_DETAILS,
      });
    }
  };
}

import { Modal } from "components";
import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import SelectWifiNetworkModal from "./SelectWifiNetworkModal";
import { getNetworkSettings, updateNetworkSettings } from "actions/network";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircle, WarningRounded } from "@mui/icons-material";
import {
  ConfigureNetwork,
  EnterWifiNetwork,
  LinearLoader,
} from "empower-energy-owner-components";

const styles = () => {
  return {
    "& .button-footer": {
      mt: 1,
      pt: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      borderTop: "1px solid rgba(0, 64, 87, 0.25)",
    },
    "& .updating-message": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      wordBreak: "break-word",
      "& .check-icon": {
        marginRight: "12px",
        fontSize: "40px",
      },
      "& .warning-icon": {
        color: "#F9B426",
        marginRight: "12px",
        fontSize: "40px",
      },
    },
  };
};

const STATUS = {
  configure: "configure",
  selectWifi: "selectWifi",
  enterWifi: "enterWifi",
};

const MESSAGE_STATUS = {
  success: "success",
  fail: "fail",
  customMessage: "customMessage",
  systemMessage: "systemMessage",
};

const NetworkModal = ({ open, onClose, selectedUnit }) => {
  const dispatch = useDispatch();

  const [status, setStatus] = useState(STATUS.configure);
  const [selectedWifi, setSelectedWifi] = useState(null);
  const [settingsUpdated, setSettingsUpdated] = useState(false);

  const [messageStatus, setMessageStatus] = useState(
    MESSAGE_STATUS.customMessage
  );

  const networkSettings = useSelector(
    (state) => state.network?.networkSettings
  );

  const previousNetworkSettings = useSelector(
    (state) => state.network.previousNetworkSettings
  );

  const networkSettingsLoading = useSelector(
    (state) => state.network.networkSettingsLoading
  );

  useEffect(() => {
    if (selectedUnit.id) {
      dispatch(getNetworkSettings(selectedUnit.id));
    }
  }, [dispatch, selectedUnit]);

  useEffect(() => {
    let interval = null;
    if (settingsUpdated) {
      dispatch(getNetworkSettings(selectedUnit.id));
      interval = setInterval(() => {
        dispatch(getNetworkSettings(selectedUnit.id));
      }, 5000);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [settingsUpdated]);

  useEffect(() => {
    if (
      !previousNetworkSettings?.status?.last_message ||
      (networkSettings?.status?.last_message >
      previousNetworkSettings?.status?.last_message)
    ) {
      if (!networkSettings?.status?.updating) {
        if (networkSettings?.status?.success) {
          setMessageStatus(MESSAGE_STATUS.success);
        } else {
          setMessageStatus(MESSAGE_STATUS.fail);
        }
      } else {
        setMessageStatus(MESSAGE_STATUS.systemMessage);
      }
    } else {
      setMessageStatus(MESSAGE_STATUS.customMessage);
    }
  }, [networkSettings, previousNetworkSettings]);

  const getTitle = () => {
    switch (status) {
      case STATUS.configure:
        return "Configure Network";
      case STATUS.selectWifi:
        return "Select WiFi Network";
      case STATUS.enterWifi:
        return selectedWifi.ssid === "enterDetails"
          ? "Enter WiFi network"
          : `Enter the password for “${
              selectedWifi?.ssid || selectedWifi?.bssid
            }”`;
      default:
        return "Configure Network";
    }
  };

  const onClickSelectWifiNetwork = (wifi) => {
    setStatus(STATUS.enterWifi);
    setSelectedWifi(wifi);
  };

  const onConnectWifi = (data) => {
    setSettingsUpdated(false);
    dispatch(
      updateNetworkSettings(selectedUnit.id, data, () => {
        setSettingsUpdated(true);
      })
    );
  };

  const onClickConfigureNetwork = (data) => {
    setSettingsUpdated(false);
    dispatch(
      updateNetworkSettings(selectedUnit.id, data, () => {
        setSettingsUpdated(true);
      })
    );
  };

  return (
    <Modal
      width="520px"
      top="10%"
      title={getTitle()}
      open={open}
      onClose={onClose}
      showHeaderBorder={true}
    >
      <Box mt={2}>
        {!settingsUpdated && (
          <Box>
            {status === STATUS.configure && (
              <ConfigureNetwork
                loading={networkSettingsLoading}
                networkSettings={networkSettings}
                onCancel={onClose}
                onClickSave={onClickConfigureNetwork}
                onClickSelectNetwork={() => setStatus(STATUS.selectWifi)}
              />
            )}

            {status === STATUS.selectWifi && (
              <SelectWifiNetworkModal
                selectedUnit={selectedUnit}
                onCancel={() => setStatus(STATUS.configure)}
                onClickSelectWifiNetwork={onClickSelectWifiNetwork}
              />
            )}

            {status === STATUS.enterWifi && (
              <EnterWifiNetwork
                selectedWifi={selectedWifi}
                onCancel={() => setStatus(STATUS.selectWifi)}
                onClickSave={onConnectWifi}
              />
            )}
          </Box>
        )}

        {settingsUpdated && (
          <Box sx={styles}>
            <Box py={1}>
              {messageStatus === MESSAGE_STATUS.success && (
                <Box className="updating-message">
                  <CheckCircle className="check-icon" color="success" />
                  <Typography variant="h4">
                    {networkSettings.status.message}
                  </Typography>
                </Box>
              )}

              {messageStatus === MESSAGE_STATUS.fail && (
                <Box className="updating-message">
                  <WarningRounded className="warning-icon" />
                  <Typography variant="h4">
                    {networkSettings.status.message}
                  </Typography>
                </Box>
              )}

              {messageStatus === MESSAGE_STATUS.customMessage && (
                <Box width="100%">
                  <LinearLoader message="Updating network settings" />
                </Box>
              )}

              {messageStatus === MESSAGE_STATUS.systemMessage && (
                <Box width="100%">
                  <LinearLoader
                    message={
                      networkSettings?.status?.message ||
                      "Updating network settings"
                    }
                  />
                </Box>
              )}
            </Box>
            <Box className="button-footer">
              <Button onClick={onClose}>Dismiss</Button>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default NetworkModal;

import { API } from "aws-amplify";
import {
  constructOrganisationUrl,
  constructStatusQueryString,
} from "utils/actions";
import { setSiteDetailsCached } from "./cached";

export const GET_STATUS_LIST_REQUEST = "@@status/GET_STATUS_LIST_REQUEST";
export const GET_STATUS_LIST_SUCCESS = "@@status/GET_STATUS_LIST_SUCCESS";
export const GET_STATUS_LIST_FAILURE = "@@status/GET_STATUS_LIST_FAILURE";

export function getStatusList(formData = undefined) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_STATUS_LIST_REQUEST,
    });
    try {
      const { profile } = getState();
      const { organisation } = profile;
      let url = "/status";
      if (formData) {
        url = constructStatusQueryString(url, {
          ...formData,
          online: formData.online || null,
        });
      } else {
        constructOrganisationUrl(url, organisation);
      }

      const response = await API.get("cmsAPI", url);
      dispatch({
        type: GET_STATUS_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_STATUS_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export function getUnitStatusForSite(id, callback) {
  return async (dispatch, getState) => {
    try {
      const { cached } = getState();
      const { statusList } = cached;
      const details = statusList.find((it) => it.id === id);

      if (details) {
        if (callback) {
          callback(details);
        }
      } else {
        let url = `/status?unit=${id}`;
        const response = await API.get("cmsAPI", url);
        dispatch(setSiteDetailsCached(response));
        if (callback) {
          callback(response);
        }
      }
    } catch (error) {
      if (callback) {
        callback(null);
      }
    }
  };
}

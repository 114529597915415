import { Box, Button, FormControl, FormLabel, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Modal, OrganisationName } from "components";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { createUser, updateUser } from "actions/user";
import { READONLY, USER_ROLES } from "constants/select";
import { FormField } from "empower-energy-owner-components";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { USER_TYPE } from "./utils";

const AddEditUserModal = ({ selectedUser, open, onClose, userType }) => {
  const dispatch = useDispatch();

  const [organisation, setOrganisation] = useState("");
  const [isEdit, setEdit] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone: undefined,
    access_type: READONLY,
  });

  const [errors, setErrors] = useState({
    email: "",
    first_name: "",
    last_name: "",
  });

  const loading = useSelector((state) => state.user?.loading);
  const profile = useSelector((state) => state.profile);
  const installerList = useSelector(
    (state) => state.organisation?.installerList
  );

  useEffect(() => {
    if (!isEmpty(selectedUser)) {
      setEdit(true);
      setFormData(selectedUser);
      setOrganisation(selectedUser?.organisation);
    } else {
      setEdit(false);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (profile.organisation && profile.organisations) {
      const org = profile.organisations.find(
        (it) => it.id === profile.organisation
      );
      if (org && userType === USER_TYPE.FLEET) {
        setOrganisation(org.value);
      }
    }
  }, [profile]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    checkErrors(e);
  };

  const checkErrors = (e) => {
    setErrors({
      ...errors,
      [e.target.name]: isEmpty(String(e.target.value)) ? "Required" : "",
    });
  };

  const onChangePhoneNumber = (value) => {
    setFormData({ ...formData, phone_number: value });
  };

  const onClickSave = (e) => {
    e.preventDefault();
    if (isEdit) {
      dispatch(
        updateUser(
          { ...formData, phone_number: formData.phone_number || undefined },
          userType,
          () => {
            onClose();
          }
        )
      );
    } else {
      dispatch(
        createUser(formData, userType, organisation, () => {
          onClose();
        })
      );
    }
  };

  const anyError = () => !Object.values(errors).every((it) => isEmpty(it));

  return (
    <Box>
      <Modal
        title={`${isEdit ? "Edit" : "Add New"} ${userType} User`}
        open={open}
        onClose={onClose}
        width={500}
        top={"10%"}
      >
        <Box mb={2}>
          {(userType === USER_TYPE.FLEET ||
            (isEdit && userType === USER_TYPE.INSTALLER)) && (
            <Box className="items-center">
              <Typography variant="body1" fontWeight={500} mr={1}>
                Organisation:{" "}
              </Typography>
              <OrganisationName
                id={organisation}
                userType={userType}
                bold={true}
              />
            </Box>
          )}

          {!isEdit && userType === USER_TYPE.INSTALLER && (
            <Box width={300}>
              <FormField
                label="Installer Organisation"
                name="installer"
                type="select"
                value={organisation}
                onChange={(e) => setOrganisation(e.target.value)}
                options={installerList}
              />
            </Box>
          )}
        </Box>
        <form>
          <FormField
            label="User Email"
            placeholder="User Email"
            name="email"
            variant="standard"
            type="email"
            value={formData.email}
            onChange={onChange}
            error={errors.email}
            onBlur={checkErrors}
          />
          <FormField
            label="First Name"
            placeholder="First Name"
            name="first_name"
            variant="standard"
            value={formData.first_name}
            onChange={onChange}
            error={errors.first_name}
            onBlur={checkErrors}
          />
          <FormField
            label="Last Name"
            placeholder="Last Name"
            name="last_name"
            variant="standard"
            value={formData.last_name}
            onChange={onChange}
            error={errors.last_name}
            onBlur={checkErrors}
          />

          <Box className="mui-field">
            <Box className="mui-field-value">
              <FormControl>
                <FormLabel htmlFor="phone_number" sx={{ fontSize: "12px" }}>
                  Enter Phone Number
                </FormLabel>
                <PhoneInputWithCountrySelect
                  id="phone_number"
                  defaultCountry="AU"
                  placeholder="Enter phone number"
                  value={formData.phone_number}
                  onChange={onChangePhoneNumber}
                />
              </FormControl>
            </Box>
          </Box>

          <FormField
            label="User Role"
            name="access_type"
            type="select"
            variant="standard"
            value={formData.access_type}
            onChange={onChange}
            options={USER_ROLES}
          />

          <Box
            className="items-center space-between"
            mt={4}
            sx={{
              "& button": {
                width: "48%",
              },
            }}
          >
            <LoadingButton
              loading={loading}
              onClick={onClickSave}
              variant="contained"
              disabled={anyError() || isEmpty(formData.email)}
            >
              {isEdit ? "Save" : "Confirm"}
            </LoadingButton>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
          </Box>
        </form>
      </Modal>
    </Box>
  );
};

export default AddEditUserModal;

import {
  CLEAR_METRIC_DETAILS,
  CLEAR_UNIT_METRIC_DETAILS,
  GET_DASHBOARD_METRICS_FAILURE,
  GET_DASHBOARD_METRICS_REQUEST,
  GET_DASHBOARD_METRICS_SUCCESS,
  GET_METRIC_DETAILS_FAILURE,
  GET_METRIC_DETAILS_REQUEST,
  GET_METRIC_DETAILS_SUCCESS,
  GET_METRIC_DETAIL_LIST_FAILURE,
  GET_METRIC_DETAIL_LIST_REQUEST,
  GET_METRIC_DETAIL_LIST_SUCCESS,
  GET_UNIT_METRIC_DETAILS_FAILURE,
  GET_UNIT_METRIC_DETAILS_REQUEST,
  GET_UNIT_METRIC_DETAILS_SUCCESS,
} from "actions/dashboard";
import { cloneDeep } from "lodash";

const initialState = {
  loading: false,
  metricGroups: [],
  metricDetailsBucketLoading: [],
  metricDetailsBucket: {},
  metricDetails: {},
  metricDetailsLoading: false,
  unitMetricDetails: {},
  unitMetricDetailsLoading: false,
};

export default function dashboard(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DASHBOARD_METRICS_REQUEST: {
      return {
        ...state,
        loading: true,
        metricGroups: [],
        metricDetailsBucket: {},
      };
    }

    case GET_DASHBOARD_METRICS_SUCCESS: {
      return {
        ...state,
        loading: false,
        metricGroups: payload,
      };
    }

    case GET_DASHBOARD_METRICS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_METRIC_DETAIL_LIST_REQUEST: {
      const loaders = state.metricDetailsBucketLoading;
      loaders.push(payload);
      return {
        ...state,
        metricDetailsBucketLoading: loaders,
      };
    }

    case GET_METRIC_DETAIL_LIST_SUCCESS: {
      let loaders = [...state.metricDetailsBucketLoading];
      loaders = loaders.filter(
        (it) => it.index !== payload.index && it.id !== payload.id
      );

      const key = `${payload.id}_${payload.index}`;

      let metricDetailsBucket = cloneDeep(state.metricDetailsBucket);
      metricDetailsBucket[key] = payload.details;

      return {
        ...state,
        metricDetailsBucketLoading: loaders,
        metricDetailsBucket,
      };
    }

    case GET_METRIC_DETAIL_LIST_FAILURE: {
      let loaders = [...state.metricDetailsBucketLoading];
      loaders = loaders.filter(
        (it) => it.index !== payload.index && it.id !== payload.id
      );
      return {
        ...state,
        metricDetailsBucketLoading: loaders,
      };
    }

    case GET_METRIC_DETAILS_REQUEST: {
      return {
        ...state,
        metricDetailsLoading: true,
      };
    }

    case GET_METRIC_DETAILS_SUCCESS: {
      return {
        ...state,
        metricDetailsLoading: false,
        metricDetails: payload,
      };
    }

    case GET_METRIC_DETAILS_FAILURE: {
      return {
        ...state,
        metricDetailsLoading: false,
      };
    }

    case CLEAR_METRIC_DETAILS: {
      return {
        ...state,
        metricDetails: {},
      };
    }

    case GET_UNIT_METRIC_DETAILS_REQUEST: {
      return {
        ...state,
        unitMetricDetailsLoading: true,
      };
    }

    case GET_UNIT_METRIC_DETAILS_SUCCESS: {
      return {
        ...state,
        unitMetricDetailsLoading: false,
        unitMetricDetails: payload,
      };
    }

    case GET_UNIT_METRIC_DETAILS_FAILURE: {
      return {
        ...state,
        unitMetricDetailsLoading: false,
      };
    }

    case CLEAR_UNIT_METRIC_DETAILS: {
      return {
        ...state,
        unitMetricDetails: {},
      };
    }

    default:
      return state;
  }
}

import { API } from "aws-amplify";
import { USER_TYPE } from "components/UserPage/utils";
import {
  constructOrganisationUrl,
  constructStatusQueryString,
} from "utils/actions";

export const GET_USER_LIST_REQUEST = "@@user/GET_USER_LIST_REQUEST";
export const GET_USER_LIST_SUCCESS = "@@user/GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAILURE = "@@user/GET_USER_LIST_FAILURE";

export function getUserList(userType = "", formData = {}) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_USER_LIST_REQUEST,
    });
    try {
      const { profile } = getState();
      const { organisation } = profile;
      let url = "/user";
      if (userType === USER_TYPE.OWNER) {
        url = "/owner";
      } else if (userType === USER_TYPE.INSTALLER) {
        url = "/installer";
      }

      if (formData) {
        url = constructStatusQueryString(url, formData);
      } else {
        constructOrganisationUrl(url, organisation);
      }

      const response = await API.get("cmsAPI", url);
      dispatch({
        type: GET_USER_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_USER_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_USER_DETAILS_REQUEST = "@@user/GET_USER_DETAILS_REQUEST";
export const GET_USER_DETAILS_SUCCESS = "@@user/GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILURE = "@@user/GET_USER_DETAILS_FAILURE";

export function getUserDetails(id, userType = "") {
  return async (dispatch, getState) => {
    if (!id) return;
    dispatch({
      type: GET_USER_DETAILS_REQUEST,
    });
    try {
      const { profile } = getState();
      const { organisation } = profile;
      let url = "/user";
      if (userType === USER_TYPE.OWNER) {
        url = "/owner";
      } else if (userType === USER_TYPE.INSTALLER) {
        url = "/installer";
      }
      url = `${url}/${id}`;
      const response = await API.get(
        "cmsAPI",
        constructOrganisationUrl(url, organisation)
      );
      dispatch({
        type: GET_USER_DETAILS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_USER_DETAILS_FAILURE,
        payload: error,
      });
    }
  };
}

export const POST_USER_CREATE_REQUEST = "@@user/POST_USER_CREATE_REQUEST";
export const POST_USER_CREATE_SUCCESS = "@@user/POST_USER_CREATE_SUCCESS";
export const POST_USER_CREATE_FAILURE = "@@user/POST_USER_CREATE_FAILURE";

export function createUser(value, userType = "", organisation = "", callback) {
  return async (dispatch) => {
    dispatch({
      type: POST_USER_CREATE_REQUEST,
    });
    try {
      const init = {
        body: {
          ...value,
          organisation:
            userType === USER_TYPE.INSTALLER ? organisation : undefined,
        },
      };
      let url = constructOrganisationUrl("/user", organisation);
      if (userType === USER_TYPE.OWNER) {
        url = "/owner";
      } else if (userType === USER_TYPE.INSTALLER) {
        url = "/installer";
      }

      const response = await API.post("cmsAPI", url, init);
      dispatch({
        type: POST_USER_CREATE_SUCCESS,
        payload: response,
      });
      dispatch(getUserList(userType));
      callback(true);
    } catch (error) {
      dispatch({
        type: POST_USER_CREATE_FAILURE,
        payload: error,
      });
      callback(false);
    }
  };
}

export const USER_DELETE_REQUEST = "@@user/USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "@@user/USER_DELETE_SUCCESS";
export const USER_DELETE_FAILURE = "@@user/USER_DELETE_FAILURE";

export function deleteUser(id, userType = "") {
  return async (dispatch) => {
    dispatch({
      type: USER_DELETE_REQUEST,
    });
    try {
      let url = "/user";
      if (userType === USER_TYPE.OWNER) {
        url = "/owner";
      } else if (userType === USER_TYPE.INSTALLER) {
        url = "/installer";
      }
      const response = await API.del("cmsAPI", `${url}/${id}`);
      dispatch({
        type: USER_DELETE_SUCCESS,
        payload: response,
      });
      dispatch(getUserList(userType));
    } catch (error) {
      dispatch({
        type: USER_DELETE_FAILURE,
        payload: error,
      });
    }
  };
}

export const USER_UPDATE_REQUEST = "@@user/USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "@@user/USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAILURE = "@@user/USER_UPDATE_FAILURE";

export function updateUser(value, userType = "", callback) {
  return async (dispatch) => {
    dispatch({
      type: USER_UPDATE_REQUEST,
    });
    try {
      const init = {
        body: value,
      };
      let url = "/user";
      if (userType === USER_TYPE.OWNER) {
        url = "/owner";
      } else if (userType === USER_TYPE.INSTALLER) {
        url = "/installer";
      }
      const response = await API.put("cmsAPI", `${url}/${value.id}`, init);
      dispatch({
        type: USER_UPDATE_SUCCESS,
        payload: response.data,
      });
      dispatch(getUserList(userType));
      if (callback) {
        callback(true);
      }
    } catch (error) {
      dispatch({
        type: USER_UPDATE_FAILURE,
        payload: error,
      });
      if (callback) {
        callback(false);
      }
    }
  };
}

export const GET_USER_SITE_OPTION_REQUEST =
  "@@user/GET_USER_SITE_OPTION_REQUEST";
export const GET_USER_SITE_OPTION_SUCCESS =
  "@@user/GET_USER_SITE_OPTION_SUCCESS";
export const GET_USER_SITE_OPTION_FAILURE =
  "@@user/GET_USER_SITE_OPTION_FAILURE";

export function getUserAssignedSite(id) {
  return async (dispatch) => {
    dispatch({
      type: GET_USER_SITE_OPTION_REQUEST,
    });
    try {
      const response = await API.get("cmsAPI", `/owner/${id}/site`);
      dispatch({
        type: GET_USER_SITE_OPTION_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_USER_SITE_OPTION_FAILURE,
        payload: error,
      });
    }
  };
}

export const POST_ASSIGN_SITE_USER_REQUEST =
  "@@user/POST_ASSIGN_SITE_USER_REQUEST";
export const POST_ASSIGN_SITE_USER_SUCCESS =
  "@@user/POST_ASSIGN_SITE_USER_SUCCESS";
export const POST_ASSIGN_SITE_USER_FAILURE =
  "@@user/POST_ASSIGN_SITE_USER_FAILURE";

export function assignSiteUser(userId, data, callback) {
  return async (dispatch) => {
    dispatch({
      type: POST_ASSIGN_SITE_USER_REQUEST,
    });
    try {
      const init = {
        body: data,
      };
      const response = await API.post("cmsAPI", `/owner/${userId}/site`, init);
      dispatch(getUserAssignedSite(userId));
      dispatch({
        type: POST_ASSIGN_SITE_USER_SUCCESS,
        payload: response.data,
      });
      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({
        type: POST_ASSIGN_SITE_USER_FAILURE,
        payload: error,
      });
    }
  };
}

export const USER_ACTION_REQUEST = "@@user/USER_ACTION_REQUEST";
export const USER_ACTION_SUCCESS = "@@user/USER_ACTION_SUCCESS";
export const USER_ACTION_FAILURE = "@@user/USER_ACTION_FAILURE";

export function userAction(id, value, userType = "", action, callback) {
  return async (dispatch) => {
    dispatch({
      type: USER_ACTION_REQUEST,
    });
    try {
      const init = {
        body: value,
      };
      let url = "/user";
      if (userType === USER_TYPE.OWNER) {
        url = "/owner";
      } else if (userType === USER_TYPE.INSTALLER) {
        url = "/installer";
      }
      const response = await API.post("cmsAPI", `${url}/${id}/${action}`, init);
      dispatch({
        type: USER_ACTION_SUCCESS,
        payload: response.data,
      });
      dispatch(getUserList(userType));
      if (callback) {
        callback(true);
      }
    } catch (error) {
      dispatch(getUserList(userType));
      dispatch({
        type: USER_ACTION_FAILURE,
        payload: error,
      });
      if (callback) {
        callback(false);
      }
    }
  };
}

export const SET_USER_PREFERRED_MFA_REQUEST =
  "@@user/SET_USER_PREFERRED_MFA_REQUEST";
export const SET_USER_PREFERRED_MFA_SUCCESS =
  "@@user/SET_USER_PREFERRED_MFA_SUCCESS";
export const SET_USER_PREFERRED_MFA_FAILURE =
  "@@user/SET_USER_PREFERRED_MFA_FAILURE";

export function setUserPreferredMFA(value, id, userType) {
  return async (dispatch) => {
    dispatch({
      type: SET_USER_PREFERRED_MFA_REQUEST,
    });
    try {
      const init = {
        body: value,
      };

      const url = `/user/${id}/mfa`;

      const response = await API.post("cmsAPI", url, init);
      dispatch({
        type: SET_USER_PREFERRED_MFA_SUCCESS,
        payload: response,
      });
      dispatch(getUserDetails(id, userType));
    } catch (error) {
      dispatch({
        type: SET_USER_PREFERRED_MFA_FAILURE,
        payload: error,
      });
    }
  };
}

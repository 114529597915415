import {
  GET_UNIT_OPERATION_REQUEST,
  GET_UNIT_OPERATION_SUCCESS,
  GET_UNIT_OPERATION_FAILURE,
  POST_OPERATION_REQUEST,
  POST_OPERATION_FAILURE,
  POST_OPERATION_SUCCESS,
} from "actions/operation";
import { sortBy } from "lodash";

const initialState = {
  loading: false,
  error: {},
  list: [],
  details: {},
  updateLoading: false,
};

export default function operation(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_UNIT_OPERATION_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_OPERATION_REQUEST: {
      return {
        ...state,
        updateLoading: true,
      };
    }

    case GET_UNIT_OPERATION_SUCCESS: {
      const operations = sortBy(
        payload.map((it) => {
          return {
            ...it,
            created_at: new Date(it.created_at).getTime(),
          };
        }),
        "created_at"
      );

      let details = {};
      if (operations.length > 0) {
        details = operations.at(-1);
      }

      return {
        ...state,
        loading: false,
        details: details,
      };
    }

    case POST_OPERATION_SUCCESS: {
      return {
        ...state,
        updateLoading: false,
      };
    }

    case GET_UNIT_OPERATION_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case POST_OPERATION_FAILURE: {
      return {
        ...state,
        updateLoading: false,
      };
    }

    default:
      return state;
  }
}

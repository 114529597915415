import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { isNil } from "lodash";
import { Header, Content, Sidebar, Footer, MFASetupModal } from "components";
import { Box } from "@mui/material";

const wrapperStyle = () => {
  return {
    display: "flex",
    minHeight: "calc(100vh - 132px)",
  };
};

const pageStyle = () => {
  return {
    display: "flex",
    flexDirection: "column",
  };
};

const AuthLayout = () => {
  const navigate = useNavigate();

  const [openMfaSetup, setOpenMfaSetup] = useState(false);

  const session = useSelector((state) => state.session.session);
  const loading = useSelector((state) => state.session.loading);
  const profile = useSelector((state) => state.profile);

  useEffect(() => {
    if (isNil(session) && !loading) {
      navigate("/");
    }
  }, [session]);

  useEffect(() => {
    if (profile.id) {
      if (profile?.mfa !== "SOFTWARE_TOKEN_MFA") {
        setOpenMfaSetup(true);
      } else {
        setOpenMfaSetup(false);
      }
    }
  }, [profile]);

  return (
    <Box sx={pageStyle}>
      <Header />
      <Box sx={wrapperStyle}>
        <Sidebar />
        <Content>
          <Outlet />
        </Content>
        {openMfaSetup && (
          <MFASetupModal
            open={openMfaSetup}
            onClose={() => setOpenMfaSetup(false)}
          />
        )}
      </Box>
      <Footer />
    </Box>
  );
};

export default AuthLayout;

import { DoNotDisturb } from "@mui/icons-material";

const { Box, Typography } = require("@mui/material");

const styles = (theme, height, border) => {
  return {
    height: height,
    width: "100%",
    padding: 4,
    marginTop: 2,
    border: border ? `1px solid ${theme.palette.primary.light}` : "unset",
    borderRadius: "10px",
    "& .icon-wrapper": {
      height: "60px",
      width: "60px",
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: "4px",
      marginBottom: 1,
    },
  };
};

const NoData = ({
  Icon,
  message,
  height = "300px",
  border = true,
  children,
}) => {
  return (
    <Box
      sx={(theme) => styles(theme, height, border)}
      className="items-justify-center"
    >
      <Box className="items-center flex-col">
        <Box className="icon-wrapper items-justify-center">
          {Icon ? Icon : <DoNotDisturb />}
        </Box>

        {message && <Typography variant="h4">{message}</Typography>}
        {children}
      </Box>
    </Box>
  );
};

export default NoData;
